import { useContext } from 'react'
import { Outlet, Navigate, useLocation } from "react-router-dom"

import { useCurrentUser } from '@contexts/currentUser'

const Authenticated = () => {
  const { user: { signedIn } } = useCurrentUser()
  const location = useLocation()

  if (!signedIn) {
    const pathname = location.pathname
    const to = pathname.startsWith('/sign_in') ? '/sign_in' : `/sign_in?redirect_to=${pathname}`

    return <Navigate to={to} />
  }

  return <Outlet />
}

export default Authenticated
