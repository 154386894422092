const Error = () => (
  <div className="flex flex-col items-center justify-center w-full mt-10">
    <img className="max-w-[200px] w-3/5 my-10" src="/laptop-fire.svg" alt="Laptop on fire" />
    <h1 className="font-bold text-2xl mb-5">Oops! Something went wrong.</h1>
    <p>We're sorry about that. Our team will be notified of the error and should be fixing it soon.</p>
    <p>Feel free to contact <a className="text-blue-500" href="mailto:support@mindjoy.com">support@mindjoy.com</a> if you need any assistance.</p>
  </div>
)

export default Error
