import { Routes, Route, Navigate } from 'react-router-dom'

import { useCurrentUser } from '@contexts/currentUser'

import OrganizationList from './pages/OrganizationList'
import OrganizationDetails from './pages/OrganizationDetails'
import TutorList from './pages/TutorList'
import TutorDetails from './pages/TutorDetails'

const Administrators = () => {
  const { user: { role } } = useCurrentUser()

  if (role !== 'admin') {
    return <Navigate to='/' />
  }

  return (
    <Routes>
      <Route index element={<Navigate to='/admin/organizations' />} />
      <Route path='/organizations' element={<OrganizationList />} />
      <Route path='/organizations/:id/*' element={<OrganizationDetails />} />
      <Route path='/tutors' element={<TutorList />} />
      <Route path='/tutors/:id/*' element={<TutorDetails />} />
    </Routes>

  )
}

export default Administrators
