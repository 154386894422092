import { twMerge } from 'tailwind-merge'

const Head = ({ children, className, ...props }) => (
  <thead
    className={twMerge("bg-gray-50", className)}
    {...props}>
    {children}
  </thead>
)

export default Head
