import Table from '@components/Table'

const InsightTable = ({ data }) => {
  return (
    <div>
      <Table>
        <Table.Head>
          <Table.Row>
            <For each='key' index='index' of={Object.keys(data[0] || {})}>
              <Table.Header key={index}>{key}</Table.Header>
            </For>
          </Table.Row>
        </Table.Head>

        <Table.Body>
          <If condition={data.length < 1}>
            <Table.Row>
              <Table.Cell colSpan={Object.keys(data[0] || {}).length || 1}>
                Nothing found!
              </Table.Cell>
            </Table.Row>
          </If>

          <For each='row' of={data}>
            <Table.Row key={row.Topic}>
              <For each='key' index='index' of={Object.keys(row)}>
                <Table.Cell key={index}>{row[key]}</Table.Cell>
              </For>
            </Table.Row>
          </For>
        </Table.Body>
      </Table>
    </div>
  )
}

export default InsightTable
