import { twMerge } from 'tailwind-merge'

const Cell = ({ children, className, ...props }) => (
  <td
    className={twMerge("whitespace-nowrap px-3 py-2 text-base text-gray-900", className)}
    {...props}>
    {children}
  </td>
)

export default Cell
