import Card from '@components/Card'

const InsightCard = ({ data }) => {
  return (
    <div>
      <Card className='p-6'>
        <Choose>
          <When condition={data.length === 4}>
            <dl className='grid grid-cols-1 gap-5 sm:grid-cols-2'>
              <For each='item' of={data}>
                <div key={item.name} className='overflow-hidden bg-white'>
                  <dt className='truncate text-sm font-medium text-gray-500'>{item.name}</dt>
                  <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>{item.value}</dd>
                </div>
              </For>
            </dl>
          </When>

          <Otherwise>
            <dl className='grid grid-cols-1 gap-5 sm:grid-cols-5'>
              <For each='item' of={data}>
                <div key={item.name} className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
                  <dt className='truncate text-sm font-medium text-gray-500'>{item.name}</dt>
                  <dd className='mt-1 text-2xl font-semibold tracking-tight text-gray-900'>{item.value}</dd>
                </div>
              </For>
            </dl>
          </Otherwise>
        </Choose>
      </Card>
    </div>
  )
}

export default InsightCard
