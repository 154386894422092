import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { TrashIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import Image from '@components/Image'
import SingleFileUpload from '@components/SingleFileInput'
import Spinner from '@components/Spinner'
import Button from '@components/Button'

const CREATE_SERIES_COVER_IMAGE_MUTATION = gql`
  mutation createSeriesCoverImage($input: CreateSeriesCoverImageInput!) {
    createSeriesCoverImage(input: $input) {
      coverImage {
        url
        filename
      }
      errors {
        message
      }
    }
  }
`

const DELETE_SERIES_COVER_IMAGE_MUTATION = gql`
  mutation deleteSeriesCoverImage($input: DeleteSeriesCoverImageInput!) {
    deleteSeriesCoverImage(input: $input) {
      success
      errors {
        message
      }
    }
  }
`

const CoverImage = ({ seriesId, className, imageUrl, refetch }) => {
  const [showFileSizeError, setShowFileSizeError] = useState(false)

  const { mutate: createCoverImage, isLoading: isCreating } = useMutation({
    mutationFn: async data => request(CREATE_SERIES_COVER_IMAGE_MUTATION, { input: { id: seriesId, ...data } }),
    onSuccess: () => refetch()
  })

  const { mutate: deleteCoverImage, isLoading: isDeleting } = useMutation({
    mutationFn: async () => request(DELETE_SERIES_COVER_IMAGE_MUTATION, { input: { id: seriesId } }),
    onSuccess: () => refetch()
  })

  return (
    <div className={twMerge('flex flex-col h-fit sm:w-[300px]', className)}>
      <Choose>
        <When condition={isCreating}>
          <Spinner className='py-16 self-center' />
        </When>

        <When condition={imageUrl}>
          <div className='relative group'>
            <Image
              className='object-cover w-full h-[200px] rounded-lg'
              src={imageUrl}
              placeholder={<div className='rounded-lg h-[200px] w-full bg-gray-200 animate-pulse border shadow' />}
            />

            <Button
              onClick={deleteCoverImage}
              className='absolute group-hover:block hidden bottom-0 right-0 m-3'
              theme='error'
              aria-label='Delete cover image'
              label={
                <Choose>
                  <When condition={isDeleting}>
                    <Spinner className='[&_*]:bg-white' />
                  </When>

                  <Otherwise>
                    <TrashIcon className='h-5 w-5' />
                  </Otherwise>
                </Choose>
              }
            />
          </div>
        </When>

        <Otherwise>
          <div className='bg-gray-200 rounded-lg flex flex-col items-center justify-center py-16 px-5'>
            <SingleFileUpload
              label='Upload a cover image'
              className='mt-3 text-center'
              tabIndex={0}
              theme='primary'
              accept='.png,.jpg,.jpeg'
              onChange={ev => {
                const file = ev.target.files[0]

                if (file && file.size > 10000000) { // 10mb limit
                  setShowFileSizeError(true)

                  return
                }

                createCoverImage({ file })
              }}
            />
            <small className='mt-3 leading-tight'>
              Supported file formats: .png, .jpg and .gif.
              Files must be smaller than 10mb.
            </small>

            <If condition={showFileSizeError}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>File size must be less than 10mb</p>
            </If>
          </div>
        </Otherwise>
      </Choose>
    </div>
  )
}

export default CoverImage
