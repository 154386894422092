import { forwardRef, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import countries from './countries.json'

const dialCodeSize = dialCode => {
  if (!dialCode) return

  const length = dialCode.length

  if (length === 2) return 'pl-9'
  if (length === 3) return 'pl-12'
  if (length === 4) return 'pl-14'

  return 'pl-16'
}

const PhoneNumberInput = forwardRef(({ countryCode, label, required, className, ...props }, ref) => {
  const dialCode = useMemo(() => {
    if (!countryCode) return null

    return countries.find(country => country.code === countryCode)?.dialCode
  }, [countryCode])

  return (
    <div>
      <label htmlFor='phone-number' className='sr-only'>
        Phone number
      </label>

      <div className='relative'>
        <input
          id='phone-number'
          placeholder='Phone number'
          className={twMerge(
            'relative block w-full rounded-none rounded-b-md border-0 bg-transparent py-1.5',
            'text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2',
            'focus:ring-inset focus:ring-blue-600 sm:leading-6',
            dialCodeSize(dialCode),
            className)}
          onKeyDown={event => {
            // Prevent letters and special characters, allow digits and control keys
            if (!/[0-9]/.test(event.key) && !event.ctrlKey && !event.metaKey && event.key.length === 1) {
              event.preventDefault()
            }
          }}
          ref={ref}
          type='tel'
          {...props}
        />

        <If condition={dialCode}>
          <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-gray-900 pointer-events-none'>
            {dialCode}
          </span>
        </If>
      </div>
    </div>
  )
})

export default PhoneNumberInput
