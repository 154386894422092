import { createContext, useState, useContext } from 'react'

const UserContext = createContext()

const CurrentUserProvider = ({ children }) => {
  const [user, setUser] = useState({ loading: true, signedIn: false })

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  )
}

const useCurrentUser = () => {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('useCurrentUser must be used within a CurrentUserProvider')
  }

  return context
}

export {
  CurrentUserProvider,
  useCurrentUser
}
