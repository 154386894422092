import { PaperClipIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'

const labelTheme = theme => {
  switch (theme) {
    case 'success':
      return 'bg-green-600 hover:bg-green-500'
    case 'secondary':
      return 'bg-purple-600 hover:bg-purple-500'
    case 'light':
      return 'bg-gray-200 hover:bg-gray-300 text-gray-900 border'
    default:
      return 'bg-blue-600 hover:bg-blue-500'
  }
}

const SingleFileUpload = ({ label = 'Upload a file', helpText, onChange, theme = 'primary', tabIndex, className, ...props }) => {
  const [file, setFile] = useState(null)

  const handleFileUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0])
    }
  }

  return (
    <div className={twMerge('flex flex-col items-start', className)}>
      <label
        tabIndex={tabIndex}
        htmlFor="upload-button"
        className={twMerge("text-white font-semibold py-2 px-4 rounded-lg cursor-pointer", labelTheme(theme))}>
        <PaperClipIcon className="h-4 w-4 inline-block mr-2" />
        {label}
      </label >

      <If condition={helpText}>
        <small className="mt-2">{helpText}</small>
      </If>

      <input
        id="upload-button"
        type="file"
        className="hidden"
        onChange={e => {
          handleFileUpload(e)

          if (onChange) onChange(e)
        }}
        {...props}
      />
    </div >
  )
}

export default SingleFileUpload
