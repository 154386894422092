import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { DocumentDuplicateIcon, CheckIcon, ArrowPathIcon } from '@heroicons/react/24/outline'
import confetti from 'canvas-confetti'

import { useAnalytics } from '@contexts/analytics'
import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Label from '@components/Label'
import Pill from '@components/Pill'
import TextArea from '@components/TextArea'

const UPDATE_FEEDBACK_COMMENT_MUTATION = gql`
  mutation updateFeedbackComment($updateFeedbackCommentInput: UpdateFeedbackCommentInput!) {
    updateFeedbackComment(input: $updateFeedbackCommentInput) {
      feedbackComment {
        id
        status
        step
      }
    }
  }
`

const PrioritizeStep = ({ feedbackCommentId, startPolling, ...props }) => {
  const copyToClipboardRef = useRef()
  const queryClient = useQueryClient()
  const { track } = useAnalytics()
  const { register, handleSubmit, getValues, watch, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: props
  })

  const { mutate: updateFeedbackComment, isSuccess, isLoading: isSaving } = useMutation({
    mutationFn: async variables => request(UPDATE_FEEDBACK_COMMENT_MUTATION, variables),
    onSuccess: () => startPolling()
  })

  const save = data => {
    updateFeedbackComment({
      updateFeedbackCommentInput: {
        ...data,
        id: feedbackCommentId,
        step: "COMMENT"
      }
    })

    track('Feedback Comment Save', { id: feedbackCommentId, comment: getValues('comment') })
  }

  const retry = () => {
    updateFeedbackComment({
      updateFeedbackCommentInput: {
        id: feedbackCommentId,
        generateComment: true,
      }
    })

    queryClient.setQueryData(['feedbackComment', feedbackCommentId], old => ({
      node: {
        ...old.node,
        status: "PROCESSING"
      }
    }))

    track('Feedback Comment Retry', { id: feedbackCommentId, comment: getValues('comment') })
  }

  const back = () => {
    queryClient.setQueryData(['feedbackComment', feedbackCommentId], old => ({
      node: {
        ...old.node,
        step: "REFLECT"
      }
    }))
  }

  const copyToClipboard = () => {
    const text = getValues('comment')
    navigator.clipboard.writeText(text)

    const button = copyToClipboardRef.current
    const { x, y } = button.getBoundingClientRect()

    confetti({
      particleCount: 100,
      spread: 70,
      origin: { x: x / window.innerWidth, y: y / window.innerHeight },
      disableForReducedMotion: true
    })

    track('Feedback Comment Copy to clipboard', { id: feedbackCommentId, comment: text })
  }
  const comment = watch('comment')
  const wordCount = comment ? comment.split(' ').filter(Boolean).length : 0

  return (
    <form onSubmit={handleSubmit(save)} className="flex flex-col space-y-4 mt-5">
      <div>
        <div className="flex flex-row justify-between items-end">
          <Label className="mb-1" htmlFor="comment">Comment</Label>
          <Pill className="mb-2" theme="secondary" label={`${wordCount} ${wordCount === 1 ? 'word' : 'words'}`} />
        </div>
        <TextArea
          className="h-[400px]"
          id="comment"
          {...register('comment', { required: "Comment is required" })}
        />
      </div>

      <div className="flex flex-row justify-between">
        <Button onClick={back} label="Back" type="button" variant="outlined" />

        <div className="flex flex-row items-center">
          <button
            type="button"
            onClick={retry}
            className="flex flex-row items-center mr-3 text-sm font-semibold hover:bg-gray-200 p-2 rounded-md">
            <ArrowPathIcon className="h-5 w-5 mr-1" />
            Retry
          </button>

          <button
            ref={copyToClipboardRef}
            type="button"
            onClick={copyToClipboard}
            className="flex flex-row items-center mr-3 text-sm font-semibold hover:bg-gray-200 p-2 rounded-md">
            <DocumentDuplicateIcon className="h-5 w-5 mr-1" />
            Copy
          </button>

          <If condition={isSuccess}>
            <CheckIcon className="h-6 w-6 text-green-700 mr-3" />
          </If>

          <Button label={isSaving ? 'Saving...' : 'Save'} type="submit" />
        </div>
      </div>
    </form>
  )
}

export default PrioritizeStep
