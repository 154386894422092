import { Tab } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'

const Panel = ({ children, className, ...props }) => (
  <Tab.Panel as="div" className={twMerge(className)}>
    {children}
  </Tab.Panel>
)

export default Panel
