import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { TrashIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import TextInput from '@components/TextInput'
import Checkbox from '@components/Checkbox'
import Button from '@components/Button'
import Label from '@components/Label'
import LinkButton from '@components/LinkButton'
import TextEditor from '@components/TextEditor'

const UPDATE_QUESTION_BLOCK_MUTATION = gql`
  mutation updateQuestionBlock($input: UpdateQuestionBlockInput!) {
    updateQuestionBlock(input: $input) {
      question {
        label
        markAllocation
        options {
          label
          correct
        }
      }
      errors {
        message
      }
    }
  }
`

const MultipleChoiceQuestionForm = ({ id, label, markAllocation, options, refetch, uploadImage }) => {
  const { control, register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      markAllocation,
      label,
      options
    }
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options'
  })

  const { mutate: updateQuestionBlock, isLoading } = useMutation({
    mutationFn: async variables => request(UPDATE_QUESTION_BLOCK_MUTATION, { input: { id, ...variables } }),
    onSuccess: () => refetch()
  })

  const onSubmit = (data) => {
    updateQuestionBlock(data)
  }

  return (

    <form onSubmit={handleSubmit(data => onSubmit(data))} className='w-full flex flex-col px-5 py-5 gap-3'>

      <div>
        <Label labelFor='question' title='Question' />

        <TextEditor.Container>
          <Controller
            name='label'
            control={control}
            render={({ field }) => (
              <TextEditor
                uploadImage={uploadImage}
                id='label'
                {...field}
              />
            )}
          />

          <TextEditor.Actions>
            <TextEditor.ToolbarToggle />
          </TextEditor.Actions>
        </TextEditor.Container>
      </div>

      <div>
        <TextInput
          id='mark-allocation'
          label='Mark allocation'
          type='number'
          className='block w-fit'
          inputClassName='w-[150px]'
          {...register('markAllocation', {
            valueAsNumber: true,
            required: 'Mark allocation is required',
            min: { value: 1, message: 'Mark allocation must be greater than 0' }
          })}
        />

        <small>Marks allocated for this question</small>

        <If condition={errors.markAllocation}>
          <p className='mt-1 text-sm font-semibold text-red-500' role='alert'>{errors.markAllocation.message}</p>
        </If>
      </div>

      <div>
        <Label className='mt-3'>Answers</Label>

        <For each='option' index='index' of={fields}>
          <div className='w-full flex flex-row items-start mb-3'>
            <Checkbox
              aria-label='Correct answer'
              {...register(`options.${index}.correct`)}
            />

            <TextEditor.Container toolbarEnabled={false} className='grow'>
              <Controller
                name={`options.${index}.label`}
                control={control}
                render={({ field }) => (
                  <TextEditor
                    uploadImage={uploadImage}
                    {...field}
                  />
                )}
              />

              <TextEditor.Actions>
                <TextEditor.ToolbarToggle />
              </TextEditor.Actions>
            </TextEditor.Container>

            <button
              aria-label='Remove answer'
              type='button'
              onClick={() => remove(index)}
              className='ml-1 hover:bg-gray-200 p-2 rounded-md'
            >
              <TrashIcon className='h-5 w-5' />
            </button>
          </div>
        </For>

        <LinkButton
          className='block'
          type='button'
          onClick={() => append({ label: '', correct: false })}
        >
          Add answer
        </LinkButton>
      </div>

      <Button
        className='ml-auto'
        type='submit'
        disabled={isLoading}
        label={isLoading ? 'Saving...' : 'Save'}
      />
    </form>
  )
}

export default MultipleChoiceQuestionForm
