import { useMutation, useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'

const EDUCATOR_PROJECT_QUERY = gql`
  query educatorProject($id: ID!) {
    node(id: $id) {
      ... on EducatorProject {
        id
        name
        status
        readme
        playground
        mode
        prompt
        socraticModeEnabled
        stemModeEnabled
        grade
        subject
        objective
        extractingText
        generatingContent
        documentText
        createdAt
        updatedAt
        tutor {
          id
          name
          description
          summary
          image {
            url
          }
        }
        series {
          id
          name
        }
        document {
          filename
          url
        }
        blocks {
          blockId
          position
          ... on Text {
            __typename
            id
            content
          }
          ... on Reflection {
            __typename
            id
            question
          }
          ... on Question {
            __typename
            id
            label
            markAllocation
            options {
              id
              label
              correct
            }
          }
          ... on FreeResponse {
            __typename
            id
            question
            markAllocation
            markScheme
            workingsRequired
          }
          ... on Video {
            __typename
            id
            url
          }
          ... on Image {
            __typename
            id
            caption
            file {
              filename
              url
            }
          }
        }
        classrooms {
          id
          name
        }
        educatorProfile {
          id
          fullName
        }
        organization {
          id
          name
        }
      }
    }
  }
`

const SUBMISSIONS_QUERY = gql`
  query submissions($id: ID!, $page: Int) {
    node(id: $id) {
      ... on EducatorProject {
        submissions(page: $page, perPage: 10) {
          nodes {
            id
            status
            profile {
              id
              fullName
            }
          }
          pagesCount
          nodesCount
        }
      }
    }
  }
`

const UPDATE_EDUCATOR_PROJECT_MUTATION = gql`
  mutation updateEducatorProject($updateEducatorProjectInput: UpdateEducatorProjectInput!) {
    updateEducatorProject(input: $updateEducatorProjectInput) {
      educatorProject {
        id
        name
        status
        readme
        playground
        submission
        mode
        prompt
        socraticModeEnabled
        stemModeEnabled
        tutor {
          id
          name
        }
        grade
        subject
        objective
        extractingText
        generatingContent
        documentText
        createdAt
        updatedAt
        document {
          filename
          url
        }
        classrooms {
          id
          name
        }
        educatorProfile {
          id
          fullName
        }
        organization {
          id
          name
        }
      }
    }
  }
`

const CREATE_DOCUMENT_MUTATION = gql`
  mutation createEducatorProjectDocument($input: CreateEducatorProjectDocumentInput!) {
    createEducatorProjectDocument(input: $input) {
      success
      errors {
        message
      }
    }
  }
`

const DELETE_DOCUMENT_MUTATION = gql`
  mutation deleteEducatorProjectDocument($input: DeleteEducatorProjectDocumentInput!) {
    deleteEducatorProjectDocument(input: $input) {
      success
      errors {
        message
      }
    }
  }
`
const EXTRACT_TEXT_MUTATION = gql`
  mutation extractText($input: ExtractTextFromEducatorProjectInput!) {
    extractTextFromEducatorProject(input: $input) {
      success
    }
  }
`

const GENERATE_CONTENT_MUTATION = gql`
  mutation generateContent($input: GenerateContentFromEducatorProjectInput!) {
    generateContentFromEducatorProject(input: $input) {
      success
    }
  }
`

const CLASSROOMS_QUERY = gql`
  query classrooms {
    me {
      profile {
        ... on EducatorProfile {
          classrooms {
            id
            name
            studentProfiles {
              id
            }
          }
        }
      }
    }
  }
`

const useEducatorProject = ({ id, refetchInterval }) => {
  const { status, refetch, data: { node: project } = {} } = useQuery({
    queryKey: ['educatorProject', id],
    queryFn: async () => request(EDUCATOR_PROJECT_QUERY, { id }),
    refetchInterval
  })

  return { project, status, refetch }
}

const useUpdateEducatorProject = ({ id, onSuccess }) => {
  const { mutate: updateProject, status } = useMutation({
    mutationFn: async variables => request(UPDATE_EDUCATOR_PROJECT_MUTATION, { updateEducatorProjectInput: { ...variables, id } }),
    onSuccess: () => {
      if (onSuccess) onSuccess()
    }
  })

  return { updateProject, status }
}

const useDocument = ({ id, onCreated, onDeleted }) => {
  const { mutate: createDocument, status: createStatus } = useMutation({
    mutationFn: async variables => request(CREATE_DOCUMENT_MUTATION, { input: { ...variables, id } }),
    onSuccess: () => {
      if (onCreated) onCreated()
    }
  })

  const { mutate: deleteDocument, status: deleteStatus } = useMutation({
    mutationFn: async () => request(DELETE_DOCUMENT_MUTATION, { input: { id } }),
    onSuccess: () => {
      if (onDeleted) onDeleted()
    }
  })

  return { createDocument, createStatus, deleteDocument, deleteStatus }
}

const useExtractText = ({ id, onSuccess, onMutate }) => {
  const { mutate: extractText, isLoading } = useMutation({
    mutationFn: async () => request(EXTRACT_TEXT_MUTATION, { input: { id } }),
    onSuccess: () => {
      if (onSuccess) onSuccess()
    },
    onMutate: () => {
      if (onMutate) onMutate()
    }
  })

  return { extractText, isLoading }
}

const useGenerateContent = ({ id, onSuccess }) => {
  const { mutate: generateContent, isLoading } = useMutation({
    mutationFn: async () => request(GENERATE_CONTENT_MUTATION, { input: { id } }),
    onSuccess: () => {
      if (onSuccess) onSuccess()
    }
  })

  return { generateContent, isLoading }
}

const useClassrooms = () => {
  const { isLoading, data: { me: { profile: { classrooms = [] } = {} } = {} } = {} } = useQuery({
    queryKey: ['classrooms'],
    queryFn: async () => request(CLASSROOMS_QUERY)
  })

  return { classrooms, isLoading }
}

const useSubmissions = ({ id, page }) => {
  const { status, refetch, data: { node: { submissions = {} } = {} } = {} } = useQuery({
    queryKey: ['educatorProjectSubmissions', id, page],
    queryFn: async () => request(SUBMISSIONS_QUERY, { id, page })
  })

  return { submissions, status, refetch }
}

export {
  useEducatorProject,
  useUpdateEducatorProject,
  useClassrooms,
  useDocument,
  useSubmissions,
  useExtractText,
  useGenerateContent
}
