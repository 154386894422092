import { Link } from 'react-router-dom'

import Card from '@components/Card'
import Pill from '@components/Pill'

const Tools = () => {
  return (
    <>
      <div className="flex flex-row items-center justify-between px-5 bg-white border-b-gray-200 border-b h-[60px] min-h-[60px]">
        <h1 className="font-heading text-2xl font-bold my-2">Tools</h1>
      </div>

      <div className="sm:m-5 mt-5">
        <div className="grid sm:grid-cols-3 grid-cols-1 gap-5">
          <Link to="/educators/tools/lesson_plans">
            <Card className="overflow-hidden p-0 h-full relative">
              <div className="p-5 mb-3">
                <h2 className="text-xl font-semibold my-3">Lesson planning tool 🧑‍🏫</h2>

                <p>Planning your lessons has never been this simple and fun!</p>
              </div>
            </Card>
          </Link>

          <Link to="/educators/tools/comments">
            <Card className="overflow-hidden p-0 h-full relative">
              <div className="p-5 mb-3">
                <h2 className="text-xl font-semibold my-3">Comments 💬</h2>

                <p>Write comments for students based on science-backed pedagogies.</p>
              </div>
            </Card>
          </Link>

          <Link to="/educators/projects?tab=1">
            <Card className="overflow-hidden p-0 h-full relative">
              <div className="p-5 mb-3">
                <h2 className="flex items-center text-xl font-semibold my-3">
                  Projects tool 👷
                </h2>

                <p>Build project-based experiences for students with the help of AI.</p>
              </div>
            </Card>
          </Link>
        </div>
      </div>
    </>
  )
}

export default Tools
