import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Card from '@components/Card'
import TextArea from '@components/TextArea'
import Select from '@components/Select'

const UPDATE_FEEDBACK_COMMENT_MUTATION = gql`
  mutation updateFeedbackComment($updateFeedbackCommentInput: UpdateFeedbackCommentInput!) {
    updateFeedbackComment(input: $updateFeedbackCommentInput) {
      feedbackComment {
        id
        status
        step
      }
    }
  }
`

const overallContextPlaceholder = category => {
  switch (category) {
    case 'REPORT':
      return 'Background information on the report e.g. End of term report for home class'
    case 'SUBJECT':
      return 'Background information on the subject e.g. Mathematics for Grade 8'
    default:
      return 'Background information on the task, or the completed submission e.g. Essay on the topic of "Modern media and the role of video"'
  }
}

const OrganizeStep = ({ feedbackCommentId, ...props }) => {
  const queryClient = useQueryClient()
  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: props
  })

  const { mutate: updateFeedbackComment } = useMutation({
    mutationFn: async variables => request(UPDATE_FEEDBACK_COMMENT_MUTATION, variables)
  })

  const next = data => {
    updateFeedbackComment({
      updateFeedbackCommentInput: {
        ...data,
        id: feedbackCommentId,
        step: "REFLECT"
      }
    })

    queryClient.setQueryData(['feedbackComment', feedbackCommentId], old => ({
      node: {
        ...old.node,
        ...data,
        step: "REFLECT"
      }
    }))
  }

  return (
    <Card className="p-5">
      <form onSubmit={handleSubmit(next)} className="flex flex-col">
        <div className="flex md:flex-row flex-col md:space-x-5">
          <div className="w-full mb-4">
            <Select
              id="comment-type"
              label="Comment type"
              className="w-full"
              required
              {...register('category', { required: 'Comment type is required' })}
            >
              <Select.Option value="">Select a comment type</Select.Option>
              <Select.Option value="REPORT">Report comment</Select.Option>
              <Select.Option value="SUBJECT">Subject comment</Select.Option>
              <Select.Option value="TASK">Task-specific comment</Select.Option>
            </Select>
            <If condition={errors.category}>
              <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.category.message}</p>
            </If>
          </div>

          <div className="w-full mb-4">
            <Select
              id="length"
              label="Comment length"
              className="w-full"
              required
              {...register('length', { required: 'Comment length is required' })}
            >
              <Select.Option value="">Select a comment length</Select.Option>
              <Select.Option value="SENTENCE">Sentence</Select.Option>
              <Select.Option value="SHORT_PARAGRAPH">Short paragraph</Select.Option>
              <Select.Option value="LONG_PARAGRAPH">Long paragraph</Select.Option>
            </Select>
            <If condition={errors.length}>
              <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.length.message}</p>
            </If>
          </div>
        </div>

        <div className="mb-4">
          <TextArea
            id="overall-context"
            label="Overall context"
            placeholder={overallContextPlaceholder(watch('category'))}
            required
            {...register('overallContext', { required: "Overall context is required" })} />
          <If condition={errors.overallContext}>
            <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.overallContext.message}</p>
          </If>
        </div>

        <Button label="Next" type="submit" className="w-fit ml-auto" />
      </form>
    </Card>
  )
}

export default OrganizeStep
