const TechnicalDifficultiesBanner = () => (
  <div className='bg-orange-100 p-3 rounded-md leading-tight my-3'>
    <p>
      Mindjoy is experiencing technical difficulties 🤕
      We're working to fix the problem and will have the service back up shortly.
      Thanks for your patience!
    </p>
  </div>
)

export default TechnicalDifficultiesBanner