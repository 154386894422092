import { useSearchParams } from 'react-router-dom'

import Card from '@components/Card'
import ButtonLink from '@components/ButtonLink'

const Purchase = () => {
  const [searchParams] = useSearchParams()
  const status = searchParams.get('status')

  return (
    <div className="flex justify-center">
      <Card className="flex flex-col p-5 mt-10 w-[400px]">
        <Choose>
          <When condition={status === 'success'}>
            <img src="/purchase-success.png" alt="Purchase success" className="m-3 w-2/3 self-center" />

            <h2 className="text-2xl font-bold font-heading">Woohoo! Purchase successful</h2>
            <p>Thanks for being a super educator.</p>

            <ButtonLink to="/educators" theme="primary" label="Let's get started" className="mt-5" />
          </When>

          <Otherwise>
            <img src="/purchase-cancelled.png" alt="Purchase cancelled" className="m-3 w-2/3 self-center" />

            <h2 className="text-2xl font-bold font-heading">Purchase cancelled</h2>
            <p>No problem, feel free to sign up later.</p>

            <ButtonLink to="/educators" theme="secondary" label="Back" className="mt-5"></ButtonLink>
          </Otherwise>
        </Choose>
      </Card>
    </div>
  )
}

export default Purchase
