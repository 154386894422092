import BreadCrumbs from '@components/Breadcrumbs'

const Skeleton = () => (
  <div>
    <BreadCrumbs>
      <BreadCrumbs.Link label='Tutors' to='/sign_in' />
      <div className='w-20 h-4 bg-gray-200' />
    </BreadCrumbs>

    <div className='w-full h-[calc(100%-45px)] flex flex-col justify-start items-center text-center mt-10'>
      <div className='md:rounded-lg rounded-none w-[400px] h-[300px] bg-gray-200 animate-pulse mb-5' />
      <div className='w-[200px] h-6 bg-gray-200 mb-3' />
      <div className='w-[300px] h-4 bg-gray-200 mb-1' />
      <div className='w-[300px] h-4 bg-gray-200' />

      <div className='w-36 h-12 bg-gray-200 mt-5 rounded-md' />
    </div>
  </div>
)

export default Skeleton
