import { useQueryClient, useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Card from '@components/Card'

const UPDATE_LESSON_PLAN_MUTATION = gql`
  mutation updateLessonPlan($updateLessonPlanInput: UpdateLessonPlanInput!) {
    updateLessonPlan(input: $updateLessonPlanInput) {
      lessonPlan {
        status
        step
      }
    }
  }
`

const buildFinalLessonPlan = ({ title, lessonPlan, studentsWillBeAbleTo, overview }) => (
  `Title:\n\n${title}\n\nOverview:\n\n${overview}\n\nStudents will be able to:\n\n${studentsWillBeAbleTo}\n\nLesson Plan:\n\n${lessonPlan}`
)

const FinishStep = ({ lessonPlanId, ...props }) => {
  const queryClient = useQueryClient()

  const { mutate: updateLessonPlan } = useMutation({
    mutationFn: async variables => request(UPDATE_LESSON_PLAN_MUTATION, variables)
  })

  const copyToClipboard = () => {
    const text = buildFinalLessonPlan(props)
    navigator.clipboard.writeText(text)
  }

  const back = () => {
    updateLessonPlan({
      updateLessonPlanInput: {
        id: lessonPlanId,
        step: "PLAN",
      }
    })

    queryClient.setQueryData(['lessonPlan', lessonPlanId], old => ({
      node: { ...old.node, step: "PLAN" }
    }))
  }

  return (
    <Card className="p-5">
      <div className="flex flex-col items-center justify-center">
        <img className="w-1/4 mt-5" src="/success-dance.gif" />
        <h2 className="font-bold text-2xl mb-3">Success!</h2>
        <p>You've successfully created a lesson plan.</p>

        <button className="p-4 w-fit mt-5 bg-blue-500 text-white rounded-xl flex flex-row items-center" onClick={copyToClipboard}>
          <DocumentDuplicateIcon className="h-6 w-6 text-white mr-2" />
          Copy to clipboard
        </button>
      </div>

      <Button className="w-fit mt-10" onClick={back} label="Back" type="button" variant="outlined" />
    </Card>
  )
}

export default FinishStep
