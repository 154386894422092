import Card from '@components/Card'

const NoResults = () => (
  <Card className='w-auto flex flex-col items-center justify-center p-10 mt-5'>
    <img src='/searching.svg' alt='Student reading' className='w-[100px] self-center mb-5' />
    <h4 className='text-2xl font-semibold'>No results</h4>
  </Card>
)

export default NoResults
