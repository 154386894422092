import { twMerge } from 'tailwind-merge'

const notificationTheme = theme => {
  switch (theme) {
    case 'success':
      return 'bg-green-100 border-green-800'
    case 'warning':
      return 'bg-orange-100 border-orange-800'
    case 'error':
      return 'bg-red-100 border-red-800'
    case 'secondary':
      return 'bg-purple-100 border-purple-800'
    default:
      return 'bg-blue-100 border-blue-800'
  }
}

const Notification = ({ children, theme, className }) => (
  <div className={
    twMerge(
      'flex flex-row items-center font-semibold w-full border rounded-md p-3',
      notificationTheme(theme),
      className
    )}>
    {children}
  </div>
)

export default Notification
