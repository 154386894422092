import { Link } from 'react-router-dom'
import Card from '@components/Card'

const NoResults = () => (
  <Card className='flex flex-col items-center justify-center p-10'>
    <img src='/reading.svg' alt='Student reading' className='w-[100px] self-center mb-5' />
    <h4 className='text-2xl font-semibold'>No courses assigned</h4>
    <p>

      <Link to='/educators/courses' className='inline text-blue-500'>
        Assign a course
      </Link>

      &nbsp;to your classroom to get started.
    </p>
  </Card>
)

export default NoResults
