import { Combobox as HeadlessCombobox } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'

const Option = ({ key, value, label }) => (
  <HeadlessCombobox.Option
    key={key}
    value={value}
    className='relative cursor-default hover:cursor-pointer select-none py-2 px-3 ui-active:bg-blue-100 text-gray-900 ui-active:text-blue-900'
  >
    <span className='block truncate'>{label}</span>

    <span className='ui-selected:flex hidden absolute inset-y-0 right-0 items-center pr-3 ui-active:text-blue-900 ui-active:bg-blue-100'>
      <CheckIcon className='h-5 w-5' aria-hidden='true' />
    </span>
  </HeadlessCombobox.Option>
)

export default Option
