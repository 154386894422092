import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import Label from './Label'

const OptionComponent = ({ value, children }) => (
  <option value={value}>{children}</option>
)

const SelectComponent = forwardRef(({ id, label, onChange, required, className, ...props }, ref) => (
  <div className={twMerge("w-fit", className)}>
    <Label labelfor={id} title={label} required={required} />

    <select
      id={id}
      className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-base sm:leading-6"
      onChange={onChange}
      ref={ref}
      {...props}
    />
  </div >
))

let Select = SelectComponent
Select.Option = OptionComponent

export default Select
