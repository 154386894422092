const EmptyState = ({ name }) => (
  <div className="h-full flex flex-col justify-center items-center">
    <div className="bg-blue-100 text-center p-3 rounded-xl w-2/3">
      <h3 className="text-xl font-bold">{name}</h3>
      <p className="mt-3 text-sm">
        This is a software program created to help you learn with AI. Responses are aren't always factually accurate. When working with AI, your job is to be curious, responsible and have fun!
      </p>
    </div>
  </div>
)

export default EmptyState
