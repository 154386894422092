import Spinner from '@components/Spinner'

const Loading = ({ step }) => (
  <div className="relative top-0 left-0 flex flex-col items-center justify-center w-full h-screen">
    <Spinner className="w-10 h-10 text-white" />

    <h3 className="mt-5 font-semibold">
      <Choose>
        <When condition={step === 'PRIORITIZE'}>
          Creating a title, overview and student objectives...
        </When>
        <When condition={step === 'PLAN'}>
          Building your lesson plan...
        </When>
        <When condition={step === 'FINISH'}>
          Putting it all together...
        </When>
        <Otherwise>
          Loading...
        </Otherwise>
      </Choose>

    </h3>
  </div >
)

export default Loading
