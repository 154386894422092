import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'
import { useAnalytics } from '@contexts/analytics'
import Button from '@components/Button'
import Card from '@components/Card'
import Select from '@components/Select'
import TextInput from '@components/TextInput'

const UPDATE_ORGANIZATION_MUTATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
        name
        organizationType
      }
      errors {
        message
      }
    }
  }
`

const OrganizationDetails = ({ nextStep, organizationId, organizationName, organizationType }) => {
  const { track } = useAnalytics()
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: { name: organizationName, organizationType: organizationType }
  })

  const { mutate: updateOrganization, isLoading: isUpdating } = useMutation({
    mutationFn: async variables => await request(UPDATE_ORGANIZATION_MUTATION, { input: { id: organizationId, ...variables } }),
    onSuccess: () => {
      track('Educator Onboarding Step Completed', { step: 'organization' })
      nextStep()
    }
  })

  const submit = data => updateOrganization(data)

  return (
    <div className='w-full flex justify-center'>
      <Card className='flex flex-col sm:w-[400px] p-5 my-10'>
        <img src='/school.svg' alt='School' className='w-1/3 self-center mt-5' />

        <h1 className='text-2xl font-bold mt-3 self-center'>Your organization</h1>
        <p className='leading-tight my-3'>Tell us a little bit about your organization.</p>

        <form className='flex flex-col space-y-4 mt-3' onSubmit={handleSubmit(submit)}>
          <If condition={errors.general}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>
              {errors.general.message}
            </p>
          </If>

          <div>
            <TextInput
              id='organization-name'
              label='Organization name'
              required
              {...register('name', { required: 'Organization name is required' })}
            />
            <If condition={errors.name}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.name.message}</p>
            </If>
          </div>

          <div>
            <Select
              id='organization-type'
              label='Organization type'
              className='w-full'
              required
              {...register('organizationType', { required: 'Organization type is required' })}
            >
              <Select.Option value=''>Select answer</Select.Option>
              <Select.Option value='SCHOOL_K12'>School (K12)</Select.Option>
              <Select.Option value='UNIVERSITY'>University</Select.Option>
              <Select.Option value='ONLINE_SCHOOL'>Online school</Select.Option>
              <Select.Option value='MICROSCHOOL'>Microschool</Select.Option>
              <Select.Option value='HOMESCHOOL'>Homeschool</Select.Option>
              <Select.Option value='TUTOR_PROGRAMME'>Tutor programme</Select.Option>
              <Select.Option value='NGO'>NGO</Select.Option>
              <Select.Option value='OTHER'>Other</Select.Option>
            </Select>
            <If condition={errors.organizationType}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.organizationType.message}</p>
            </If>
          </div>

          <Button className='w-fit ml-auto' type='submit' label={isUpdating ? 'Saving...' : 'Next'} disabled={isUpdating} />
        </form>
      </Card>
    </div>
  )
}

export default OrganizationDetails
