import { useParams } from 'react-router-dom'
import { gql } from 'graphql-request'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useForm, FormProvider } from 'react-hook-form'

import { request } from '@helpers/graphql'
import BreadCrumbs from '@components/Breadcrumbs'

import ChatDetails from './ChatDetails'
import EmptyChat from './EmptyChat'
import Skeleton from './Skeleton'
import NotFound from './NotFound'

const TUTOR_ASSIGNMENT_QUERY = gql`
  query tutorAssignment($id: ID!) {
    node(id: $id) {
      ... on TutorAssignment {
        id
        createdAt
        classroom {
          id
          name
        }
        proFeaturesEnabled
        tutor {
          id
          name
          summary
          createdAt
          image {
            url
          }
          userName
          organizationName
        }
        lastChat {
          id
          createdAt
        }
      }
    }
  }
`

const CREATE_CHAT_MUTATION = gql`
  mutation createAssignmentChat($input: CreateAssignmentChatInput!) {
    createAssignmentChat(input: $input) {
      chat {
        id
        createdAt
      }
    }
  }
`

const TutorAssignmentDetails = () => {
  const { id: tutorAssignmentId } = useParams()
  const methods = useForm({ mode: 'onTouched', defaultValues: { message: null, attachments: [] } })

  const { isLoading, refetch, data = { node: {} } } = useQuery({
    queryKey: ['tutorAssignment', tutorAssignmentId],
    queryFn: async () => request(TUTOR_ASSIGNMENT_QUERY, { id: tutorAssignmentId })
  })

  const { tutor, lastChat, proFeaturesEnabled } = data.node || {}

  const {
    mutate: createChat,
    isLoading: isCreating
  } = useMutation({
    mutationFn: async () => request(CREATE_CHAT_MUTATION, { input: { assignmentId: tutorAssignmentId } }),
    onSuccess: refetch
  })

  if (isLoading) return null

  return (
    <>
      <Choose>
        <When condition={isLoading}>
          <Skeleton />
        </When>

        <When condition={!tutor}>
          <NotFound navigateBackTo='/students/tutors' />
        </When>

        <Otherwise>
          <BreadCrumbs>
            <BreadCrumbs.Link label='Tutors' to='/students/tutors' />
            <BreadCrumbs.Text label={tutor.name} />
          </BreadCrumbs>

          <div className='relative flex flex-col sm:flex-row h-[calc(100%-45px)] bg-white'>
            <div className='hidden md:flex flex-col md:w-1/4 w-full overflow-y-auto p-3 border-r'>
              <If condition={tutor.image?.url}>
                <img className='object-cover rounded-xl w-full h-[200px] mb-3' src={tutor.image.url} />
              </If>

              <h2 className='text-xl font-semibold leading-tight'>{tutor.name}</h2>

              <If condition={tutor.summary}>
                <p className='text-gray-600 mt-1 leading-tight'>{tutor.summary}</p>
              </If>
            </div>

            <Choose>
              <When condition={!!lastChat}>
                <FormProvider {...methods}>
                  <ChatDetails
                    name={tutor.name}
                    tutorId={tutor.id}
                    chatId={lastChat.id}
                    proFeaturesEnabled={proFeaturesEnabled}
                  />
                </FormProvider>
              </When>

              <Otherwise>
                <EmptyChat name={tutor.name} createChat={createChat} />
              </Otherwise>
            </Choose>
          </div>
        </Otherwise>
      </Choose>
    </>
  )
}

export default TutorAssignmentDetails
