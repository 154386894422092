import { useForm, Controller } from 'react-hook-form'

import Button from '@components/Button'
import TextArea from '@components/TextArea'
import Modal from '@components/Modal'
import LinkButton from '@components/LinkButton'
import Toggle from '@components/Toggle'
import Pill from '@components/Pill'
import Tooltip from '@components/Tooltip'

import { useEducatorProject, useUpdateEducatorProject } from '../hooks'

const LessonPromptModal = ({ id, closeModal, isOpen, prompt, socraticModeEnabled, stemModeEnabled }) => {
  const { refetch } = useEducatorProject({ id })
  const { updateProject, status } = useUpdateEducatorProject({
    id,
    onSuccess: () => {
      refetch()
      closeModal()
    }
  })

  const { control, register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: { prompt, socraticModeEnabled, stemModeEnabled }
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
    >
      <div className='flex flex-col h-full'>
        <h3 className='font-heading text-2xl font-bold mb-3'>Update Lesson</h3>

        <form
          onSubmit={handleSubmit(updateProject)}
          className='flex flex-col md:px-0 px-5'
        >
          <div className='mb-5'>
            <TextArea
              className='h-[100px]'
              id='description'
              label='Prompt'
              {...register('prompt')}
            />
            <If condition={errors.prompt}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.prompt.message}</p>
            </If>

            <p className='mt-1 text-sm leading-tight'>
              You can describe a subject to focus on, the contents of a particular lesson, or even the style of the tutor. Be creative!
            </p>
          </div>

          <div className='mb-3'>
            <Controller
              name='socraticModeEnabled'
              control={control}
              render={({ field }) => (
                <Toggle
                  {...field}
                  label='Socratic mode'
                  description='This turns on probing questions instead of direct answers'
                />
              )}
            />
          </div>

          <div className='mb-3'>
            <Controller
              name='stemModeEnabled'
              control={control}
              render={({ field }) => (
                <Toggle
                  {...field}
                  id='stem-mode-enabled'
                  label='STEM mode'
                  description='This turns on accurate calculations and visualisations for STEM related subjects (e.g. mathematics, science, physics, chemistry, etc.)'
                />
              )}
            />
          </div>

          <div className='flex flex-row justify-between items-center mt-3'>
            <LinkButton
              className='text-gray-500'
              onClick={closeModal}
            >
              Cancel
            </LinkButton>

            <Button
              disabled={status === 'loading'}
              label={status === 'loading' ? 'Saving...' : 'Save'}
              type='submit'
              className='w-fit'
            />
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default LessonPromptModal
