import { XMarkIcon } from '@heroicons/react/24/solid'
import { twMerge } from 'tailwind-merge'

const pillTheme = theme => {
  switch (theme) {
    case 'success':
      return 'bg-green-500'
    case 'error':
      return 'bg-red-500'
    case 'secondary':
      return 'bg-purple-500'
    case 'light':
      return 'bg-gray-200 text-gray-900'
    default:
      return 'bg-blue-500'
  }
}

const DismissiblePill = ({ label, theme, className, onDismiss }) => (
  <>
    <span className={twMerge('flex items-center rounded-3xl text-white py-1 px-3 text-sm m-1', pillTheme(theme), className)}>
      {label}

      <button onClick={onDismiss} className='ml-1'>
        <XMarkIcon className='h-4 w-4' />
      </button>
    </span>
  </>
)

export default DismissiblePill
