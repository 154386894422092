import { PaperClipIcon } from '@heroicons/react/24/outline'
import React, { useState, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { buttonTheme } from './Button'

const MultipleFileInput = ({
  label = 'Upload files',
  helpText,
  theme = 'primary',
  variant = 'default',
  tabIndex,
  className,
  ...props
}) => {
  const fileInputRef = useRef()

  return (
    <>
      <button
        type='button'
        tabIndex={tabIndex}
        htmlFor='upload-button'
        className={twMerge(
          'rounded-md px-3.5 py-2.5 text-base font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
          'disabled:cursor-not-allowed',
          buttonTheme(variant, theme),
          className
        )}
        onClick={() => fileInputRef.current.click()}
      >

        <PaperClipIcon className='h-4 w-4 inline-block mr-2' />
        {label}
      </button>

      <If condition={helpText}>
        <small className='mt-1'>{helpText}</small>
      </If>

      <input
        ref={fileInputRef}
        id='upload-button'
        type='file'
        multiple
        className='hidden'
        {...props}
      />
    </>
  )
}

export default MultipleFileInput
