import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm, Controller } from 'react-hook-form'
import { CheckIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Select from '@components/Select'
import TextInput from '@components/TextInput'
import Toggle from '@components/Toggle'

const UPDATE_ORGANIZATION_MUTATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
        name
        verificationStatus
        privateStudentChatsEnabled
        organizationType
      }
      errors {
        message
      }
    }
  }
`

const Settings = ({ organizationId, name, verificationStatus, privateStudentChatsEnabled, organizationType, refetch }) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onTouched', defaultValues: { name, verificationStatus, privateStudentChatsEnabled, organizationType } })

  const { mutate: updateOrganization, isLoading: isUpdating, isSuccess } = useMutation({
    mutationFn: async variables => await request(UPDATE_ORGANIZATION_MUTATION, { input: { id: organizationId, ...variables } }),
    onSuccess: () => refetch()
  })

  const onSubmit = data => updateOrganization(data)

  return (

    <div className='w-auto px-5 py-3'>
      <form
        className='flex flex-col'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='w-full mb-3'>
          <TextInput
            id='name'
            label='Name'
            {...register('name', { required: 'Name is required' })}
          />
          <If condition={errors.name}>
            <p className='mt-1 text-sm font-semibold text-red-500' role='alert'>{errors.name.message}</p>
          </If>
        </div>

        <div className='w-full mb-3'>
          <Select
            id='organization-type'
            label='Organization type'
            {...register('organizationType')}
          >
            <Select.Option value=''>Select answer</Select.Option>
            <Select.Option value='SCHOOL_K12'>School (K12)</Select.Option>
            <Select.Option value='UNIVERSITY'>University</Select.Option>
            <Select.Option value='ONLINE_SCHOOL'>Online school</Select.Option>
            <Select.Option value='MICROSCHOOL'>Microschool</Select.Option>
            <Select.Option value='HOMESCHOOL'>Homeschool</Select.Option>
            <Select.Option value='TUTOR_PROGRAMME'>Tutor programme</Select.Option>
            <Select.Option value='NGO'>NGO</Select.Option>
            <Select.Option value='OTHER'>Other</Select.Option>
          </Select>
          <If condition={errors.organizationType}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.organizationType.message}</p>
          </If>
        </div>

        <div className='w-full mb-3'>
          <Select
            id='verificationStatus'
            label='Verification status'
            {...register('verificationStatus')}
          >
            <Select.Option value='PENDING'>Pending</Select.Option>
            <Select.Option value='VERIFIED'>Verified</Select.Option>
            <Select.Option value='REJECTED'>Rejected</Select.Option>
          </Select>
        </div>

        <div className='w-full mb-3'>
          <Controller
            name='privateStudentChatsEnabled'
            control={control}
            {...register('privateStudentChatsEnabled')}
            render={({ field }) => (
              <Toggle
                {...field}
                label='Private student chats'
                description='This setting makes student chats private and only allows educators to view summarised chat information.'
              />
            )}
          />
        </div>

        <div className='ml-auto'>
          <If condition={isSuccess}>
            <CheckIcon className='mr-2 inline-block h-5 w-5' />
          </If>

          <Button
            className='mb-5 w-fit'
            theme='primary'
            disabled={isUpdating}
            type='submit'
            label={isUpdating ? 'Saving...' : 'Save'}
          />
        </div>
      </form>
    </div>
  )
}

export default Settings
