import FlashMessage from '@components/FlashMessage'

const Main = ({ children }) => (
  // Navbar is 57px in height
  <main className="flex flex-col w-full overflow-auto h-[calc(100vh-57px)] lg:h-screen relative bg-gray-50">
    <FlashMessage />

    {children}
  </main>
)

export default Main
