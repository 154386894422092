import { twMerge } from 'tailwind-merge'

const pillTheme = theme => {
  switch (theme) {
    case 'success':
      return 'bg-green-500'
    case 'error':
      return 'bg-red-500'
    case 'secondary':
      return 'bg-purple-500'
    case 'light':
      return 'bg-gray-200 text-gray-900'
    default:
      return 'bg-blue-500'
  }
}

const Pill = ({ label, theme, className, ...props }) => (
  <span {...props} className={twMerge("rounded-3xl text-white py-1 px-3 text-xs m-1", pillTheme(theme), className)}>
    {label}
  </span>
)

export default Pill
