import Card from '@components/Card'

const NoTutorsAssignedNotice = () => (
  <Card className='w-auto flex flex-col items-center justify-center p-10 m-5'>
    <img src='/reading.svg' alt='Student reading' className='w-[120px] self-center mb-5' />
    <h4 className='text-2xl font-semibold'>No tutors assigned</h4>
    <p>
      You don't have any tutors assigned to you (just yet!)
    </p>
  </Card>
)

export default NoTutorsAssignedNotice
