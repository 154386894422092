import { useState } from 'react'

const InsightExpandableList = ({ data }) => {
  const [expandedItem, setExpandedItem] = useState(null)

  const toggleItem = (index) => {
    setExpandedItem(expandedItem === index ? null : index)
  }

  return (
    <div className='space-y-2'>
      <For each='item' index='index' of={data}>
        <div key={index} className='border rounded-lg overflow-hidden'>
          <button
            className='w-full text-left p-3 focus:outline-none hover:bg-gray-100 flex justify-between items-center bg-white'
            onClick={() => toggleItem(index)}
          >
            <span className='font-semibold text-gray-900'>{item.name}</span>
            <span className='text-2xl text-gray-500'>{expandedItem === index ? '−' : '+'}</span>
          </button>
          {expandedItem === index && (
            <div className='p-3 bg-gray-50'>
              <p className='text-gray-700'>{item.details}</p>
            </div>
          )}
        </div>
      </For>
    </div>
  )
}

export default InsightExpandableList
