import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'

const Image = ({ className, src, alt, placeholder, ...props }) => {
  const [isLoading, setIsLoading] = useState(true)

  return (
    <>
      <If condition={isLoading}>
        {placeholder}
      </If>

      <img
        {...props}
        className={twMerge(className, isLoading ? 'hidden' : 'block')}
        src={src}
        alt={alt}
        onLoad={() => setIsLoading(false)}
      />
    </>
  )
}

export default Image
