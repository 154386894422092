import { useSearchParams } from 'react-router-dom'

import ButtonLink from '@components/ButtonLink'
import Card from '@components/Card'

const AuthenticationError = () => {
  const [searchParams] = useSearchParams()
  const message = searchParams.get('message')

  return (
    <Card className='flex flex-col p-5 sm:w-[350px] md:mt-10 mt-5 self-center'>
      <h1 className='text-2xl font-bold'>Something went wrong 🤔</h1>

      <If condition={!!message}>
        <strong className='font-semibold mt-3'>Error message</strong>

        <p className='leading-tight rounded-md bg-gray-100 p-3'>
          <Choose>
            <When condition={message === 'account_not_found'}>
              We couldn't find a user with that email address. Make sure you're using the same email address you used to sign up.
            </When>

            <When condition={message === 'signup_error'}>
              Your invitation doesn't seem to be working. You may have used it already, or it expired.
            </When>

            <When condition={message === 'email_taken'}>
              This email address is already in use. Please sign in or use a different email address.
            </When>

            <When condition={message === 'email_invalid'}>
              Please make sure to sign up with your school email address.
            </When>
          </Choose>
        </p>
      </If>

      <p className='mt-3 leading-tight'>
        If you need any help, please let us know via&nbsp;
        <a href='mailto:support@mindjoy.com' className='mt-5 text-blue-600 hover:underline'>support@mindjoy.com</a>
        .
      </p>

      <ButtonLink className='mt-5' theme='primary' to='/' label='Back' />
    </Card>
  )
}

export default AuthenticationError
