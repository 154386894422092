import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm } from 'react-hook-form'

import { request } from '@helpers/graphql'
import TextInput from '@components/TextInput'
import Button from '@components/Button'


const UPDATE_VIDEO_BLOCK_MUTATION = gql`
  mutation updateVideoBlock($input: UpdateVideoBlockInput!) {
    updateVideoBlock(input: $input) {
      video {
        url
      }
      errors {
        message
      }
    }
  }
`

const formatUrl = (url) => {
  if (!url) return null

  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url
  }

  return `https://${url}`
}

const VideoForm = ({ id, url, refetch }) => {
  const { register, handleSubmit, formState: { errors } } = useForm({ defaultValues: { url } })

  const { mutate: updateVideoBlock, isLoading } = useMutation({
    mutationFn: async variables => request(UPDATE_VIDEO_BLOCK_MUTATION, { input: { id, ...variables } }),
    onSuccess: () => refetch()
  })

  const onSubmit = ({ url }) => {
    const formattedUrl = formatUrl(url)

    updateVideoBlock({ url: formattedUrl })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-5 py-5">
      <TextInput
        label="Video URL"
        placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
        {...register('url', { required: 'Video URL is required' })}
      />
      <If condition={errors.url}>
        <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.url.message}</p>
      </If>

      <small className="mt-2">You can add videos from Youtube, Vimeo, Facebook and Twitch.</small>

      <Button
        className="ml-auto"
        type="submit"
        disabled={isLoading}
        label={isLoading ? 'Saving...' : 'Save'}
      />
    </form>
  )
}

export default VideoForm
