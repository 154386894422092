import Card from '@components/Card'

const Skeleton = () => (
  <div className="w-full flex justify-center">
    <Card className="flex flex-col sm:w-[400px] p-5 my-10">
      <div className='animate-pulse'>
        <div className="w-24 h-24 bg-gray-200 rounded-full mb-5 self-center" />

        <div className="w-1/2 h-8 bg-gray-200 mb-3" />
        <div className="w-3/4 h-3 bg-gray-200 mb-1" />

        <div className='flex flex-col gap-5'>
          <div className="flex gap-3 mt-5">
            <div className="w-full h-8 bg-gray-200 rounded-md" />
            <div className="w-full h-8 bg-gray-200 rounded-md" />
          </div>

          <div className="w-full h-8 bg-gray-200 rounded-md" />
          <div className="w-full h-8 bg-gray-200 rounded-md" />
          <div className="w-20 h-10 bg-gray-200 rounded-md ml-auto" />
        </div>
      </div>
    </Card>
  </div>
)

export default Skeleton