import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { setUser as setSentryUser } from '@sentry/react'

import { useCurrentUser } from '@contexts/currentUser'
import { client } from '@helpers/api'
import { useAnalytics } from '@contexts/analytics'
import Button from '@components/Button'
import TextInput from '@components/TextInput'
import PasswordInput from '@components/PasswordInput'
import Spinner from '@components/Spinner'
import Notification from '@components/Notification'
import { useFlashMessage } from '@components/FlashMessage'

const SignUpForm = ({ shortCode, classroomName }) => {
  const { setFlashMessage } = useFlashMessage()
  const navigate = useNavigate()
  const { identify } = useAnalytics()
  const { register, handleSubmit, setError, formState: { errors } } = useForm({ mode: 'onTouched' })
  const { setUser } = useCurrentUser()
  const [isFormVisible, setIsFormVisible] = useState(false)

  const { mutateAsync: joinClassroom, isLoading } = useMutation({
    mutationFn: async variables => await client.post('/student/classroom_joins', variables),
    onSuccess: response => {
      setUser({ loading: false, signedIn: true, ...response.data })
      setSentryUser({ id: response.data.id, email: response.data.email, role: response.data.role })
      identify(response.data.id)
      setFlashMessage(`You have joined ${classroomName} 🎉`, 'success')
      navigate('/students')
    },
    onError: error => {
      const message = error?.response?.data?.error

      if (message === 'Email has already been taken') {
        setError('email', { message })
      } else if (message === 'Student limit reached') {
        setError('general', { message: 'Oops! This classroom has reached it\'s student limit. ' })
      }
      else {
        setError('general', { message: 'Oops! Something went wrong.' })
      }
    }
  })

  const submit = data => {
    joinClassroom({ ...data, short_code: shortCode })
  }

  return (
    <>
      <Choose>
        <When condition={!isFormVisible}>
          <Button
            label="Create student account"
            className="mt-5 w-full"
            onClick={() => setIsFormVisible(true)} />
        </When>

        <Otherwise>
          <form className="flex flex-col space-y-4 mt-5" onSubmit={handleSubmit(submit)}>
            <If condition={errors.general}>
              <Notification theme="error">
                {errors.general.message}
              </Notification>
            </If>

            <div className="flex gap-3">
              <div className="w-full">
                <TextInput
                  id="first-name"
                  label="First name"
                  required
                  {...register('first_name', { required: "First name is required" })} />
                <If condition={errors.first_name}>
                  <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.first_name.message}</p>
                </If>
              </div>

              <div className="w-full">
                <TextInput
                  id="last-name"
                  label="Last name"
                  required
                  {...register('last_name', { required: "Last name is required" })} />
                <If condition={errors.last_name}>
                  <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.last_name.message}</p>
                </If>
              </div>
            </div>

            <div>
              <TextInput
                id="email"
                label="Email"
                required
                {...register('email', { required: "Email is required", pattern: { value: /(.+)@(.+){2,}\.(.+){2,}/i, message: "Email must be valid" } })}
              />
              <If condition={errors.email}>
                <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.email.message}</p>
              </If>

              <small className="text-sm">Make sure to use your school email address</small>
            </div>

            <div>
              <PasswordInput
                id="password"
                label="Password"
                required
                {...register('password', { required: "Password is required", minLength: { value: 8, message: "Password must be at least 8 characters" } })}
              />
              <If condition={errors.password}>
                <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.password.message}</p>
              </If>
            </div>

            <Button
              type="submit"
              label={isLoading ? <Spinner className="[&_*]:bg-white" /> : "Create account"}
              disabled={isLoading} />
          </form>
        </Otherwise>
      </Choose>
    </>
  )
}

export default SignUpForm


