import Card from '@components/Card'

const NoResults = ({ lessonSelected }) => (
  <Card className='w-auto flex flex-col items-center justify-center p-10'>
    <img src='/swingset.svg' alt='Student reading' className='w-[120px] self-center mb-5' />
    <h4 className='text-2xl font-semibold'>No insights just yet</h4>
    <If condition={!lessonSelected}>
      <p>
        Select a lesson to view student insights
      </p>
    </If>
  </Card>
)

export default NoResults
