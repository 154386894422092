const SuggestionList = ({ children }) => {
  if (!children) return null

  return (
    <div className="flex flex-col flex-wrap mt-auto mb-5 gap-2 animate-[appear_0.3s_linear]">
      {children}
    </div>
  )
}

export default SuggestionList
