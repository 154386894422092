import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { BanknotesIcon, LinkIcon } from '@heroicons/react/24/outline'

import BreadCrumbs from '@components/Breadcrumbs'
import Image from '@components/Image'
import SecureMarkdown from '@components/SecureMarkdown'

import NotFound from './NotFound'
import ContentCard from './ContentCard'
import TutorCard from './TutorCard'
import creatorData from './creatorData'

const ContentDetails = () => {
  const { id } = useParams()
  const creator = creatorData[id]

  return (
    <Choose>
      <When condition={!creator}>
        <NotFound />
      </When>

      <Otherwise>
        <Helmet>
          <title>Mindjoy Creators - {creator.name}</title>
          <meta property='og:title' content={`Mindjoy - ${creator.name}`} />
          <meta property='og:type' content='website' />
          <meta property='og:url' content={window.location.href} />
          <If condition={creator.imageUrl}>
            <meta property='og:image' content={creator.imageUrl} />
          </If>
        </Helmet>

        <div className='flex flex-col items-center'>
          <BreadCrumbs className='w-full'>
            <BreadCrumbs.Link label='Creators' to='/educators/creators' />
            <BreadCrumbs.Text label={creator.name} />
          </BreadCrumbs>

          <div className='w-full h-[calc(100%-45px)] p-5'>
            <div className='flex gap-5 md:flex-row flex-col'>
              <If condition={creator.profileImageUrl}>
                <Image
                  className='object-cover w-[300px] h-[300px] rounded-full mb-3 md:mb-0'
                  src={creator.profileImageUrl}
                  placeholder={<div className='w-[300px] h-[300px] shrink-0 bg-gray-200 animate-pulse rounded-full mb-3 md:mb-0' />}
                />
              </If>

              <div className='flex flex-col'>
                <p className='font-semibold mb-3 text-lg'>Creator profile</p>
                <h2 className='text-4xl font-bold text-purple-600'>{creator.name}</h2>

                <div className='flex my-5 gap-2'>
                  <For each='tag' of={creator.tags}>
                    <span key={tag} className='inline-block w-fit shrink-0 text-normal font-semibold bg-gray-200 px-3 py-1 rounded-full'>{tag}</span>
                  </For>
                </div>

                <p className='text-gray-900 text-lg leading-tight'>{creator.headline}</p>

                <div className='flex gap-3 mt-3'>
                  <If condition={creator.linkedinUrl}>
                    <a
                      target='_blank'
                      href={creator.linkedinUrl}
                      className='flex justify-center items-center rounded-full p-3 text-base shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-gray-600' rel='noreferrer'
                    >
                      <img src='/linkedin-logo.png' className='w-6 h-6' />
                    </a>
                  </If>

                  <If condition={creator.youtubeUrl}>
                    <a
                      target='_blank'
                      href={creator.youtubeUrl}
                      className='flex justify-center items-center rounded-full p-3 text-base shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-gray-600' rel='noreferrer'
                    >
                      <img src='/youtube-logo.png' className='w-6 h-6' />
                    </a>
                  </If>

                  <If condition={creator.blogUrl}>
                    <a
                      target='_blank'
                      href={creator.blogUrl}
                      className='flex justify-center items-center rounded-full p-3 text-base shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-gray-600' rel='noreferrer'
                    >
                      <LinkIcon className='w-6 h-6' />
                    </a>
                  </If>
                </div>

                <a
                  href='https://buy.stripe.com/5kA7vj5GC5YX42AbIK'
                  className='mt-5 w-fit flex items-center bg-gradient-to-tr from-blue-500 to-purple-600 hover:to-purple-500 hover:from-blue-400 rounded-md px-3.5 py-2.5 text-base font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                >
                  Say thanks <BanknotesIcon className='w-6 h-6 ml-1' />
                </a>
              </div>
            </div>

            <h4 className='font-semibold text-lg mt-5'>About this creator</h4>
            <SecureMarkdown content={creator.biography} />

            <If condition={creator.tutors.length > 0}>
              <h3 className='text-3xl mt-10 font-bold text-purple-600'>Tutors & simulations</h3>

              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5'>
                <For each='content' of={creator.tutors}>
                  <TutorCard
                    key={content.name}
                    title={content.name}
                    description={`Made by ${creator.name}`}
                    callToAction='Try now'
                    imageUrl={content.imageUrl}
                    profileImageUrl={creator.profileImageUrl}
                    url={content.url}
                  />
                </For>
              </div>
            </If>

            <h3 className='text-3xl mt-10 font-bold text-purple-600'>Learn with {creator.name.split(' ')[0]}</h3>

            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5'>
              <ContentCard
                className='first-child:object-contain'
                title='How to Build'
                callToAction='Start tutorial'
                imageUrl='/reading.svg'
                url={creator.howToBuildUrl}
              />

              <ContentCard
                title='AI Teacher Labs'
                callToAction='Join session'
                imageUrl='/blocks.svg'
                url={creator.eventUrl}
              />

              <ContentCard
                title='Creator course'
                callToAction='View course'
                imageUrl='/brainstorm-board.svg'
                url={creator.courseUrl}
              />
            </div>
          </div>
        </div>
      </Otherwise>
    </Choose>
  )
}

export default ContentDetails
