import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { LightBulbIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Card from '@components/Card'
import TextInput from '@components/TextInput'
import TextArea from '@components/TextArea'
import Notification from '@components/Notification'

const UPDATE_LESSON_PLAN_MUTATION = gql`
  mutation updateLessonPlan($updateLessonPlanInput: UpdateLessonPlanInput!) {
    updateLessonPlan(input: $updateLessonPlanInput) {
      lessonPlan {
        status
        step
      }
    }
  }
`

const PrioritizeStep = ({ lessonPlanId, startPolling, ...props }) => {
  const queryClient = useQueryClient()
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: props
  })

  const { mutate: updateLessonPlan } = useMutation({
    mutationFn: async variables => request(UPDATE_LESSON_PLAN_MUTATION, variables),
    onSuccess: () => startPolling()
  })

  const next = data => {
    updateLessonPlan({
      updateLessonPlanInput: {
        ...data,
        id: lessonPlanId,
        step: "PLAN"
      }
    })

    queryClient.setQueryData(['lessonPlan', lessonPlanId], old => ({
      node: {
        ...old.node,
        status: "PROCESSING",
        step: "PLAN"
      }
    }))
  }

  const back = () => {
    updateLessonPlan({
      updateLessonPlanInput: {
        id: lessonPlanId,
        step: "ORGANIZE",
      }
    })

    queryClient.setQueryData(['lessonPlan', lessonPlanId], old => ({
      node: { ...old.node, step: "ORGANIZE" }
    }))
  }

  return (
    <Card>
      <form onSubmit={handleSubmit(next)} className="flex flex-col space-y-4 m-5">
        <Notification theme="secondary">
          <LightBulbIcon className="inline-block w-5 h-5 text-gray-500 mr-2" />
          <p>Pro tip! You can edit your inputs before moving to the next section.</p>
        </Notification>

        <div>
          <TextInput
            id="title"
            label="Title"
            autoFocus
            required
            {...register('title', { required: "Title is required" })} />
          <If condition={errors.title}>
            <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.title.message}</p>
          </If>
        </div>

        <div>
          <TextArea
            id="overview"
            label="Overview"
            required
            {...register('overview', { required: "Overview is required" })} />
          <If condition={errors.overview}>
            <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.overview.message}</p>
          </If>
        </div>

        <div>
          <TextArea
            id="students-will-be-able-to"
            label="Students will be able to"
            required
            {...register('studentsWillBeAbleTo', { required: "Students will be able to is required" })} />
          <If condition={errors.studentsWillBeAbleTo}>
            <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.studentsWillBeAbleTo.message}</p>
          </If>
        </div>

        <div className="flex flex-row justify-between">
          <Button onClick={back} label="Back" type="button" variant="outlined" />
          <Button label="Next" type="submit" />
        </div>
      </form>
    </Card>
  )
}

export default PrioritizeStep
