import Tooltip from '@components/Tooltip'

const ProFeatureTooltip = ({ anchorSelect, place }) => (
  <Tooltip anchorSelect={anchorSelect} place={place}>
    <span className='flex items-center leading-none'>
      <img className='mr-2 w-4 h-4' src='/crown.svg' alt='crown' />
      Upgrade to pro
    </span>
  </Tooltip>
)

export default ProFeatureTooltip
