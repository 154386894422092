import { useForm } from 'react-hook-form'

import Button from '@components/Button'
import TextInput from '@components/TextInput'
import Modal from '@components/Modal'
import LinkButton from '@components/LinkButton'

import { useEducatorProject, useUpdateEducatorProject } from './hooks'

const LessonNameModal = ({ id, closeModal, isOpen, name }) => {
  const { refetch } = useEducatorProject({ id })
  const { updateProject, status } = useUpdateEducatorProject({
    id,
    onSuccess: () => {
      refetch()
      closeModal()
    }
  })

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: { name }
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
    >
      <div className='flex flex-col h-full'>
        <h3 className='font-heading text-2xl font-bold mb-3'>Update Lesson</h3>

        <form
          onSubmit={handleSubmit(updateProject)}
          className='flex flex-col md:px-0 px-5'
        >
          <div className='mb-3'>
            <TextInput
              id='name'
              label='Name'
              required
              {...register('name', { required: 'Name is required' })}
            />

            <If condition={errors.name}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.name.message}</p>
            </If>
          </div>

          <div className='flex flex-row justify-between items-center mt-3'>
            <LinkButton
              className='text-gray-500'
              onClick={closeModal}
            >
              Cancel
            </LinkButton>

            <Button
              disabled={status === 'loading'}
              label={status === 'loading' ? 'Saving...' : 'Save'}
              type='submit'
              className='w-fit'
            />
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default LessonNameModal
