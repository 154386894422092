import TextEditor from './TextEditor'
import Container from './Container'
import Attachment from './Attachment'
import AttachmentList from './AttachmentList'
import FileInput from './FileInput'
import ToolbarToggle from './ToolbarToggle'
import Actions from './Actions'
import MathExtension from './MathExtension'
import TableBubbleMenu from './TableBubbleMenu'

TextEditor.Container = Container
TextEditor.Attachment = Attachment
TextEditor.AttachmentList = AttachmentList
TextEditor.FileInput = FileInput
TextEditor.ToolbarToggle = ToolbarToggle
TextEditor.Actions = Actions

export default TextEditor

export {
  MathExtension,
  TableBubbleMenu
}
