import { useForm } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import {
  CheckIcon,
  XMarkIcon,
  NumberedListIcon
} from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import TextInput from '@components/TextInput'
import SecureMarkdown from '@components/SecureMarkdown'

const UPDATE_QUESTION_SUBMISSION = gql`
  mutation UpdateQuestionSubmission($input: UpdateQuestionSubmissionInput!) {
    updateQuestionSubmission(input: $input) {
      blockSubmission {
        id
        status
        blockId
        content {
          ... on QuestionSubmission {
            __typename
            marks
          }
        }
      }
    }
  }
`

const MultipleChoiceQuestionForm = ({ id, question, options, markAllocation, answer, marks, refetch, closeModal }) => {
  const { formState: { errors }, handleSubmit, register } = useForm({
    mode: 'onTouched',
    defaultValues: {
      marks
    }
  })

  const { mutate: updateQuestionSubmission, isLoading: isUpdating } = useMutation({
    mutationFn: async variables => await request(UPDATE_QUESTION_SUBMISSION, { input: { blockSubmissionId: id, ...variables } }),
    onSuccess: () => {
      refetch()
      closeModal()
    }
  })

  const submit = data => updateQuestionSubmission(data)

  return (
    <div>
      <div className='flex items-center mb-5'>
        <NumberedListIcon className='size-8 mr-3 bg-yellow-200 rounded-md p-1 shrink-0' />
        <h3 className='text-2xl font-bold leading-none'>Multiple Choice</h3>
      </div>

      <SecureMarkdown content={question} />

      <ul className='flex flex-col gap-2 mt-5'>
        <For each='option' of={options}>
          <li
            className={twMerge(
              'flex border border-gray-300 p-3 rounded-lg',
              answer.id === option.id && (
                answer.correct
                  ? 'bg-green-100 border-green-600'
                  : 'bg-red-100 border-red-600'
              )
            )}
            key={option.id}
          >
            <SecureMarkdown content={option.label} />

            <If condition={answer.id === option.id}>
              <Choose>
                <When condition={answer.correct}>
                  <CheckIcon className='ml-auto w-6 h-6 text-green-600' />
                </When>

                <Otherwise>
                  <XMarkIcon className='ml-auto w-6 h-6 text-red-600' />
                </Otherwise>
              </Choose>
            </If>
          </li>
        </For>
      </ul>

      <form onSubmit={handleSubmit(submit)} className='flex flex-col mt-3 gap-3'>
        <div>
          <div className='flex items-end gap-3'>
            <TextInput
              id='marks'
              label='Marks'
              type='number'
              className='block w-fit'
              inputClassName='w-[100px]'
              {...register('marks', {
                valueAsNumber: true,
                required: 'Marks are required',
                min: { value: 0, message: "Marks can't be negative" },
                max: { value: markAllocation, message: 'Mark cannot be greater than the mark allocation' }
              })}
            />
            <span className='text-lg font-semibold mb-1'> / {markAllocation}</span>
          </div>

          <If condition={errors.marks}>
            <p className='mt-1 text-sm font-semibold text-red-500' role='alert'>{errors.marks.message}</p>
          </If>
        </div>

        <Button
          className='mt-3 w-fit ml-auto'
          type='submit'
          label={isUpdating ? 'Saving...' : 'Save'}
          disabled={isUpdating}
        />
      </form>
    </div>
  )
}

export default MultipleChoiceQuestionForm
