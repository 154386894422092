import Button from '@components/Button'
import Modal from '@components/Modal'

const SubmitModal = ({ closeModal, isOpen }) => (
  <Modal
    isOpen={isOpen}
    onClose={closeModal}
  >
    <div className=' h-full'>
      <h3 className='font-heading text-2xl font-bold'>Submit lesson</h3>
      <p className='mt-3'>Would you like to submit this lesson? You won't be able to make changes again.</p>

      <div className='flex flex-row justify-between items-center mt-3'>
        <button
          className='text-gray-500 cursor-pointer'
          onClick={closeModal}
        >
          Cancel
        </button>

        <Button
          disabled
          label='Submit'
        />
      </div>
    </div>
  </Modal>
)

export default SubmitModal
