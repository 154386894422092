import Group from './Group'
import List from './List'
import Panel from './Panel'
import Panels from './Panels'
import Tab from './Tab'

Tab.Group = Group
Tab.List = List
Tab.Panel = Panel
Tab.Panels = Panels

export default Tab
