import Button from '@components/Button'
import Modal from '@components/Modal'
import LinkButton from '@components/LinkButton'

const DeleteBlockModal = ({ closeModal, isOpen, deleteBlock, isDeleting }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
    >
      <div className='flex flex-col h-full'>
        <h3 className='font-heading text-2xl font-bold mb-3'>Delete block?</h3>

        <p className='mb-5'>
          Are you sure you'd like to delete this block? This can't be undone.
        </p>

        <div className='flex flex-row justify-between items-center'>
          <LinkButton
            className='text-gray-500'
            onClick={closeModal}
          >
            Cancel
          </LinkButton>

          <Button
            theme='error'
            disabled={isDeleting}
            label={isDeleting ? 'Deleting...' : 'Delete'}
            onClick={deleteBlock}
          />
        </div>
      </div>
    </Modal>
  )
}

export default DeleteBlockModal
