import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { Link, useNavigate } from 'react-router-dom'
import { PlusIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Modal from '@components/Modal'
import Select from '@components/Select'

import { useOnboardingChecklist } from '../../hooks/onboardingChecklist'

const CLASSROOMS_QUERY = gql`
  query classrooms {
    me {
      profile {
        ... on EducatorProfile {
          classrooms {
            id
            name
            educatorProfile {
              fullName
            }
            studentProfiles {
              id
            }
          }
        }
      }
    }
  }
`

const ASSIGN_TUTOR_TO_CLASSROOM_MUTATION = gql`
  mutation assignTutorToClassroom($input: AssignToClassroomInput!) {
    assignTutorToClassroom(input: $input) {
      assignment {
        id
        classroom {
          id
        }
      }
      errors {
        message
      }
    }
  }
`

const AssignClassroomsModal = ({
  closeModal,
  isOpen,
  tutorId,
  name
}) => {
  const navigate = useNavigate()
  const { markOnboardingItemCompleted } = useOnboardingChecklist()

  const { data: { me: { profile: { classrooms: educatorClassrooms = [] } = {} } = {} } = {} } = useQuery({
    queryKey: ['classrooms'],
    queryFn: async () => request(CLASSROOMS_QUERY)
  })

  const { mutate: assign, isLoading: isAssigning } = useMutation({
    mutationFn: async data => request(ASSIGN_TUTOR_TO_CLASSROOM_MUTATION, { input: { tutorId, ...data } }),
    onSuccess: data => {
      const { assignment: { id: assignmentId, classroom: { id: classroomId } } } = data.assignTutorToClassroom
      navigate(`/educators/classrooms/${classroomId}/tutors/${tutorId}/sessions/${assignmentId}`)
      closeModal()
      markOnboardingItemCompleted('assignedTutorToClassroom')
    }
  })

  const { handleSubmit, register } = useForm({
    mode: 'onTouched'
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
    >
      <h3 className='font-heading text-2xl font-bold mb-3'>Assign</h3>
      <p className='mb-3'>You're about to create a session for <strong>{name}</strong>.</p>

      <form
        onSubmit={handleSubmit(data => { assign(data) })}
        className='flex flex-col mt-3'
      >
        <div className='bg-gray-100 p-3 rounded-md'>
          <Select
            className='w-full'
            label='Select a classroom'
            {...register('classroomId', { required: 'Classroom is required' })}
          >
            <Select.Option key='' value=''>Select a classroom</Select.Option>
            <For each='classroom' of={educatorClassrooms}>
              <Select.Option key={classroom.id} value={classroom.id}>{classroom.name}</Select.Option>
            </For>
          </Select>

          <Link to='/educators/classrooms' className='flex items-center text-blue-500 mt-3'>
            <PlusIcon className='h-5 w-5 mr-2' />
            add new classroom
          </Link>
        </div>

        <Button
          theme='secondary'
          disabled={isAssigning}
          label={isAssigning ? 'Assigning...' : 'Assign'}
          type='submit'
          className='w-fit mt-3 self-end'
        />
      </form>
    </Modal>
  )
}

export default AssignClassroomsModal
