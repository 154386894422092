import { useEffect } from 'react'
import { Outlet, useLocation } from "react-router-dom"
import { useQuery } from '@tanstack/react-query'

import { useCurrentUser } from '@contexts/currentUser'
import { useAnalytics } from '@contexts/analytics'
import { client } from '@helpers/api'
import StudentNav from '@components/StudentNav'
import EducatorNav from '@components/EducatorNav'
import AdminNav from '@components/AdminNav'
import PublicNav from '@components/PublicNav'
import Main from '@components/Main'

const Layout = () => {
  const location = useLocation()
  const { page } = useAnalytics()

  const { setUser, user: { loading, signedIn, role } } = useCurrentUser()

  useQuery({
    queryKey: ['me'], queryFn: () => client.get('/me'),
    onSuccess: data => setUser({ ...data.data, loading: false, signedIn: true }),
    onError: data => {
      if (data.response.status === 401) {
        setUser({ loading: false, signedIn: false })
      }
    }
  })

  useEffect(() => {
    page()
  }, [location])

  return (
    <div className="flex flex-row">
      <Choose>
        <When condition={!signedIn}>
          <PublicNav>
            <Main>
              <If condition={!loading}>
                <Outlet />
              </If>
            </Main>
          </PublicNav>
        </When>

        <When condition={signedIn && role === 'student'}>
          <StudentNav>
            <Main>
              <If condition={!loading}>
                <Outlet />
              </If>
            </Main>
          </StudentNav>
        </When>

        <When condition={signedIn && role === 'educator'}>
          <EducatorNav>
            <Main>
              <If condition={!loading}>
                <Outlet />
              </If>
            </Main>
          </EducatorNav>
        </When>

        <When condition={signedIn && role === 'admin'}>
          <AdminNav>
            <Main>
              <div className="sticky top-0w-full h-[10px] min-h-[10px] bg-green-500" />

              <If condition={!loading}>
                <Outlet />
              </If>
            </Main>
          </AdminNav>
        </When>
      </Choose>
    </div>
  )
}

export default Layout
