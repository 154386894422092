import { useState, useCallback } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { gql } from 'graphql-request'
import { useQuery, useMutation } from '@tanstack/react-query'
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline'
import debounce from 'lodash/debounce'
import { useNavigate } from 'react-router-dom'

import { request } from '@helpers/graphql'
import { stripMarkdown } from '@helpers/format'
import Button from '@components/Button'
import Combobox from '@components/Combobox'
import Modal from '@components/Modal'
import TextInput from '@components/TextInput'
import Image from '@components/Image'

const CREATE_EDUCATOR_PROJECT_MUTATION = gql`
  mutation createEducatorProject($input: CreateEducatorProjectInput!) {
    createEducatorProject(input: $input) {
      educatorProject {
        id
        name
        createdAt
        updatedAt
      }
      errors {
        message
      }
    }
  }
`

const TUTORS_QUERY = gql`
  query tutors($audience: AudienceEnum!, $search: String, $tags: [String!], $page: Int) {
    tutors(audience: $audience, search: $search, tags: $tags, page: $page, perPage: 10) {
      nodes {
        id
        name
        description
        summary
        visibility
        image {
          url
        }
      }
      pagesCount
      nodesCount
    }
  }
`

const AddLessonModal = ({ isOpen, onClose, id }) => {
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState(null)

  const { mutate: createEducatorProject, isLoading: isCreating } = useMutation({
    mutationFn: async data => request(CREATE_EDUCATOR_PROJECT_MUTATION, { input: { seriesId: id, ...data } }),
    onSuccess: data => navigate(`/educators/projects/${data.createEducatorProject.educatorProject.id}`)
  })

  const { data: { tutors: { nodes: tutors = [] } = {} } = {}, isFetching: isSearching } = useQuery({
    queryKey: ['tutors', 'ME', searchTerm],
    queryFn: async () => request(TUTORS_QUERY, { audience: 'ME', page: 1, search: searchTerm })
  })

  const debouncedSetSearchTerm = useCallback(
    debounce((text) => setSearchTerm(text), 300),
    []
  )

  const { control, register, handleSubmit, formState: { errors }, watch } = useForm({ mode: 'onTouched' })

  const selectedTutor = watch('tutor')

  const submit = ({ name, tutor }) => {
    createEducatorProject({ name, tutorId: tutor?.id })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='sm'
    >
      <form
        onSubmit={handleSubmit(submit)}
        className='flex flex-col'
      >
        <h3 className='text-2xl font-semibold mb-3'>New lesson</h3>

        <div className='mb-3'>
          <TextInput
            id='name'
            label='Name'
            required
            {...register('name', { required: 'Name is required' })}
          />

          <If condition={errors.name}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.name.message}</p>
          </If>
        </div>

        <div className='mb-5'>
          <Controller
            name='tutor'
            control={control}
            rules={{ required: 'Tutor is required' }}
            render={({ field }) => (
              <Combobox
                id='tutor'
                className='w-full'
                {...field}
                placeholder='Type to search your tutors'
                displayValue={tutor => tutor?.name}
                onSearch={debouncedSetSearchTerm}
                label='Tutor'
                required
              >
                <If condition={tutors.length < 1 && !isSearching}>
                  <p className='ml-3 my-3 text-gray-500'>No tutors found</p>
                </If>

                <For each='tutor' of={tutors}>
                  <Combobox.Option
                    key={tutor.id}
                    value={tutor}
                    label={
                      <div className='flex items-center'>
                        <Choose>
                          <When condition={tutor.image?.url}>
                            <Image
                              src={tutor.image?.url}
                              alt={tutor.name}
                              className='size-10 rounded-md mr-3 object-cover shrink-0'
                              placeholder={<div className='size-10 mr-3 rounded-md bg-gray-200 animate-pulse shrink-0' />}
                            />
                          </When>

                          <Otherwise>
                            <div className='flex items-center justify-center size-10 rounded-md mr-3 bg-gray-100 shrink-0'>
                              <ChatBubbleLeftRightIcon className='size-5 text-gray-400' />
                            </div>
                          </Otherwise>
                        </Choose>

                        <div className='leading-tight truncate'>
                          <p className='leading-none'>{tutor.name}</p>
                          <small className='text-gray-500'>
                            <Choose>
                              <When condition={tutor.summary}>
                                {tutor.summary}
                              </When>
                              <Otherwise>
                                {stripMarkdown(tutor.description)}
                              </Otherwise>
                            </Choose>
                          </small>
                        </div>
                      </div>
                    }
                  />
                </For>
              </Combobox>
            )}
          />

          <small>
            Select the tutor for this lesson
          </small>

          <If condition={errors.tutor}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.tutor.message}</p>
          </If>
        </div>

        <If condition={selectedTutor}>
          <div className='flex mb-5'>
            <Choose>
              <When condition={selectedTutor.image?.url}>
                <Image
                  src={selectedTutor.image?.url}
                  alt={selectedTutor.name}
                  className='size-[100px] rounded-md mr-3 object-cover shrink-0'
                  placeholder={<div className='size-[100px] mr-3 rounded-md bg-gray-200 animate-pulse shrink-0' />}
                />
              </When>

              <Otherwise>
                <div className='flex items-center justify-center size-[100px] rounded-md mr-3 bg-blue-100 shrink-0'>
                  <ChatBubbleLeftRightIcon className='size-10 text-blue-400' />
                </div>
              </Otherwise>
            </Choose>

            <div className='leading-tight'>
              <h3 className='text-lg font-semibold leading-none'>{selectedTutor.name}</h3>
              <p className='text-gray-500'>
                <Choose>
                  <When condition={selectedTutor.summary}>
                    {selectedTutor.summary}
                  </When>

                  <Otherwise>
                    {stripMarkdown(selectedTutor.description)}
                  </Otherwise>
                </Choose>
              </p>
            </div>
          </div>
        </If>

        <Button
          type='submit'
          label={isCreating ? 'Creating...' : 'Create lesson'}
        />
      </form>
    </Modal>
  )
}

export default AddLessonModal
