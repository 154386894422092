import { twMerge } from 'tailwind-merge'

const Header = ({ children, className, ...props }) => (
  <th
    className={twMerge("py-2 pl-3 pr-3 text-left text-base font-semibold text-gray-900", className)}
    {...props}>
    {children}
  </th>
)

export default Header
