import { twMerge } from 'tailwind-merge'
import { Switch } from '@headlessui/react'

const toggleTheme = (theme, enabled) => {
  switch (theme) {
    case 'success':
      return twMerge(enabled && 'bg-green-600 focus:ring-green-600')
    case 'error':
      return twMerge(enabled && 'bg-red-600 focus:ring-red-600')
    case 'secondary':
      return twMerge(enabled && 'bg-purple-600 focus:ring-purple-600')
    default:
      return twMerge(enabled && 'bg-blue-600 focus:ring-blue-600')
  }
}

const Toggle = ({
  id,
  value,
  theme,
  onChange,
  className,
  name,
  label,
  description,
  disabled
}) => {
  return (
    <Switch.Group
      as='div'
      name={name}
      className={twMerge('flex items-center gap-5', className)}
    >
      <Switch
        id={id}
        disabled={disabled}
        checked={value}
        onChange={onChange}
        className={twMerge(
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent disabled:bg-gray-300 bg-gray-400 duration-200 ease-in-out ring-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 disabled:cursor-not-allowed',
          toggleTheme(theme, value)
        )}
      >
        <span
          aria-hidden='true'
          className={twMerge(
            value ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>

      <span className='flex flex-grow flex-col'>
        <Switch.Label as='span' className='text-base font-semibold leading-6 text-gray-900' passive>
          {label}
        </Switch.Label>
        <Switch.Description as='span' className='text-sm'>
          {description}
        </Switch.Description>
      </span>
    </Switch.Group>
  )
}

export default Toggle
