const creatorData = {
  'matthew-wemyss': {
    slug: 'matthew-wemyss',
    name: 'Matthew Wemyss',
    organizationName: 'Cambridge School of Bucharest',
    profileImageUrl: 'https://d2w7g5mzahgod1.cloudfront.net/creators/matthew-wemyss.jpg',
    headline: 'Assistant School Director | Innovation and Education | Passionate about AI in Education',
    linkedinUrl: 'https://www.linkedin.com/in/matthew-wemyss-68781b23/',
    youtubeUrl: 'https://www.youtube.com/@matthewwemyssINandED',
    blogUrl: 'https://matthewwemyss.wordpress.com/',
    howToBuildUrl: 'https://blog.mindjoy.com/how-to-build-a-subject-revision-bot-with-personality',
    eventUrl: 'https://lu.ma/u17f7p4b',
    courseUrl: null,
    biography: 'Matthew is Assistant School Director at Cambridge School of Bucharest, is devoted to fusing traditional education with futuristic technologies. His leadership in incorporating AI, VR, and Metaverse applications underlines her commitment to preparing students for a tech-driven future. \n\nAt the Cambridge School of Bucharest, innovation meets education through strategic initiatives led by Matthew. The school\'s commitment to developing future-ready learners is demonstrated through its embrace of cutting-edge technologies and a curriculum designed to empower students in a global society.',
    tags: ['🇷🇴 Romania', 'CAT teacher'],
    tutors: [
      {
        url: '/educators/tutors/Z2lkOi8vY29tbWVudHMvVHV0b3IvMjk1Mg',
        name: 'Rocky Bit Boa',
        imageUrl: 'https://d2w7g5mzahgod1.cloudfront.net/creators/rocky-bit-boa.jpeg'
      }
    ]
  },
  'alex-gray': {
    slug: 'alex-gray',
    name: 'Alex Gray',
    organizationName: 'Dubai English School',
    profileImageUrl: 'https://d2w7g5mzahgod1.cloudfront.net/creators/alex-gray.jpg',
    headline: 'Educational Innovator in Secondary | Leader & Founder of DEEP Professional',
    linkedinUrl: 'https://www.linkedin.com/in/alexandergray84/',
    youtubeUrl: 'https://www.youtube.com/channel/UCyLuBzMOZIgAomABsyOVp1g',
    websiteUrl: null,
    howToBuildUrl: 'https://blog.mindjoy.com/how-to-build-a-personalised-project-based-learning-tutor/',
    eventUrl: null,
    courseUrl: null,
    biography: 'Alex is the Head of Science at Dubai British School, where he supports his staff in curriculum development, syllabus design and CPD. When he\'s not finding new ways to introduce science to his learners, you can find him hosting his podcast "The International Classroom".',
    tags: ['United Arab Emirates 🇦🇪', 'Science'],
    tutors: [
      {
        url: '/educators/tutors/Z2lkOi8vY29tbWVudHMvVHV0b3IvMzA1Nw',
        name: 'Dr. Bravestone\'s Expeditions',
        imageUrl: 'https://d2w7g5mzahgod1.cloudfront.net/creators/brave.png'
      }
    ]
  },
  'harun-boke': {
    slug: 'harun-boke',
    name: 'Harun Böke',
    organizationName: 'English School of Kyrenia',
    profileImageUrl: 'https://d2w7g5mzahgod1.cloudfront.net/creators/harun-boke.jpg',
    headline: 'AI-literate maths teacher | Flipped Learning Master Practitioner',
    linkedinUrl: 'https://www.linkedin.com/in/charunboke/',
    youtubeUrl: 'https://www.youtube.com/',
    websiteUrl: 'https://www.google.com/',
    howToBuildUrl: 'https://blog.mindjoy.com/how-to-build-a-flipped-classroom-maths-mentor',
    eventUrl: null,
    courseUrl: null,
    biography: 'Harun is an experienced Mathematics teacher, curriculum designer and international examiner. Harun implements a flipped-classroom mastery approach in order to personalize learning and differentiate learning journeys for his students. All while using technology to enhance the process of course!',
    tags: ['🇹🇷 Turkey', 'Maths'],
    tutors: [
      {
        url: '/educators/tutors/Z2lkOi8vY29tbWVudHMvVHV0b3IvMzQ3Nw',
        name: 'Flipped classroom maths mentor',
        imageUrl: 'https://d2w7g5mzahgod1.cloudfront.net/creators/flipped-classroom.png'
      }
    ]
  },
  'marie-allirot': {
    slug: 'marie-allirot',
    name: 'Marie Allirot',
    organizationName: 'Bede\'s Prep School',
    profileImageUrl: 'https://d2w7g5mzahgod1.cloudfront.net/creators/marie-allirot.jpg',
    headline: 'French and Graphic Design Teacher | AI explorer',
    linkedinUrl: 'https://www.linkedin.com/in/marie-allirot-b625841b4/',
    websiteUrl: null,
    youtubeUrl: 'https://www.youtube.com/watch?v=_G08vW1kOpU',
    howToBuildUrl: 'https://blog.mindjoy.com/how-to-build-a-language-learning-escape-room-tutor',
    eventUrl: null,
    courseUrl: null,
    biography: 'Marie is a French teacher at Bede\'s School in Sussex, England. With a wealth of experience as Deputy Housemistress and Housemistress in Boarding, Marie creates enriching environments for pupils through the integration of technology. Lately, that\'s meant being a part of her school\'s AI group in order to explore introducing AI into their teaching.',
    tags: ['🇬🇧 United Kingdom ', 'French', 'Graphic design'],
    tutors: [
      {
        url: '/educators/tutors/Z2lkOi8vY29tbWVudHMvVHV0b3IvMzIxNA',
        name: 'Lucas and the French Escape Room',
        imageUrl: 'https://d2w7g5mzahgod1.cloudfront.net/creators/lucas.png'
      }
    ]
  },
  'bleu-wenzel': {
    slug: 'bleu-wenzel',
    name: 'Bleu Wenzel',
    organizationName: 'Alpha School',
    profileImageUrl: 'https://d2w7g5mzahgod1.cloudfront.net/creators/bleu-wenzel.png',
    headline: 'Transforming schools with AI | Enhancing education and emotional intelligence for the future',
    linkedinUrl: 'https://www.linkedin.com/in/jenson-wenzel-4704bb10a/',
    websiteUrl: null,
    youtubeUrl: null,
    howToBuildUrl: null,
    eventUrl: null,
    courseUrl: 'https://beta.mindjoy.com/educators/courses/Z2lkOi8vY29tbWVudHMvU2VyaWVzLzc2MA',
    biography: 'Jenson Wenzel is a transformative educator and mentor, specializing in integrating AI to enhance education and emotional intelligence. Passionate about supporting neurodivergent students, Jenson excels in motivational coaching, personalized learning through AI chatbots, and innovative instructional methods. By coordinating education teams and pioneering new educational trends, Jenson ensures an engaging and efficient learning experience that prioritizes real-world applications over traditional lectures. \n\nTransforming schools with AI, enhancing education and emotional intelligence for the future. We are a K-12 private school dedicated to transforming education by integrating cutting-edge AI technologies and innovative teaching methodologies for limitless learning. Our students love school, learn 2X fast, and learn life skills. We believe that success lies in hard work and innovation.',
    tags: ['United States 🇺🇸', 'Guide'],
    tutors: []
  },
  'hiba-bou-ajram': {
    slug: 'hiba-bou-ajram',
    name: 'Hiba Bou Ajram',
    organizationName: 'English Modern School Doha',
    profileImageUrl: 'https://d2w7g5mzahgod1.cloudfront.net/creators/hiba.jpeg',
    headline: '21st Century Learning',
    linkedinUrl: 'https://www.linkedin.com/in/hibab/',
    websiteUrl: null,
    youtubeUrl: null,
    howToBuildUrl: null,
    eventUrl: null,
    courseUrl: null,
    biography: 'Hiba is a dedicated educator with a strong foundation in software technology and over five years of teaching experience. As a 21st Century Learning Coordinator, she excels in integrating advanced technologies, especially AI, into educational frameworks. Her leadership skills, developed in the software industry, complement her innovative teaching methods, making her an ideal candidate for roles that blend cutting-edge technology with pedagogical strategies.',
    tags: ['United Arab Emirates 🇦🇪', '21st Century Skills'],
    tutors: []
  },
  'claire-pattle': {
    slug: 'claire-pattle',
    name: 'Claire Pattle',
    organizationName: 'Thomas More College',
    profileImageUrl: 'https://d2w7g5mzahgod1.cloudfront.net/creators/claire.jpeg',
    headline: '21st Century Learning',
    linkedinUrl: 'https://www.linkedin.com/in/clairepattle/',
    websiteUrl: null,
    youtubeUrl: null,
    howToBuildUrl: null,
    eventUrl: null,
    courseUrl: null,
    biography: 'Claire is a passionate educator with a diverse background in teaching and senior management. She has extensive experience as a senior teacher and Deputy Head in independent schools. Clair specializes in Physical Education and Geography, holding an MCSE and A+ certification. She has taught Computer Applications Technology for 12 years and advanced levels of the Microsoft Office Suite and Google Workspace. Clair is a Google Certified Educator (Levels 1 and 2) and a certified Apple Teacher, dedicated to integrating technology into teaching and learning. She excels in coaching and mentoring teachers to explore innovative pedagogies such as PBL, inquiry-based, and design thinking lessons, and currently teaches Primary school pupils ICT skills, integrating academic content and technology through cross-curricular methods.',
    tags: ['South Africa 🇿🇦', 'Digital skills'],
    tutors: []
  },
  'matt-hains': {
    slug: 'matt-hains',
    name: 'Matt Hains',
    organizationName: 'Kingsmead College',
    profileImageUrl: 'https://d2w7g5mzahgod1.cloudfront.net/creators/matt-hains.png',
    headline: 'Computer Applications Technology | Digital Technology Integration',
    linkedinUrl: 'https://www.linkedin.com/in/mhainsonline/',
    websiteUrl: null,
    youtubeUrl: null,
    howToBuildUrl: null,
    eventUrl: null,
    courseUrl: null,
    biography: 'Matt Hains is an award-winning Digital Technology Educator specializing in Computer Applications Technology for Grades 10 through 12. With a Master\'s in Education focusing on ICT, Matt is a Microsoft Innovative Educator Expert and Fellow, recognized for his contributions to professional ICT development and training. He is a two-time ISPA Tech Teacher of the Year and serves as the national IEB Examiner for Grade 12 C.A.T. practical exams. As an author and speaker, Matt shares his expertise at educational conferences and through platforms like The CAT Collective, fostering online collaboration among C.A.T. teachers in South Africa.',
    tags: ['South Africa 🇿🇦', 'CAT'],
    tutors: []
  },
  'xoliswa-mahlungu': {
    slug: 'xoliswa-mahlungu',
    name: 'Xoliswa Mahlungu',
    organizationName: 'Future Nations Schools',
    profileImageUrl: 'https://d2w7g5mzahgod1.cloudfront.net/creators/xoliswa.jpeg',
    headline: 'Digital Learning | Technology',
    linkedinUrl: 'https://www.linkedin.com/in/xoliswa-mahlangu-a9b49720/',
    websiteUrl: null,
    youtubeUrl: null,
    howToBuildUrl: null,
    eventUrl: null,
    courseUrl: null,
    biography: 'Xoliswa is a Curriculum Developer with a Master of Engineering in Software Engineering from the University of the Witwatersrand. She has a proven track record in the primary and secondary education industry, with skills in business processes, analytical skills, requirements analysis, databases, and facilitation. Xoliswa\'s strong professional background equips her to design effective and innovative curricula that enhance educational outcomes.',
    tags: ['South Africa 🇿🇦', 'Digital learning', 'Technology'],
    tutors: []
  }
}

export default creatorData
