import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { CheckIcon } from '@heroicons/react/24/outline'

import { client } from '@helpers/api'
import Button from '@components/Button'
import Card from '@components/Card'
import TextInput from '@components/TextInput'

const SecuritySettings = () => {
  const { register, handleSubmit, setError, getValues, formState: { errors } } = useForm({ mode: 'onTouched' })
  const { mutate: updatePassword, isLoading, isSuccess } = useMutation({
    mutationFn: async variables => await client.patch('/users', variables),
    onError: error => {
      const errors = error?.response?.data?.errors

      if (errors.current_password) {
        setError('current_password', { message: 'Current password is incorrect' })
      }
    }
  })

  return (
    <Card className='p-5'>
      <h2 className='text-2xl font-bold font-heading'>Security settings</h2>

      <form className='flex flex-col space-y-4 mt-5' onSubmit={handleSubmit(data => updatePassword({ user: data }))}>
        <div>
          <TextInput
            id='current-password'
            label='Current password'
            type='password'
            required
            {...register('current_password', { required: 'Current password is required to make account changes' })}
          />
          <If condition={errors.current_password}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.current_password.message}</p>
          </If>
        </div>

        <div>
          <TextInput
            id='password'
            label='New password'
            type='password'
            required
            {...register('password', { required: 'New password is required', minLength: { value: 8, message: 'Password must be at least 8 characters' } })}
          />
          <If condition={errors.password}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.password.message}</p>
          </If>
        </div>

        <div>
          <TextInput
            id='password-confirmation'
            label='New password confirmation'
            type='password'
            required
            {...register('password_confirmation', { required: 'Password confirmation is required', validate: value => value === getValues('password') || 'Passwords do not match' })}
          />
          <If condition={errors.password_confirmation}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.password_confirmation.message}</p>
          </If>
        </div>

        <div className='flex flex-row justify-end items-center'>
          <If condition={isSuccess}>
            <CheckIcon className='h-6 w-6 text-green-700 mr-3' />
          </If>

          <Button
            className='w-fit'
            type='submit'
            disabled={!!isLoading}
            label={isLoading ? 'Saving...' : 'Save'}
          />
        </div>
      </form>
    </Card>
  )
}

export default SecuritySettings
