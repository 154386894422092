import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { LightBulbIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Card from '@components/Card'
import TextArea from '@components/TextArea'
import Notification from '@components/Notification'

const UPDATE_LESSON_PLAN_MUTATION = gql`
  mutation updateLessonPlan($updateLessonPlanInput: UpdateLessonPlanInput!) {
    updateLessonPlan(input: $updateLessonPlanInput) {
      lessonPlan {
        status
        step
      }
    }
  }
`

const PlanStep = ({ lessonPlanId, lessonPlan }) => {
  const queryClient = useQueryClient()
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: { lessonPlan }
  })

  const { mutate: updateLessonPlan } = useMutation({
    mutationFn: async variables => request(UPDATE_LESSON_PLAN_MUTATION, variables),
    onSuccess: () => queryClient.invalidateQueries(['lessonPlan', lessonPlanId])
  })

  const next = data => {
    updateLessonPlan({
      updateLessonPlanInput: {
        ...data,
        id: lessonPlanId,
        step: "FINISH"
      }
    })

    queryClient.setQueryData(['lessonPlan', lessonPlanId], old => ({
      node: {
        ...old.node,
        status: "PROCESSING",
        step: "FINISH"
      }
    }))
  }

  const back = () => {
    updateLessonPlan({
      updateLessonPlanInput: {
        id: lessonPlanId,
        step: "PRIORITIZE",
      }
    })

    queryClient.setQueryData(['lessonPlan', lessonPlanId], old => ({
      node: {
        ...old.node,
        step: "PRIORITIZE"
      }
    }))
  }

  return (
    <Card>
      <form onSubmit={handleSubmit(next)} className="flex flex-col space-y-4 m-5">
        <Notification theme="secondary">
          <LightBulbIcon className="inline-block w-5 h-5 text-gray-500 mr-2" />
          <p>Pro tip! You can edit your inputs before moving to the next section.</p>
        </Notification>

        <div>
          <TextArea
            id="lesson-plan"
            label="Lesson plan"
            className="h-[400px]"
            autoFocus
            required
            {...register('lessonPlan', { required: "Lesson plan is required" })} />
          <If condition={errors.lessonPlan}>
            <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.lessonPlan.message}</p>
          </If>
        </div>

        <div className="flex flex-row justify-between">
          <Button onClick={back} label="Back" type="button" variant="outlined" />
          <Button label="Next" type="submit" />
        </div>
      </form>
    </Card>
  )
}

export default PlanStep
