import { ArrowRightIcon } from '@heroicons/react/24/outline'

const Suggestion = ({ text, onClick }) => (
  <button
    onClick={onClick}
    className="sm:w-fit w-full bg-gray-200 shrink-0 flex text-left leading-none justify-between items-center px-2 py-1 rounded-lg border-transparent hover:bg-gray-300 hover:cursor-pointer border-2">
    {text}
    <ArrowRightIcon className="h-5 w-5 ml-2 shrink-0" />
  </button>
)

export default Suggestion
