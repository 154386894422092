import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useParams, Link } from 'react-router-dom'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'
import BreadCrumbs from '@components/Breadcrumbs'
import PageHeading from '@components/PageHeading'
import Spinner from '@components/Spinner'
import Notification from '@components/Notification'

import OrganizeStep from './OrganizeStep'
import PrioritizeStep from './PrioritizeStep'
import PlanStep from './PlanStep'
import FinishStep from './FinishStep'
import StepBanner from './StepBanner'
import Loading from './Loading'

const LESSON_PLAN_QUERY = gql`
  query lessonPlan($id: ID!) {
    node(id: $id) {
      ... on LessonPlan {
        status
        step
        grade
        duration
        subject
        topic
        objective
        title
        overview
        studentsWillBeAbleTo
        lessonPlan
        methodology
        educatorProfile {
          id
          fullName
        }
        organization {
          id
          name
        }
      }
    }
  }
`

const LessonPlanDetails = () => {
  const { id } = useParams()
  const [refetchInverval, setRefetchInterval] = useState(null)
  const startPolling = () => setRefetchInterval(2000)
  const stopPolling = () => setRefetchInterval(null)

  const { isLoading, data: { node: lessonPlan } = {} } = useQuery({
    queryKey: ['lessonPlan', id],
    queryFn: async () => request(LESSON_PLAN_QUERY, { id }),
    refetchInterval: refetchInverval,
    onSettled: data => {
      if (refetchInverval && (data.node.status === 'COMPLETED' || data.node.status === 'FAILED')) {
        stopPolling()
      }
    }
  })

  if (isLoading) return <Spinner className="relative top-0 left-0 flex items-center justify-center w-full h-screen" />

  return (
    <>
      <BreadCrumbs className="py-3 px-5 border-b-gray-200 border-b bg-white">
        <BreadCrumbs.Link label="Lesson plans" to="/educators/tools/lesson_plans" />
        <BreadCrumbs.Text label={lessonPlan.topic ? lessonPlan.topic : 'Lesson plan'} />
      </BreadCrumbs>

      <PageHeading title="Lesson plan" />

      <div className="md:p-5">
        <StepBanner currentStep={lessonPlan.step} />

        <If condition={lessonPlan.status === 'FAILED'}>
          <Notification theme="error">
            <p>Oops! Something went wrong 😔</p>
          </Notification>
        </If>

        <Choose>
          <When condition={lessonPlan.status === 'PROCESSING'}>
            <Loading step={lessonPlan.step} />
          </When>

          <When condition={lessonPlan.step === 'ORGANIZE'}>
            <OrganizeStep
              lessonPlanId={id}
              startPolling={startPolling}
              grade={lessonPlan.grade}
              duration={lessonPlan.duration}
              subject={lessonPlan.subject}
              topic={lessonPlan.topic}
              objective={lessonPlan.objective}
              methodology={lessonPlan.methodology}
            />
          </When>

          <When condition={lessonPlan.step === 'PRIORITIZE'}>
            <PrioritizeStep
              lessonPlanId={id}
              startPolling={startPolling}
              title={lessonPlan.title}
              overview={lessonPlan.overview}
              studentsWillBeAbleTo={lessonPlan.studentsWillBeAbleTo}
            />
          </When>

          <When condition={lessonPlan.step === 'PLAN'}>
            <PlanStep
              lessonPlanId={id}
              lessonPlan={lessonPlan.lessonPlan}
            />
          </When>

          <When condition={lessonPlan.step === 'FINISH'}>
            <FinishStep
              {...lessonPlan}
              lessonPlanId={id}
            />
          </When>
        </Choose>
      </div>
    </>
  )
}

export default LessonPlanDetails
