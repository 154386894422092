import { createContext, useContext } from 'react'

const AnalyticsContext = createContext()

const AnalyticsProvider = ({ analytics, children }) => (
  <AnalyticsContext.Provider value={{ ...analytics }}>
    {children}
  </AnalyticsContext.Provider>
)

const useAnalytics = () => {
  const context = useContext(AnalyticsContext)

  if (!context) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider')
  }

  return context
}

export { AnalyticsProvider, useAnalytics }
