import { useState, useEffect } from 'react'

const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key) // eslint-disable-line
    return storedValue !== null ? JSON.parse(storedValue) : defaultValue
  })

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value)) // eslint-disable-line
  }, [key, value])

  return [value, setValue]
}

export {
  useLocalStorage
}
