import Spinner from '@components/Spinner'

const Loading = ({ step }) => (
  <div className="relative top-0 left-0 flex flex-col items-center justify-center w-full mt-[200px]">
    <Spinner className="w-10 h-10 text-white" />

    <h3 className="mt-5 font-semibold">
      <Choose>
        <When condition={step === 'COMMENT'}>
          Building your comment...
        </When>
        <Otherwise>
          Loading...
        </Otherwise>
      </Choose>

    </h3>
  </div >
)

export default Loading
