import Card from '@components/Card'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { ChatBubbleLeftEllipsisIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { startCase } from 'lodash'

import { formatDate } from '@helpers/format'
import Tooltip from '@components/Tooltip'
import CopyToClipboardButton from '@components/CopyToClipboardButton'

const Item = ({ label, value }) => (
  <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
    <dt className='font-medium'>{label}</dt>
    <dd className='mt-1 text-gray-700 sm:col-span-2 sm:mt-0 leading-tight'>{value}</dd>
  </div>
)

const On = () => (
  <div className='flex gap-2 items-center text-gray-700'>
    On
    <CheckIcon id='check-icon' className='w-5 h-5' />
  </div>
)

const Off = () => (
  <div className='flex gap-2 items-center text-gray-700'>
    Off
    <XMarkIcon id='off-icon' className='w-5 h-5' />
  </div>
)

const Overview = ({
  tutorId,
  name,
  createdAt,
  description,
  summary,
  socratic,
  suggestionsEnabled,
  stemModeEnabled,
  knowledgeBaseEnabled,
  visibility,
  approvedForCommunity,
  imageUrl,
  userName,
  organizationName
}) => (
  <Card className='w-auto mx-5 my-3'>
    <div className='flex px-4 py-6 sm:px-6'>
      <Choose>
        <When condition={imageUrl}>
          <img src={imageUrl} alt={name} className='w-[300px] h-[200px] object-cover rounded-md' />
        </When>

        <Otherwise>
          <div className='bg-blue-100 rounded-md flex items-center justify-center w-[300px] h-[200px]'>
            <ChatBubbleLeftEllipsisIcon className='w-12 h-12 text-blue-500 m-auto' />
          </div>
        </Otherwise>
      </Choose>

      <div className='flex flex-col ml-5 w-full'>
        <div>
          <h3 className='text-2xl font-semibold leading-7 text-gray-900'>{name}</h3>
          <p className='max-w-2xl leading-6 text-gray-500'>by {userName} at {organizationName}</p>
          <p className='mt-3 max-w-2xl leading-6 text-gray-900'>{summary}</p>
        </div>

        <div className='flex w-full gap-3 mt-3'>
          <CopyToClipboardButton className='flex-gro' label='Public URL' value={`${window.location.origin}/tutors/${tutorId}`} />
          <CopyToClipboardButton className='flex-grw' label='Educator URL' value={`${window.location.origin}/educators/tutors/${tutorId}`} />
        </div>
      </div>
    </div>

    <div className='border-t border-gray-100'>
      <dl className='divide-y divide-gray-100'>
        <Item
          label='Approved for community'
          value={
            <Choose>
              <When condition={approvedForCommunity}>
                <CheckCircleIcon id='verified-icon' className='w-7 h-7 text-green-700' />
                <Tooltip anchorSelect='#verified-icon'>Approved</Tooltip>
              </When>

              <Otherwise>
                <XCircleIcon id='rejected-icon' className='w-6 h-6 text-red-700' />
                <Tooltip anchorSelect='#rejected-icon'>Not approved</Tooltip>
              </Otherwise>
            </Choose>
          }
        />
        <Item label='Created' value={formatDate(createdAt)} />
        <Item label='Visibility' value={startCase(visibility.toLowerCase())} />
        <Item label='Prompt' value={description || '-'} />
        <Item label='Socratic' value={socratic ? <On /> : <Off />} />
        <Item label='Suggested questions' value={suggestionsEnabled ? <On /> : <Off />} />
        <Item label='STEM mode' value={stemModeEnabled ? <On /> : <Off />} />
        <Item label='Knowledge base' value={knowledgeBaseEnabled ? <On /> : <Off />} />
      </dl>
    </div>
  </Card>
)

export default Overview
