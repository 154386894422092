import PageHeading from '@components/PageHeading'

const Workshops = () => (
  <>
    <PageHeading title="Workshops" />

    <iframe
      className="h-full w-full"
      frameborder="0"
      allowfullscreen=""
      aria-hidden="false"
      tabindex="0"
      src="https://lu.ma/embed/calendar/cal-bIw7gsI4KC1cfVo/events?lt=light"
    />
  </>
)

export default Workshops
