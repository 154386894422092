import { Route, Routes, useParams, useLocation, Link } from 'react-router-dom'
import { useQuery, useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import {
  PresentationChartLineIcon,
  LightBulbIcon,
  XMarkIcon,
  ArrowUturnLeftIcon,
  PencilSquareIcon
} from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import { useCurrentUser } from '@contexts/currentUser'
import Breadcrumbs from '@components/Breadcrumbs'
import TabNav from '@components/TabNav'
import Button from '@components/Button'

import SubmissionList from './SubmissionList'
import Insights from './Insights'

const SERIES_QUERY = gql`
  query series($id: ID!) {
    node(id: $id) {
      ... on Series {
        id
        name
        totalPossibleScore
        educatorProjects {
          id
          name
          playground
          position
          totalPossibleScore
        }
      }
    }
  }
`

const CLASSROOM_QUERY = gql`
  query classroom($id: ID!) {
    classroom(id: $id) {
      id
      name
      shortCode
      educatorProfile {
        id
        fullName
      }
    }
  }
`

const ASSIGNMENTS_QUERY = gql`
  query series($id: ID!) {
    node(id: $id) {
      ... on Series {
        assignments {
          id
          createdAt
          closed
          classroom {
            id
            name
          }
        }
      }
    }
  }
`

const UPDATE_ASSIGNMENT_MUTATION = gql`
  mutation updateAssignment($input: UpdateAssignmentInput!) {
    updateAssignment(input: $input) {
      assignment {
        id
      }
    }
  }
`

const CourseActivity = () => {
  const { courseId, classroomId } = useParams()
  const { user: { organization: { features } } } = useCurrentUser()
  const insightsEnabled = features.includes('course_insights')
  const location = useLocation()

  const { data: { node: { name, educatorProjects = [], classrooms = [], totalPossibleScore } = {} } = {}, isLoading: isSeriesLoading } = useQuery(
    ['seriesDetails', courseId],
    async () => request(SERIES_QUERY, { id: courseId })
  )

  const { data: { classroom = {} } = {} } = useQuery({
    queryKey: ['seriesClassroom', classroomId, courseId],
    queryFn: async () => await request(CLASSROOM_QUERY, { id: classroomId })
  })

  const { data: { node: { assignments = [] } = {} } = {}, refetch: refetchAssignments } = useQuery({
    queryKey: ['seriesAssignments', courseId],
    queryFn: async () => request(ASSIGNMENTS_QUERY, { id: courseId })
  })

  const { mutate: updateAssignment } = useMutation({
    mutationFn: async data => request(UPDATE_ASSIGNMENT_MUTATION, { input: { ...data } }),
    onSuccess: () => refetchAssignments()
  })

  const assignment = assignments.find(assignment => assignment.classroom.id === classroomId)

  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.Link label='Classrooms' to='/educators/classrooms' />
        <Breadcrumbs.Link label={classroom.name} to={`/educators/classrooms/${classroomId}/courses`} />
        <Breadcrumbs.Text label={name} />

        <Link to={`/educators/courses/${courseId}/settings`} className='flex items-center rounded-full hover:bg-gray-200 p-1 ml-1'>
          <PencilSquareIcon className='size-5' />
        </Link>
      </Breadcrumbs>

      <TabNav>
        <TabNav.Link
          to={{ pathname: '', search: location.search }}
          end
          label='Submissions'
          icon={<PresentationChartLineIcon className='size-6 mr-1' />}
        />

        <If condition={insightsEnabled}>
          <TabNav.Link
            to={{ pathname: 'insights', search: location.search }}
            label='Insights'
            icon={<LightBulbIcon className='size-6 mr-1' />}
          />
        </If>

        <div className='flex items-center ml-auto mr-5'>
          <If condition={assignment}>
            <Choose>
              <When condition={assignment.closed}>
                <Button
                  type='button'
                  variant='outlined'
                  theme='light'
                  label={<span className='flex items-center gap-2'>Reopen<ArrowUturnLeftIcon className='size-6' /></span>}
                  className='py-1'
                  onClick={() => updateAssignment({ assignmentId: assignment.id, closed: false })}
                />

              </When>

              <Otherwise>
                <Button
                  type='button'
                  variant='outlined'
                  theme='error'
                  label={<span className='flex items-center gap-2'>Close<XMarkIcon className='size-6' /></span>}
                  className='py-1'
                  onClick={() => updateAssignment({ assignmentId: assignment.id, closed: true })}
                />
              </Otherwise>
            </Choose>
          </If>
        </div>
      </TabNav>


      <Routes>
        <Route
          index
          element={
            <SubmissionList
              id={courseId}
              classroomId={classroomId}
              educatorProjects={educatorProjects}
              totalPossibleScore={totalPossibleScore}
            />
          }
        />

        <If condition={insightsEnabled}>
          <Route
            path='insights'
            element={
              <Insights
                id={courseId}
                classroomId={classroomId}
                educatorProjects={educatorProjects}
              />
            }
          />
        </If>
      </Routes>
    </>
  )
}

export default CourseActivity
