import React from 'react'
import { twMerge } from 'tailwind-merge'

const TabNav = ({ className, children, theme }) => (
  <div className={twMerge('h-[50px] shrink-0 flex flex-row items-center border-b-gray-200 border-b bg-white', className)}>
    {React.Children.map(children, child => {
      if (!child) return null

      return React.cloneElement(child, { theme })
    })}
  </div>
)

export default TabNav
