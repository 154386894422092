import { useState } from 'react'
import { gql } from 'graphql-request'
import { useQuery, useMutation } from '@tanstack/react-query'

import { request } from '@helpers/graphql'
import Card from '@components/Card'
import LinkButton from '@components/LinkButton'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Pill from '@components/Pill'
import Toggle from '@components/Toggle'

import EditStudentProfileModal from './EditStudentProfileModal'

const STUDENT_PROFILES_QUERY = gql`
  query classroom($id: ID!, $showArchived: Boolean) {
    classroom(id: $id) {
      studentProfiles(showArchived: $showArchived) {
        id
        fullName
        firstName
        lastName
        email
        archived
      }
    }
  }
`

const UPDATE_STUDENT_PROFILE_MUTATION = gql`
  mutation updateStudentProfile($input: UpdateStudentProfileInput!) {
    updateStudentProfile(input: $input) {
      studentProfile {
        fullName
        firstName
        lastName
        email
        archived
      }
      errors {
        message
      }
    }
  }
`

const StudentProfilesList = ({ id }) => {
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [showArchived, setShowArchived] = useState(false)

  const { isLoading, refetch, data: { classroom: { studentProfiles = [] } = {} } = {} } = useQuery({
    queryKey: ['studentProfiles', id, showArchived],
    queryFn: async () => await request(STUDENT_PROFILES_QUERY, { id, showArchived })
  })

  const { mutate: updateStudentProfile, isLoading: isUpdating } = useMutation({
    mutationFn: async variables => request(UPDATE_STUDENT_PROFILE_MUTATION, { input: variables }),
    onSuccess: () => refetch()
  })

  return (
    <div className='w-full'>
      <Card className='p-5 sm:mx-5 my-5 w-auto'>
        <Toggle
          value={showArchived}
          onChange={() => setShowArchived(!showArchived)}
          label='Show archived students'
        />
      </Card>

      <Choose>
        <When condition={isLoading}>
          <Spinner className='relative top-0 left-0 flex items-center justify-center w-full h-screen' />
        </When>

        <Otherwise>
          <div className='mt-3 sm:mx-5 overflow-x-scroll p-0.5 md:mt-0'>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header width='40%'>Name</Table.Header>
                  <Table.Header width='40%'>Email</Table.Header>
                  <Table.Header width='10%' />
                  <Table.Header width='10%' />
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <If condition={studentProfiles.length < 1}>
                  <Table.Row>
                    <Table.Cell colSpan='4'>There aren't any students in this classroom yet, go ahead and invite them 😀</Table.Cell>
                  </Table.Row>
                </If>

                <For each='student' of={studentProfiles}>
                  <Table.Row key={student.id}>
                    <Table.Cell>
                      {student.fullName}

                      <If condition={student.archived}>
                        <Pill className='ml-3 text-sm' theme='light' label='Archived' />
                      </If>
                    </Table.Cell>
                    <Table.Cell>{student.email}</Table.Cell>
                    <Table.Cell>
                      <Choose>
                        <When condition={student.archived}>
                          <LinkButton onClick={() => updateStudentProfile({ studentProfileId: student.id, archived: false })}>
                            unarchive
                          </LinkButton>
                        </When>
                        <Otherwise>
                          <LinkButton onClick={() => updateStudentProfile({ studentProfileId: student.id, archived: true })}>
                            archive
                          </LinkButton>
                        </Otherwise>
                      </Choose>

                    </Table.Cell>
                    <Table.Cell>
                      <LinkButton onClick={() => setSelectedStudent(student)}>edit</LinkButton>
                    </Table.Cell>
                  </Table.Row>
                </For>
              </Table.Body>
            </Table>
          </div>

          <If condition={!!selectedStudent}>
            <EditStudentProfileModal
              id={selectedStudent.id}
              firstName={selectedStudent.firstName}
              lastName={selectedStudent.lastName}
              email={selectedStudent.email}
              onClose={() => setSelectedStudent(null)}
              isOpen={!!selectedStudent}
              refetch={refetch}
            />
          </If>

        </Otherwise>
      </Choose>
    </div>
  )
}

export default StudentProfilesList
