import { gql } from 'graphql-request'

import { useQuery, useQueryClient } from '@tanstack/react-query'

import { request } from '@helpers/graphql'

const ONBOARDING_CHECKLIST_QUERY = gql`
  query onboardingChecklist {
    me {
      profile {
        ... on EducatorProfile {
          onboardingChecklistCompleted
          onboardingChecklist {
            assignedTutorToClassroom
            chatWithTutor
            createdClassroom
            invitedStudents
            invitedEducators
          }
        }
      }
    }
  }
`

const useOnboardingChecklist = () => {
  const {
    isLoading,
    data: { me: { profile: { onboardingChecklistCompleted, onboardingChecklist = {} } = {} } = {} } = {}
  } = useQuery({
    queryKey: ['onboardingChecklist'],
    queryFn: async () => request(ONBOARDING_CHECKLIST_QUERY),
    staleTime: 86400000 // Only refetch once a day
  })

  const queryClient = useQueryClient()

  const markOnboardingItemCompleted = (itemKey) => {
    queryClient.setQueryData(['onboardingChecklist'], (old) => {
      const newChecklist = {
        ...old.me.profile.onboardingChecklist
      }

      newChecklist[itemKey] = true

      return {
        me: {
          profile: {
            ...old.me.profile,
            onboardingChecklist: newChecklist
          }
        }
      }
    })
  }

  const dismissOnboardingChecklist = () => {
    queryClient.setQueryData(['onboardingChecklist'], (_old) => ({
      me: {
        profile: {
          onboardingChecklistCompleted: true
        }
      }
    }))
  }

  return {
    isLoading,
    onboardingChecklistCompleted,
    onboardingChecklist,
    markOnboardingItemCompleted,
    dismissOnboardingChecklist
  }
}

export { useOnboardingChecklist }
