import { useQuery, useMutation } from '@tanstack/react-query'
import { useParams, useNavigate } from 'react-router-dom'
import { gql } from 'graphql-request'
import {
  ChatBubbleLeftIcon,
  CodeBracketIcon,
  PencilIcon,
  DocumentTextIcon,
  FolderPlusIcon,
  CheckIcon
} from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import Card from '@components/Card'
import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import ButtonLink from '@components/ButtonLink'
import SecureMarkdown from '@components/SecureMarkdown'

import SeriesCard from '../../components/SeriesCard'

const SERIES_QUERY = gql`
  query series($id: ID!) {
    node(id: $id) {
      ... on Series {
        id
        name
        description
        notes
        isOwner
        coverImage {
          url
        }
        educatorName
        organizationName
        educatorProjects {
          id
          name
          playground
          position
        }
      }
    }
  }
`

const IMPORT_SERIES_TO_LIBRARY_MUTATION = gql`
  mutation importSeriesToLibrary($input: ImportSeriesToLibraryInput!) {
    importSeriesToLibrary(input: $input) {
      series {
        id
      }
    }
  }
`

const SeriesPreview = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { isLoading, data: { node: series = {} } = {} } = useQuery({
    queryKey: ['seriesPreview', id],
    queryFn: async () => request(SERIES_QUERY, { id })
  })

  const { mutate: importSeriesToLibrary, isLoading: isImporting } = useMutation({
    mutationFn: async () => request(IMPORT_SERIES_TO_LIBRARY_MUTATION, { input: { seriesId: id } }),
    onSuccess: data => navigate(`/educators/courses/${data.importSeriesToLibrary.series.id}`)
  })

  if (isLoading) return

  return (
    <>
      <BreadCrumbs>
        <BreadCrumbs.Link label='Community' to='/educators/community' />
        <BreadCrumbs.Text label={series.name} />
      </BreadCrumbs>

      <div className='sm:mx-5 my-3'>
        <Card className='flex flex-row justify-end items-center p-3'>
          <Choose>
            <When condition={!series.isOwner}>
              <Button
                disabled={series.isOwner || isImporting}
                onClick={() => importSeriesToLibrary()}
                label={
                  <Choose>
                    <When condition={isImporting}>Importing...</When>
                    <Otherwise>
                      <span className='flex gap-2 items-center'>Import to my courses<FolderPlusIcon className='h-6 w-6' /></span>
                    </Otherwise>
                  </Choose>
                }
              />
            </When>

            <Otherwise>
              <ButtonLink
                label={<span className='flex gap-2 items-center'>In your library<CheckIcon className='h-6 w-6' /></span>}
                to={`/educators/courses/${series.id}`}
              />
            </Otherwise>
          </Choose>
        </Card>
      </div>

      <div className='sm:m-5'>
        <div className='flex flex-row gap-5 flex-wrap lg:flex-nowrap'>
          <div className='basis-full lg:basis-2/5 shrink-0'>
            <SeriesCard
              id={series.id}
              name={series.name}
              description={series.description}
              coverImageUrl={series.coverImage?.url}
              educatorName={series.educatorName}
              organizationName={series.organizationName}
              projectCount={series.educatorProjects?.length}
            />

            <If condition={series.notes}>
              <Card className='mt-5 p-5'>
                <h3 className='font-semibold text-xl'>Notes</h3>
                <SecureMarkdown className='text-sm' content={series.notes} />
              </Card>
            </If>
          </div>

          <div className='grow-0 basis-full'>
            <h3 className='text-xl font-semibold mb-3'>Projects</h3>

            <For index='index' each='project' of={series.educatorProjects}>
              <Card key={project.id} className='flex flex-row mb-3 p-3'>
                <Choose>
                  <When condition={project.playground === 'CHAT'}>
                    <ChatBubbleLeftIcon className='w-6 h-6 mr-3' />
                  </When>
                  <When condition={project.playground === 'CODE'}>
                    <CodeBracketIcon className='w-6 h-6 mr-3' />
                  </When>
                  <When condition={project.playground === 'COMPLETION'}>
                    <PencilIcon className='w-6 h-6 mr-3' />
                  </When>
                  <When condition={project.playground === 'DOC'}>
                    <DocumentTextIcon className='w-6 h-6 mr-3' />
                  </When>
                </Choose>

                <div className='w-full text-left'>
                  <h3 className='text-lg font-semibold'>{project.name}</h3>
                </div>
              </Card>
            </For>
          </div>
        </div>
      </div>
    </>
  )
}

export default SeriesPreview
