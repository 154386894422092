import React from 'react'
import { Menu } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'

const Item = ({ children, className, ...props }) => {
  return (
    <Menu.Item
      as="div"
      className={twMerge(
        className,
        'text-lg',
        'block px-4 py-2 text-sm ui-active:bg-gray-100 ui-active:text-gray-900 ui-not-active:text-gray-700'
      )}
      {...props}>
      {children}
    </Menu.Item>
  )
}

export default Item
