import { useEffect, useRef, useState } from 'react'
import { gql } from 'graphql-request'
import { useFormContext } from 'react-hook-form'
import {
  useMutation,
  useInfiniteQuery,
  useQueryClient,
  useQuery
} from '@tanstack/react-query'

import { useAnalytics } from '@contexts/analytics'
import { request } from '@helpers/graphql'
import { useChannel } from '@contexts/actionCable'
import Conversation from '@components/Conversation'

import UpgradeBanner from './UpgradeBanner'
import { useSubscription } from '../../../hooks/subscription'

const SEND_MESSAGE_MUTATION = gql`
  mutation sendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      messages(perPage: 10) {
        nodes {
          id
          status
          from
          text
          sourceName
          sourceLink
          createdAt
          attachments {
            url
            filename
          }
        }
      }
    }
  }
`

const MESSAGES_QUERY = gql`
  query chat($id: ID!, $page: Int!) {
    node(id: $id) {
      ... on Chat {
        messages(page: $page, perPage: 10) {
          pagesCount
          nodesCount
          nodes {
            id
            status
            from
            text
            sourceName
            sourceLink
            createdAt
            attachments {
              url
              filename
            }
          }
        }
      }
    }
  }
`

const SUGGESTIONS_QUERY = gql`
  query chat($id: ID!) {
    node(id: $id) {
      ... on Chat {
        suggestions {
          text
        }
      }
    }
  }
`

const TRANSCRIBE_AUDIO_MUTATION = gql`
  mutation transcribeAudio($input: TranscribeAudioInput!) {
    transcribeAudio(input: $input) {
      success
    }
  }
`

const UPLOAD_ATTACHMENTS_MUTATION = gql`
  mutation uploadAttachments($input: UploadAttachmentsInput!) {
    uploadAttachments(input: $input) {
      attachments {
        id
        url
        filename
      }
      errors {
        message
      }
    }
  }
`

const TEXT_TO_SPEECH_MUTATION = gql`
  mutation textToSpeech($input: TextToSpeechInput!) {
    textToSpeech(input: $input) {
      success
    }
  }
`

const ChatDetails = ({ chatId, name }) => {
  const { subscribe, unsubscribe } = useChannel()
  const queryClient = useQueryClient()
  const { track } = useAnalytics()
  const { setValue } = useFormContext()
  const { hasProFeatures } = useSubscription()

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status } =
    useInfiniteQuery({
      queryKey: ['messages', chatId],
      queryFn: async ({ pageParam = 1 }) =>
        request(MESSAGES_QUERY, { id: chatId, page: pageParam }),
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.node.messages.pagesCount > pages.length) {
          return pages.length + 1
        }

        return false
      },
      select: (data) => ({
        pages: [...data.pages].reverse(),
        pageParams: [...data.pageParams].reverse()
      })
    })

  const { mutate: sendMessage, isLoading: isSending } = useMutation({
    mutationFn: async (variables) => request(SEND_MESSAGE_MUTATION, variables),
    onSuccess: (newData) => {
      queryClient.setQueryData(['messages', chatId], (oldData) => {
        const oldNodes = oldData.pages[0].node.messages.nodes
        const newNodes = newData.sendMessage.messages.nodes
        const mergedNodes = [...newNodes]

        // Add old nodes that are not in the new nodes, exclusing temp
        oldNodes.forEach((node) => {
          const foundNode = mergedNodes.find((n) => n.id === node.id)

          if (!foundNode && !node.temp) {
            mergedNodes.push(node)
          }
        })

        return {
          ...oldData,
          pages: [
            {
              ...oldData.pages[0],
              node: {
                ...oldData.pages[0].node,
                messages: {
                  nodes: mergedNodes.sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                  )
                }
              }
            },
            ...oldData.pages.slice(1)
          ]
        }
      })
    }
  })

  const { data: { node: { suggestions = [] } = {} } = {} } = useQuery({
    queryKey: ['suggestions', chatId],
    queryFn: async () => request(SUGGESTIONS_QUERY, { id: chatId })
  })

  const { mutateAsync: uploadAttachments, isLoading: isUploading } = useMutation({
    mutationFn: async data => request(UPLOAD_ATTACHMENTS_MUTATION, { input: { chatId, ...data } })
  })

  const { mutateAsync: textToSpeechMutation } = useMutation({
    mutationFn: async (variables) => request(TEXT_TO_SPEECH_MUTATION, variables)
  })

  const audioPlayerRef = useRef(null)

  const handleUploadAttachments = async files => {
    const data = await uploadAttachments({ attachments: files })

    return data.uploadAttachments
  }

  const submitMessage = ({ text, attachmentIds }) => {
    sendMessage({ input: { chatId, text, attachmentIds } })
    setIsAwaitingResponse(true)

    queryClient.setQueryData(['messages', chatId], (oldData) => ({
      ...oldData,
      pages: [
        {
          ...oldData.pages[0],
          node: {
            ...oldData.pages[0].node,
            messages: {
              ...oldData.pages[0].node.messages,
              nodes: [
                {
                  text,
                  from: 'USER',
                  createdAt: new Date().toISOString(),
                  temp: true
                },
                ...oldData.pages[0].node.messages.nodes
              ]
            }
          }
        },
        ...oldData.pages.slice(1)
      ]
    }))

    queryClient.setQueryData(['suggestions', chatId], () => {
      return { node: { suggestions: [] } }
    })
  }

  const { mutate: transcribeAudio, isLoading: isTranscribing } = useMutation({
    mutationFn: async (variables) =>
      request(TRANSCRIBE_AUDIO_MUTATION, variables)
  })

  const submitAudio = (file) => {
    transcribeAudio({ input: { file, chatId } })
  }

  const idleToolStatus = { type: 'tool_status', tool: undefined, status: 'idle' }
  const [toolStatus, setToolStatus] = useState(idleToolStatus)
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)

  const [currentAudioMessageId, setCurrentAudioMessageId] = useState(null)

  const handlePlayPauseAudio = (messageId) => {
    setCurrentAudioMessageId(messageId)
  }

  useEffect(() => {
    subscribe(
      {
        channel: 'Tutor::ChatChannel',
        chat_id: chatId
      },
      {
        received: (data) => {
          if (data.type === 'message') {
            setIsAwaitingResponse(false)

            queryClient.setQueryData(['messages', chatId], (oldData) => {
              const oldNodes = oldData.pages[0].node.messages.nodes
              const newNodes = oldNodes.map((node) =>
                node.id === data.id ? { ...node, text: data.text, status: data.status } : node
              )

              if (!newNodes.find((n) => n.id === data.id)) {
                newNodes.unshift(data)
              }

              return {
                ...oldData,
                pages: [
                  {
                    ...oldData.pages[0],
                    node: {
                      ...oldData.pages[0].node,
                      messages: { nodes: newNodes }
                    }
                  },
                  ...oldData.pages.slice(1)
                ]
              }
            })
          }

          if (data.type === 'suggestions') {
            queryClient.setQueryData(['suggestions', chatId], () => {
              return {
                node: {
                  suggestions: data.suggestions.map((suggestion) => ({
                    text: suggestion
                  }))
                }
              }
            })
          }

          if (data.type === 'tool_status') {
            setIsAwaitingResponse(false)
            setToolStatus(data)
          }

          if (data.type === 'transcription') {
            setValue('text', data.text)
          }

          if (data.type === 'text_to_speech') {
            audioPlayerRef.current.src = data.audio_url
            audioPlayerRef.current.load()
            handlePlayPauseAudio(data.message_id)
          }
        }
      }
    )

    return () => {
      unsubscribe()
    }
  }, [chatId])

  return (
    <Conversation
      chatEnabled={hasProFeatures}
      ChatDisabledComponent={UpgradeBanner}
      transcribeAudio={submitAudio}
      isTranscribing={isTranscribing}
      sendMessage={submitMessage}
      uploadAttachments={handleUploadAttachments}
      isUploading={isUploading}
      isSending={isSending}
    >
      <Choose>
        <When condition={status === 'loading'}>
          <Conversation.LoadingState />
        </When>

        <Otherwise>
          <If condition={data?.pages?.[0]?.node?.messages?.pagesCount == 0}>
            <Conversation.EmptyState name={name} />
          </If>

          <Conversation.LoadMore
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
          />

          {/* Audio player for text-to-speech */}
          <audio ref={audioPlayerRef} />

          <For each='page' of={data.pages} index='index'>
            <div key={`page-${index}`} className='flex flex-col-reverse'>
              <For each='message' of={page.node.messages.nodes}>
                <Conversation.Message
                  key={message.id}
                  id={message.id}
                  status={message.status}
                  text={message.text}
                  from={message.from}
                  sourceName={message.sourceName}
                  sourceLink={message.sourceLink}
                  createdAt={message.createdAt}
                  attachments={message.attachments}
                  audioPlayerRef={audioPlayerRef}
                  currentAudioMessageId={currentAudioMessageId}
                  textToSpeechMutation={textToSpeechMutation}
                  trackAudioPlayed={() =>
                    track('Tutor Chat Audio Played', {
                      chatId,
                      text: message.text
                    })}
                />
              </For>
            </div>
          </For>

          <If condition={!['idle', 'streaming'].includes(toolStatus.status)}>
            <Conversation.ToolLoadingState tool={toolStatus.tool} status={toolStatus.status} />
          </If>

          <If condition={isAwaitingResponse}>
            <Conversation.MessageLoadingState />
          </If>

          <Conversation.SuggestionList>
            <For each='suggestion' of={suggestions}>
              <Conversation.Suggestion
                key={suggestion.text}
                text={suggestion.text}
                onClick={() => {
                  submitMessage({ text: suggestion.text })

                  track('Tutor Chat Suggestion Accepted', {
                    chatId,
                    text: suggestion.text
                  })
                }}
              />
            </For>
          </Conversation.SuggestionList>
        </Otherwise>
      </Choose>
    </Conversation>
  )
}

export default ChatDetails
