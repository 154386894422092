import Button from '@components/Button'

const NoBlockSelected = ({ addBlock }) => (
  <div className='flex flex-col items-center justify-center p-10 h-full'>
    <img src='/blocks.svg' alt='Searching' className='w-[200px] self-center mb-5' />
    <h4 className='text-2xl font-semibold'>Add a block to get started</h4>
    <Button className='mt-5' label='Add block' onClick={addBlock} />
  </div>
)

export default NoBlockSelected
