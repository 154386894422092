import { forwardRef, useState } from 'react'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'

import Label from './Label'

const PasswordInput = forwardRef(({ label, onChange, required, className, ...props }, ref) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <Label className={className} title={label} required={required}>
      <div className='relative'>
        <input
          className='block font-normal w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-base sm:leading-6'
          type={showPassword ? 'text' : 'password'}
          onChange={onChange}
          ref={ref}
          {...props}
        />
        <button
          aria-label={showPassword ? 'Hide password' : 'Show password'}
          type='button'
          className='absolute p-0.5 top-1/2 rounded-lg transform -translate-y-1/2 right-3 hover:bg-gray-200'
          onClick={() => setShowPassword(!showPassword)}
        >
          <Choose>
            <When condition={!showPassword}>
              <EyeIcon
                className='h-6 w-6 text-gray-500'
              />
            </When>

            <Otherwise>
              <EyeSlashIcon
                className='h-6 w-6 text-gray-500'
              />
            </Otherwise>
          </Choose>
        </button>
      </div>
    </Label>
  )
})

export default PasswordInput
