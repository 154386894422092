import { gql } from 'graphql-request'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import TextArea from '@components/TextArea'
import { useFlashMessage } from '@components/FlashMessage'

const INVITE_STUDENT_TO_CLASSROOM_MUTATION = gql`
  mutation inviteStudentsToClassroom($inviteStudentsToClassroomInput: InviteStudentsToClassroomInput!) {
    inviteStudentsToClassroom(input: $inviteStudentsToClassroomInput) {
      success
      errors
    }
  }
`

const InviteStudentsForm = ({ classroomId, closeModal }) => {
  const { setFlashMessage } = useFlashMessage()
  const { register, handleSubmit, reset, setError, formState: { errors } } = useForm({ mode: 'onTouched' })
  const { mutateAsync: inviteStudents, isLoading: sending } = useMutation(
    ['inviteStudents'], async variables => request(INVITE_STUDENT_TO_CLASSROOM_MUTATION, variables),
    {
      onSettled: response => {
        const data = response?.inviteStudentsToClassroom
        if (data.success === true) {
          setFlashMessage('Invitations sent', 'success')
          closeModal()
          reset()
        } else {
          const message = data.errors[0] || 'Something went wrong'
          setError('emailAddresses', { message })
        }
      }
    }
  )

  const submit = data => {
    inviteStudents({ inviteStudentsToClassroomInput: { ...data, classroomId } })
  }

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="flex flex-col space-y-4 mt-5">
      <p>
        Make sure to use <span className="font-semibold">school-managed email addresses.</span> If students are already signed up, they will be added to your classroom immediately.
      </p>

      <div>
        <TextArea
          id="email-addresses"
          label="Student email addresses"
          className="h-20"
          autoFocus
          required
          {...register('emailAddresses', { required: "Email addresses are required", pattern: { value: /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,}(\s*,?\s*)*)+$/i, message: "Email addresses must be valid" } })}
        />
        <If condition={errors.emailAddresses}>
          <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.emailAddresses.message}</p>
        </If>

        <p className="text-sm mt-1 flex flex-row items-center">
          Invite multiple students by listing email addresses separated by a comma, or by pasting a CSV file.
        </p>
      </div>

      <Button
        disabled={sending}
        className="w-fit ml-auto"
        type="submit"
        label={sending ? "Sending..." : "Send invitations"}
      />
    </form>
  )
}

export default InviteStudentsForm
