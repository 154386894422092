import { useState } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { Link } from 'react-router-dom'
import {
  BellAlertIcon,
  EllipsisHorizontalIcon,
  CheckIcon,
  ChatBubbleLeftRightIcon,
  ArrowUturnLeftIcon
} from '@heroicons/react/24/outline'
import { twMerge } from 'tailwind-merge'

import { request } from '@helpers/graphql'
import Table from '@components/Table'
import Spinner from '@components/Spinner'
import Tooltip from '@components/Tooltip'
import Checkbox from '@components/Checkbox'
import CircleSpinner from '@components/CircleSpinner'

import NoResults from './NoResults'
import QuestionModal from './QuestionModal'
import MessageHistoryModal from './MessageHistoryModal'

const EDUCATOR_PROJECT_SUBMISSIONS_QUERY = gql`
  query educatorProjectSubmissions($classroomId: ID!, $educatorProjectId: ID!) {
    educatorProjectSubmissions(classroomId: $classroomId, educatorProjectId: $educatorProjectId) {
      id
      status
      flaggedByModeration
      score
      profileable {
        ... on StudentProfile {
          id
          fullName
        }
      }
      blockSubmissions {
        id
        status
        blockId
        content {
          ... on FreeResponseSubmission {
            __typename
            answer
            marks
          }
          ... on QuestionSubmission {
            __typename
            answer {
              label
            }
            correct
            marks
          }
        }
      }
    }
    educatorProject: node(id: $educatorProjectId) {
      ... on EducatorProject {
        id
        name
        totalPossibleScore
        blocks {
          __typename
          blockId
          ... on Reflection {
            __typename
            id
            question
          }
          ... on Question {
            __typename
            id
            label
            markAllocation
            options {
              id
              label
              correct
            }
          }
          ... on FreeResponse {
            __typename
            id
            question
            markAllocation
            markScheme
            workingsRequired
          }
        }
      }
    }
  }
`

const ORGANIZATION_QUERY = gql`
  query organization {
    organization {
      privateStudentChatsEnabled
    }
  }
`

const RETURN_SUBMISSIONS_MUTATION = gql`
  mutation returnEducatorProjectSubmissions($input: ReturnEducatorProjectSubmissionsInput!) {
    returnEducatorProjectSubmissions(input: $input) {
      success
      errors {
        message
      }
    }
  }
`

const formatStatus = status => {
  switch (status) {
    case 'IN_PROGRESS':
      return 'In progress'
    case 'COMPLETED':
      return 'Completed'
    case 'RETURNED':
      return 'Returned'
  }
}

const statusTheme = status => {
  switch (status) {
    case 'COMPLETED':
      return 'bg-green-50 border-green-600 text-green-600'
    case 'RETURNED':
      return 'bg-purple-50 border-purple-600 text-purple-600'
    default:
      return 'bg-gray-100 border-gray-300 text-gray-900'
  }
}

const LessonSubmissionList = ({ classroomId, educatorProjectId }) => {
  const [selectedSubmissionId, setSelectedSubmissionId] = useState(null)
  const [selectedChatId, setSelectedChatId] = useState(null)
  const [selectedSubmissionIds, setSelectedSubmissionIds] = useState([])

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['educatorProjectSubmissions', classroomId, educatorProjectId],
    queryFn: async () => request(EDUCATOR_PROJECT_SUBMISSIONS_QUERY, { classroomId, educatorProjectId }),
    enabled: !!classroomId && !!educatorProjectId
  })

  const { data: { organization = {} } = {} } = useQuery({
    queryKey: ['organizationPrivateChatsEnabled'],
    queryFn: async () => request(ORGANIZATION_QUERY)
  })

  const { educatorProjectSubmissions = [], educatorProject = {} } = data || {}
  const { blocks = [], totalPossibleScore = 0 } = educatorProject

  const { mutate: returnSubmissions, isLoading: isReturning } = useMutation({
    mutationFn: async submissionIds => await request(RETURN_SUBMISSIONS_MUTATION, { input: { submissionIds } }),
    onSuccess: () => {
      setSelectedSubmissionIds([])
      refetch()
    }
  })

  const toggleAll = () => {
    if (selectedSubmissionIds.length === educatorProjectSubmissions.length) {
      setSelectedSubmissionIds([])
    } else {
      const submissionIds = educatorProjectSubmissions.filter(s => s.status === 'COMPLETED').map(s => s.id)
      setSelectedSubmissionIds(submissionIds)
    }
  }

  return (
    <div className='mt-5'>
      <Choose>
        <When condition={isLoading}>
          <Spinner className='flex items-center justify-center w-full mt-24' />
        </When>

        <When condition={educatorProjectSubmissions.length < 1}>
          <NoResults classroomSelected />
        </When>

        <Otherwise>
          <button
            onClick={() => returnSubmissions(selectedSubmissionIds)}
            disabled={selectedSubmissionIds.length < 1 || isReturning}
            type='button'
            className='inline-flex items-center justify-center rounded bg-white px-2 py-1 mb-3 text-sm font-semibold text-purple-600 shadow-sm ring-1 ring-inset ring-purple-300 hover:bg-purple-50 disabled:cursor-not-allowed disabled:opacity-40 disabled:hover:bg-white'
          >
            <Choose>
              <When condition={isReturning}>
                <CircleSpinner className='mr-2' />
                Returning...
              </When>

              <Otherwise>
                <ArrowUturnLeftIcon className='size-4 mr-2' />
                Return submissions&nbsp;
                <If condition={selectedSubmissionIds.length > 0}>
                  <span>({selectedSubmissionIds.length})</span>
                </If>
              </Otherwise>
            </Choose>
          </button>

          {/**
           * Complex height calculation for table scrolling:
           * On desktop: height = full height - breadcrumbs - tabs - toolbar - return button - padding
           * On mobile: height = full height - navbar - breadcrumbs - tabs - toolbar - return button - padding
           **/}
          <div className='lg:max-h-[calc(100vh-84px-50px-45px-28px-2.75rem)] max-h-[calc(100vh-57px-84px-50px-45px-28px-3.5rem)] flex overflow-auto whitespace-nowrap w-full rounded-lg border border-gray-200 bg-white'>
            <Table className='table-auto w-full'>
              <Table.Head>
                <Table.Row>
                  <Table.Header className='text-base align-middle w-48 sticky left-0 top-0 bg-gray-50 z-10'>
                    <div className='relative flex items-center'>
                      <Checkbox
                        checked={selectedSubmissionIds.length === educatorProjectSubmissions.length}
                        onChange={toggleAll}
                      />
                      Students
                    </div>
                    <div className='absolute top-0 right-0 bottom-0 w-px bg-gray-200 z-20' />
                  </Table.Header>
                  <Table.Header className='text-base align-middle sticky top-0 bg-gray-50'>
                    Status
                  </Table.Header>
                  <Table.Header className='text-base align-middle w-32 sticky top-0 bg-gray-50'>Total</Table.Header>
                  <For each='block' of={blocks} index='index'>
                    <Table.Header key={block.id} className='text-base align-middle text-center sticky top-0 bg-gray-50 w-32'>
                      {index + 1}
                    </Table.Header>
                  </For>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <For each='submission' of={educatorProjectSubmissions}>
                  <Table.Row key={submission.id}>
                    <Table.Cell className='sticky left-0 bg-white w-48'>
                      <div className='relative flex items-center'>
                        <Checkbox
                          disabled={submission.status === 'RETURNED'}
                          checked={selectedSubmissionIds.includes(submission.id)}
                          onChange={event => {
                            if (event.target.checked) {
                              setSelectedSubmissionIds(prev => [...prev, submission.id])
                            } else {
                              setSelectedSubmissionIds(prev => prev.filter(id => id !== submission.id))
                            }
                          }}
                        />
                        {submission.profileable.fullName}

                        <If condition={!organization.privateStudentChatsEnabled}>
                          <button
                            onClick={() => setSelectedChatId(submission.id)}
                            className='ml-auto bg-gray-100 rounded-full p-1 hover:bg-gray-200'
                          >
                            <ChatBubbleLeftRightIcon className='size-5' />
                          </button>
                        </If>

                        <If condition={submission.flaggedByModeration}>
                          <span id={`flagged-${submission.id}`} className='group ml-2 inline-flex items-center bg-red-100 text-red-900 p-1 rounded-full'>
                            <BellAlertIcon className='w-5 h-5' />
                          </span>

                          <Tooltip anchorSelect={`#flagged-${submission.id}`} delayShow={200}>
                            Flagged by moderation system
                          </Tooltip>
                        </If>
                      </div>

                      <div className='absolute top-0 right-0 bottom-0 w-px bg-gray-200 z-20' />
                    </Table.Cell>
                    <Table.Cell>
                      <span
                        className={twMerge(
                          'rounded-full px-3 py-1',
                          statusTheme(submission.status)
                        )}
                      >
                        {formatStatus(submission.status)}
                      </span>
                    </Table.Cell>
                    <Table.Cell className='w-32'>
                      <div className='bg-gray-100 rounded-md px-2 py-1 w-fit border border-gray-300'>
                        {submission.score} / {totalPossibleScore}
                      </div>
                    </Table.Cell>

                    <For each='block' of={blocks}>
                      <With blockSubmission={submission.blockSubmissions.find(b => b.blockId === block.blockId)}>
                        <Choose>
                          <When condition={blockSubmission?.status === 'IN_PROGRESS'}>
                            <Table.Cell className='whitespace-nowrap'>
                              <Link to={`/educators/submissions/${submission.id}`}>
                                <EllipsisHorizontalIcon className='w-5 h-5 mx-auto' />
                              </Link>
                            </Table.Cell>
                          </When>

                          <When condition={blockSubmission?.status === 'COMPLETED'}>
                            <Table.Cell className='w-32'>
                              <div className='w-full flex justify-center'>
                                <Choose>
                                  <When condition={block.__typename === 'Question' || block.__typename === 'FreeResponse'}>
                                    <With percentage={(blockSubmission.content.marks / block.markAllocation) * 100}>
                                      <button
                                        onClick={() => setSelectedSubmissionId(blockSubmission.id)}
                                        className={twMerge(
                                          'relative flex items-center justify-center rounded-md px-2 py-1',
                                          percentage === 100
                                            ? 'bg-green-100 border border-green-600'
                                            : percentage >= 50
                                              ? 'bg-yellow-100 border border-yellow-600'
                                              : 'bg-red-100 border border-red-600'
                                        )}
                                      >
                                        {blockSubmission.content.marks}
                                        <span className='mx-1'>/</span>
                                        {block.markAllocation}
                                      </button>
                                    </With>
                                  </When>
                                  <Otherwise>
                                    <div className='bg-gray-100 rounded-md px-2 py-1 w-fit border border-gray-300'>
                                      <CheckIcon className='w-5 h-5 mx-auto' />
                                    </div>
                                  </Otherwise>
                                </Choose>
                              </div>
                            </Table.Cell>
                          </When>

                          <Otherwise>
                            <Table.Cell />
                          </Otherwise>
                        </Choose>
                      </With>
                    </For>
                  </Table.Row>
                </For>
              </Table.Body>
            </Table>
          </div>
        </Otherwise>
      </Choose>

      <If condition={!!selectedSubmissionId}>
        <QuestionModal
          refetch={refetch}
          closeModal={() => setSelectedSubmissionId(null)}
          submissionId={selectedSubmissionId}
        />
      </If>

      <If condition={!!selectedChatId}>
        <MessageHistoryModal
          id={selectedChatId}
          closeModal={() => setSelectedChatId(null)}
        />
      </If>
    </div>
  )
}

export default LessonSubmissionList
