import PageHeading from '@components/PageHeading'

import { creatorData, TutorCard } from '../CreatorDetails'

const CreatorList = () => {
  const creators = Object.values(creatorData)

  return (
    <>
      <PageHeading title='Creators' />

      <h3 className='p-5 text-2xl font-bold text-purple-600'>Learn from experts around the world</h3>

      <div className='px-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mb-5'>
        <For each='creator' of={creators}>
          <With tutor={creator.tutors[0]}>
            <TutorCard
              key={creator.name}
              title={creator.name}
              description={
                <>
                  {creator.organizationName}

                  <div className='flex flex-wrap my-3 gap-2'>
                    <For each='tag' of={creator.tags}>
                      <span key={tag} className='text-gray-900 inline-block w-fit shrink-0 text-normal font-semibold bg-gray-200 px-3 py-1 rounded-full'>{tag}</span>
                    </For>
                  </div>
                </>
              }
              imageUrl={creator.profileImageUrl}
              callToAction='View profile'
              url={`/educators/creators/${creator.slug}`}
            />
          </With>
        </For>
      </div>
    </>
  )
}

export default CreatorList
