import Card from '@components/Card'

const NoResults = ({ clearFilters }) => (
  <Card className='flex flex-col items-center justify-center p-10'>
    <img src='/searching.svg' alt='Searching' className='w-[100px] self-center mb-5' />
    <h4 className='text-2xl font-semibold'>No results found</h4>
    <p>
      Try adjusting your search, or&nbsp;
      <button className='inline text-blue-500' onClick={clearFilters}>
        clear your search
      </button>.
    </p>
  </Card>
)

export default NoResults
