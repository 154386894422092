import { NavLink } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

const navClassName = ({ isActive }) => {
  return twMerge(
    'group flex gap-x-3 rounded-md p-2 text-lg leading-6',
    isActive ? 'bg-gray-50 text-blue-600' : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50'
  )
}
const navIconClassName = ({ isActive }) => {
  return twMerge(
    'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
    isActive ? 'text-blue-600' : 'text-gray-700 group-hover:text-gray-500 group-hover:text-blue-600'
  )
}

const NavItem = ({ item, closeSidebar }) => (
  <Choose>
    <When condition={item.onClick}>
      <button
        onClick={() => {
          item.onClick()
          closeSidebar()
        }}
        className='group w-full flex gap-x-3 rounded-md p-2 text-lg leading-6 text-gray-700 hover:text-blue-600 hover:bg-gray-50'
      >
        <item.icon
          className='flex-shrink-0 -ml-1 mr-3 h-6 w-6 text-gray-700 group-hover:text-gray-500'
          aria-hidden='true'
        />
        <span className="whitespace-nowrap">{item.name}</span>
      </button>
    </When>

    <When condition={item.external}>
      <a
        onClick={closeSidebar}
        href={item.href}
        target='_blank'
        className='group flex gap-x-3 rounded-md p-2 text-lg leading-6 text-gray-700 hover:text-blue-600 hover:bg-gray-50' rel='noreferrer'
      >
        <>
          <item.icon
            className={navIconClassName({ isActive: false })}
            aria-hidden='true'
          />
          <span className="whitespace-nowrap">{item.name}</span>
        </>
      </a>
    </When>

    <Otherwise>
      <NavLink
        onClick={closeSidebar}
        to={item.href}
        className={navClassName}
      >
        {({ isActive }) => (
          <>
            <item.icon
              className={navIconClassName({ isActive })}
              aria-hidden='true'
            />
            <span className="whitespace-nowrap">{item.name}</span>
          </>
        )}
      </NavLink>
    </Otherwise>
  </Choose>
)

export default NavItem
