import Button from '@components/Button'

const EmptyChat = ({ createChat, name }) => (
  <div className="flex flex-col justify-center items-center w-full h-full border-l border-r-gray-200">
    <p className="text-2xl font-semibold mb-3">No chats yet</p>
    <p className="text-gray-600 mb-3 text-center">Start a new chat with {name} by clicking the button below 👇</p>
    <Button theme="secondary" className="mt-3" onClick={() => createChat()} label="Start chat" />
  </div>
)

export default EmptyChat
