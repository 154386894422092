const Skeleton = () => (
  <div className='h-full flex w-full bg-gray-50'>
    <div className='flex gap-1 px-5 pt-3 h-full w-full sm:w-1/3 bg-gray-50  '>
      <div className='w-full h-fit rounded-2xl border-gray-300 border p-1'>
        <div className='bg-gray-200 rounded-md w-full h-2 block' />
      </div>

      <For each='block' index='index' of={[0, 1, 2, 3, 4]}>
        <div className='w-full h-fit rounded-2xl flex-grow h3 p-1 border-white'>
          <div className='bg-gray-200 rounded-md w-full h-2 block' />
        </div>
      </For>
    </div>
  </div>
)

export default Skeleton
