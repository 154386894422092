import { twMerge } from 'tailwind-merge'

const PageHeading = ({ title, children, className }) => (
  <div className={twMerge("flex flex-row items-center justify-between px-5 border-b-gray-200 border-b bg-white h-[60px] min-h-[60px]", className)} >
    <h1 className="font-heading text-2xl font-bold my-2">{title}</h1>

    {children}
  </div >
)

export default PageHeading
