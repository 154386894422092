import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { Navigate, useSearchParams, Link } from 'react-router-dom'
import { setUser as setSentryUser } from '@sentry/react'
import { ArrowLongRightIcon } from '@heroicons/react/20/solid'

import { useCurrentUser } from '@contexts/currentUser'
import { useAnalytics } from '@contexts/analytics'
import { client } from '@helpers/api'
import Button from '@components/Button'
import Card from '@components/Card'
import TextInput from '@components/TextInput'
import PasswordInput from '@components/PasswordInput'

const SignIn = () => {
  const [searchParams] = useSearchParams()
  const { identify } = useAnalytics()
  const { register, handleSubmit, setError, formState: { errors } } = useForm({ mode: 'onTouched' })
  const { user: { signedIn, role }, setUser } = useCurrentUser()

  const { mutate: signIn, isLoading } = useMutation({
    mutationFn: async variables => await client.post('/users/sign_in', variables),
    onSuccess: response => {
      setUser({ loading: false, signedIn: true, ...response.data })
      setSentryUser({ id: response.data.id, email: response.data.email, role: response.data.role })
      identify(response.data.id)
    },
    onError: error => {
      const message = error?.response?.data?.error

      if (message === 'Invalid Email or password.') {
        setError('email', { message: 'Invalid email or password' })
      }
    }
  })

  const submit = data => {
    signIn({ user: data })
  }

  if (signedIn) {
    const redirectPath = searchParams.get('redirect_to')

    if (redirectPath) {
      return <Navigate to={redirectPath} />
    } else if (role === 'student') {
      return <Navigate to='/students' />
    } else if (role === 'admin') {
      return <Navigate to='/admin' />
    }

    return <Navigate to='/educators' />
  }

  const cookies = document.cookie.split('; ')
  const csrfToken = cookies?.find(cookie => cookie.startsWith('CSRF-TOKEN='))?.split('=')[1]

  return (
    <>
      <Card className='p-5 sm:w-[400px] md:mt-10 mt-5 self-center'>
        <h1 className='text-2xl font-bold'> Sign in</h1>

        <form method='post' action={`${import.meta.env.VITE_API_URL}/users/auth/google_app?intent=sign_in`}>
          <input type='hidden' name='authenticity_token' value={csrfToken} />

          <button
            type='submit'
            className='w-full mt-5 flex items-center rounded-md px-3.5 py-2.5 text-base shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-gray-600'
          >
            <img
              className='w-5 mr-5'
              src='google-logo.svg'
            />

            <span>Sign in with Google</span>

            <ArrowLongRightIcon className='ml-auto w-6' />
          </button>
        </form>

        <form method='post' action={`${import.meta.env.VITE_API_URL}/users/auth/microsoft_app?intent=sign_in`}>
          <input type='hidden' name='authenticity_token' value={csrfToken} />

          <button
            type='submit'
            className='w-full mt-5 flex items-center rounded-md px-3.5 py-2.5 text-base shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-gray-600'
          >
            <img
              className='w-5 mr-5'
              src='microsoft-logo.svg'
            />

            <span>Sign in with Microsoft</span>

            <ArrowLongRightIcon className='ml-auto w-6' />
          </button>
        </form>

        <div className='flex items-center my-3'>
          <div className='border-b-2 w-full' />
          <span className='p-3'>Or</span>
          <div className='border-b-2 w-full' />
        </div>

        <form className='flex flex-col space-y-4' onSubmit={handleSubmit(submit)}>
          <div>
            <TextInput
              id='email'
              label='Email'
              required
              {...register('email', { required: 'Email is required' })}
            />
            <If condition={errors.email}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.email.message}</p>
            </If>
          </div>

          <div>
            <PasswordInput
              id='password'
              label='Password'
              required
              {...register('password', { required: 'Password is required' })}
            />
            <If condition={errors.password}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.password.message}</p>
            </If>
          </div>

          <If condition={errors.general}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.general.message}</p>
          </If>

          <Button
            type='submit'
            disabled={isLoading}
            label={isLoading ? 'Signing in...' : 'Sign in'}
          />
        </form>

        <Link to='/request_password_reset' className='mt-3 block text-blue-500'>Forgot password?</Link>
      </Card>

      <p className='flex self-center mt-5'>
        New to Mindjoy?&nbsp;<Link to='/sign_up' className='text-blue-500'>Sign up</Link>
      </p>
    </>
  )
}

export default SignIn
