import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { gql } from 'graphql-request'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import PageHeading from '@components/PageHeading'
import Notification from '@components/Notification'

const LESSON_PLANS_QUERY = gql`
  query lessonPlans {
    lessonPlans {
      id
      title
      topic
      step
      subject
      grade
    }
  }
`

const LessonPlanList = () => {
  const { isLoading, data: { lessonPlans = [] } = {} } = useQuery(['lessonPlans'], async () => request(LESSON_PLANS_QUERY))

  return (
    <>
      <PageHeading title="Lesson plans" />

      <Notification className="w-100 m-5 font-normal flex items-center" theme="primary">
        <InformationCircleIcon className="w-5 h-5 mr-2 text-blue-800" />
        <strong>
          The lesson planning tool will be retired in the near future.
        </strong>

        &nbsp;We recommend using AI tutors to create lesson plans instead,&nbsp;<a className="font-semibold text-blue-500 underline" href="mailto:support@mindjoy.com">let us know</a>&nbsp;if you need help with the switch.
      </Notification>

      <Choose>
        <When condition={isLoading}>
          <Spinner className="relative top-0 left-0 flex items-center justify-center w-full h-screen" />
        </When>

        <Otherwise>
          <div className="md:px-5 py-3 flex flex-col">

            <div className="overflow-x-scroll p-0.5">
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.Header>Title</Table.Header>
                    <Table.Header>Subject</Table.Header>
                    <Table.Header>Grade</Table.Header>
                    <Table.Header></Table.Header>
                  </Table.Row>
                </Table.Head>

                <Table.Body>
                  <If condition={lessonPlans.length < 1}>
                    <Table.Row>
                      <Table.Cell colSpan="2">You don't have any lesson plans yet, go ahead and create one 😀</Table.Cell>
                    </Table.Row>
                  </If>

                  <For each="lessonPlan" of={lessonPlans}>
                    <Table.Row key={lessonPlan.id}>
                      <Table.Cell>
                        <Link className="block" to={lessonPlan.id}>
                          <Choose>
                            <When condition={lessonPlan.title}>
                              {lessonPlan.title}
                            </When>
                            <Otherwise>
                              Untitled lesson plan
                            </Otherwise>
                          </Choose>
                        </Link>
                      </Table.Cell>
                      <Table.Cell><Link className="block" to={lessonPlan.id}>{lessonPlan.subject}</Link></Table.Cell>
                      <Table.Cell>
                        <Link className="block" to={lessonPlan.id}>
                          <If condition={lessonPlan.grade}>Grade {lessonPlan.grade}</If>
                        </Link>
                      </Table.Cell>
                      <Table.Cell><Link className="block text-blue-500" to={lessonPlan.id}>edit</Link></Table.Cell>
                    </Table.Row>
                  </For>
                </Table.Body>
              </Table>
            </div>
          </div>
        </Otherwise>
      </Choose>
    </>
  )
}

export default LessonPlanList
