import BreadCrumbs from '@components/Breadcrumbs'
import PageHeading from '@components/PageHeading'
import Spinner from '@components/Spinner'

const Skeleton = () => (
  <div className="h-full w-full bg-white flex flex-col justify-between">
    <BreadCrumbs>
      <BreadCrumbs.Link label="Tutors" to="/students/tutors" />
      <div className="w-20 h-4 bg-gray-200" />
    </BreadCrumbs>
  </div>
)

export default Skeleton
