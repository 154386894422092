import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'
import OverflowMenu from '@components/OverflowMenu'

const UPDATE_AUTHORIZATION_ROLE_MUTATION = gql`
  mutation updateEducatorProfileAuthorizationRole($updateAuthorizationRoleInput: UpdateAuthorizationRoleInput!) {
    updateEducatorProfileAuthorizationRole(input: $updateAuthorizationRoleInput) {
      educatorProfile {
        authorizationRole
      }
      errors {
        message
      }
    }
  }
`

const EducatorListActions = ({ educator, refetch }) => {
  const educatorNameOrEmail = educator.fullName.trim() === '' ? educator.email : educator.fullName

  const { mutate: updateEducatorRole } = useMutation({
    mutationFn: async (variables) => request(UPDATE_AUTHORIZATION_ROLE_MUTATION, variables),
    onSuccess: () => {
      refetch()
    }
  })

  const handleRoleChange = (id, newRole) => {
    updateEducatorRole({
      updateAuthorizationRoleInput: {
        educatorProfileId: id,
        authorizationRole: newRole
      }
    })
  }

  return (
    <OverflowMenu orientation='horizontal'>
      <Choose>
        <When condition={educator.authorizationRole === 'ADMIN'}>
          <OverflowMenu.Item>
            <button
              onClick={() => handleRoleChange(educator.id, 'TEACHER')}
              className='text-md w-full text-left'
            >
              Make {educatorNameOrEmail} a teacher
            </button>
          </OverflowMenu.Item>
        </When>

        <Otherwise>
          <OverflowMenu.Item>
            <button
              onClick={() => handleRoleChange(educator.id, 'ADMIN')}
              className='text-md w-full text-left'
            >
              Make {educatorNameOrEmail} an admin
            </button>
          </OverflowMenu.Item>
        </Otherwise>
      </Choose>
    </OverflowMenu>
  )
}

export default EducatorListActions
