import { CogIcon } from '@heroicons/react/20/solid'

const ToolLoadingState = ({ tool, status }) => {
  let statusCopy

  const toolsStatusMap = {
    assistant: {
      processing: 'processing your request...'
    },
    mathematics_tool: {
      processing: 'calculating results...'
    },
    science_and_technology_tool: {
      processing: 'calculating results...'
    },
    society_and_culture_tool: {
      processing: 'searching...'
    }
  }

  if (tool && status) {
    statusCopy = toolsStatusMap[tool][status]
  }

  return (
    <>
      <Choose>
        <When condition={status !== undefined}>
          <div className='flex items-center mb-3'>
            <div className='relative mr-1'>
              <svg className='progress-ring' width='24' height='24'>
                {/* <!-- Background Circle --> */}
                <circle className='progress-ring__circle-background stroke-gray-400' strokeWidth='2' fill='transparent' r='10' cx='12' cy='12' />
                {/* <!-- Progress Circle --> */}
                <circle className='progress-ring__circle stroke-purple-600 animate-[progress-animation_2s_ease-out_forwards]' strokeWidth='2' fill='transparent' r='10' cx='12' cy='12' />
              </svg>

              <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                <CogIcon className='w-3 h-3 text-purple-600 animate-spin' />
              </div>
            </div>
            <span className='animate-pulse'>
              {statusCopy || 'processing...'}
            </span>
          </div>
        </When>
      </Choose>
    </>
  )
}

export default ToolLoadingState
