import { useNavigate } from 'react-router-dom'

import { useSubscription } from '../hooks/subscription'
import Button from '@components/Button'

const UpgradeBanner = () => {
  const navigate = useNavigate()
  const { isLoading, isWithinTrialPeriod, daysLeftInTrial, hasActiveSubscription } = useSubscription()

  if (isLoading || hasActiveSubscription) return null

  return (
    <div className='bg-purple-100 rounded-lg p-3 text-purple-700 animate-[appear_0.3s_ease-in]'>
      <p className='leading-tight text-sm min-w-[160px]'>
        <Choose>
          <When condition={isWithinTrialPeriod}>
            You have <strong>{daysLeftInTrial} {daysLeftInTrial === 1 ? 'day' : 'days'}</strong> remaining in your free trial.
          </When>

          <Otherwise>
            <strong>Upgrade your plan</strong> to continue using Mindjoy
          </Otherwise>
        </Choose>
      </p>

      <Button
        onClick={() => navigate('/educators/upgrade')}
        label={
          <div className='flex justify-center items-center'>
            Upgrade
            <img className='ml-3 w-5 h-5' src='/crown.svg' alt='crown' />
          </div>
        }
        theme='secondary'
        className='w-full mt-3'
      />
    </div>
  )
}

export default UpgradeBanner
