import { InformationCircleIcon } from '@heroicons/react/24/outline'

import LinkButton from '@components/LinkButton'
import Notification from '@components/Notification'

const NoTutorSelectedNotification = ({ openModal, lessonName }) => (
  <Notification className='flex items-center leading-none font-normal'>
    <InformationCircleIcon className='size-5 mr-2 text-blue-800' />
    <LinkButton onClick={openModal}>
      Select a tutor&nbsp;
    </LinkButton>

    <p>
      for {lessonName}
    </p>
  </Notification>
)

export default NoTutorSelectedNotification
