import { forwardRef, useEffect, useState } from 'react'
import { useEditor, EditorContent } from '@tiptap/react'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import StarterKit from '@tiptap/starter-kit'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import ImageExtension from '@tiptap/extension-image'
import { Markdown } from 'tiptap-markdown'

import ToolBar from './ToolBar'
import TableBubbleMenu from './TableBubbleMenu'
import MathExtension from './MathExtension'
import KeyboardHandler from './KeyboardHandler'

const extensions = [
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    heading: {
      levels: [1, 2, 3]
    },
    horizontalRule: false,
    blockquote: false
  }),
  Markdown.configure({
    html: false, // Allow HTML input/output
    tightLists: false, // No <p> inside <li> in markdown output
    tightListClass: 'tight', // Add class to <ul> allowing you to remove <p> margins when tight
    bulletListMarker: '*', // <li> prefix in markdown output
    linkify: true, // Create links from "https://..." text
    breaks: true, // New lines (\n) in markdown input are converted to <br>
    transformPastedText: true, // Allow to paste markdown text in the editor
    transformCopiedText: true // Copied text is transformed to markdown
  }),
  Table.configure({
    resizable: false,
    allowTableNodeSelection: false
  }),
  TableRow,
  TableHeader,
  TableCell,
  ImageExtension,
  MathExtension,
  KeyboardHandler
]

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
const ALLOWED_IMAGE_TYPES = ['image/jpg', 'image/jpeg', 'image/png', 'image/heic', 'image/heif', 'image/webp']

const TextEditor = forwardRef(({
  value,
  onChange,
  disabled,
  uploadImage,
  newlineHintEnabled = false,
  onError
}, ref) => {
  const [isUploading, setIsUploading] = useState(false)

  const handleImageUpload = async (file) => {
    setIsUploading(true)

    try {
      const url = await uploadImage(file)
      return url
    } finally {
      setIsUploading(false)
    }
  }

  const editor = useEditor({
    extensions,
    content: value,
    editable: !disabled,
    editorProps: {
      attributes: {
        class: 'p-3 text-editor block w-full h-full overflow-x-auto p-0 border-none ring-0 focus:ring-0 focus:border-none focus-within:outline-none shadow-none text-lg sm:leading-6 resize-none',
      },
      handleDrop: async (view, event, slice, moved) => {
        if (!uploadImage) return false

        if (!moved && event.dataTransfer?.files?.[0]) {
          const file = event.dataTransfer.files[0]

          // Only handle image files
          if (!ALLOWED_IMAGE_TYPES.includes(file.type)) {
            return false
          }

          if (file.size > MAX_FILE_SIZE) {
            if (onError) onError('Image size must be less than 10MB')

            return false
          }

          try {
            const url = await handleImageUpload(file)
            editor.commands.setImage({ src: url })
          } catch (error) {
            if (onError) onError(error.message || 'Failed to upload image')
          }

          return true
        }

        return false
      },
      transformPastedHTML: html => {
        const parser = new DOMParser()
        const doc = parser.parseFromString(html, 'text/html')
        const table = doc.querySelector('table')

        if (table) {
          // Check for complex HTML not supported by GFM
          const complexElements = table.querySelectorAll('img, a, strong, em, code, pre, blockquote, ul, ol, li')
          if (complexElements.length > 0) {
            // Complex HTML found, return an empty string to prevent pasting
            if (onError) {
              onError('Complex formatting within tables is not supported')
            }
            return ''
          }

          const headers = table.querySelectorAll('th')

          if (headers.length === 0) {
            const firstRow = table.querySelector('tr')
            firstRow.querySelectorAll('td').forEach((cell) => {
              const header = document.createElement('th')
              header.textContent = cell.textContent
              firstRow.replaceChild(header, cell)
            })
          }

          // Handle multi-column rows
          table.querySelectorAll('tr').forEach(row => {
            const cells = row.querySelectorAll('td, th')
            cells.forEach((cell, index) => {
              const colspan = parseInt(cell.getAttribute('colspan') || '1', 10)
              if (colspan > 1) {
                // Create new cells to replace the colspan
                for (let i = 1; i < colspan; i++) {
                  const newCell = document.createElement(cell.tagName)
                  newCell.textContent = index === 0 ? cell.textContent : ''
                  row.insertBefore(newCell, cell.nextSibling)
                }
                cell.removeAttribute('colspan')
              }
            })
          })

          // Remove any nested tables
          const nestedTables = table.querySelectorAll('table')
          nestedTables.forEach(nestedTable => nestedTable.remove())

          // Remove any block elements inside table cells
          const blockElements = table.querySelectorAll('div, p')
          blockElements.forEach(element => {
            const textContent = element.textContent
            element.parentNode.replaceChild(document.createTextNode(textContent), element)
          })

          // Return only the table HTML
          return table.outerHTML
        }

        // If no table is found, return the original HTML
        return html
      }
    },
    onUpdate: ({ editor }) => {
      if (onChange) {
        onChange(editor.storage.markdown.getMarkdown())
      }
    }
  })

  useEffect(() => {
    // If value is set externally OR value is blank, update the editor content.
    if (!editor.isFocused || !value) {
      editor.commands.setContent(value)
    }
  }, [value])

  return (
    <>
      <ToolBar
        uploadImage={uploadImage ? handleImageUpload : undefined}
        isUploading={isUploading}
        newlineHintEnabled={newlineHintEnabled}
        editor={editor}
      />
      <TableBubbleMenu editor={editor} />
      <EditorContent
        className='h-full'
        ref={ref}
        editor={editor}
      />
    </>
  )
})

export default TextEditor
