import { forwardRef } from 'react'

import counties from './countries.json'

const CountryCodeSelect = forwardRef(({ label = 'Country', ...props }, ref) => {
  return (
    <div>
      <label htmlFor="countryCode" className="sr-only">
        Country code
      </label>

      <select
        id='countryCode'
        className="relative block w-full rounded-none rounded-t-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6"
        ref={ref}
        {...props}
      >
        <option value=''>Select country code</option>
        <For each='country' of={counties}>
          <option key={country.code} value={country.code}>
            {country.name}&nbsp;({country.dialCode})
          </option>
        </For>
      </select>
    </div>
  )
})

export default CountryCodeSelect
