import React, { useEffect, useRef, useState } from 'react'

const AutoScroll = ({ children, className, threshold = 200 }) => {
  const containerRef = useRef(null)
  const [autoScroll, setAutoScroll] = useState(true)

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current

    const distanceFromBottom = scrollHeight - scrollTop - clientHeight
    const isWithinScrollThreshold = distanceFromBottom <= threshold

    setAutoScroll(isWithinScrollThreshold)
  }

  // Add event listener on mount, remove on unmount.
  useEffect(() => {
    const container = containerRef.current
    container.addEventListener('scroll', handleScroll)

    return () => {
      container.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // Scroll to bottom when children change and autoscroll is enabled.
  useEffect(() => {
    if (autoScroll) {
      const { scrollHeight } = containerRef.current
      containerRef.current.scrollTop = scrollHeight
    }
  }, [children, autoScroll])

  return (
    <div
      ref={containerRef}
      className={className}
    >
      {children}
    </div>
  )
}

export default AutoScroll
