import { useQuery, useMutation } from '@tanstack/react-query'
import { useNavigate, useParams, Navigate } from 'react-router-dom'
import { gql } from 'graphql-request'

import { useCurrentUser } from '@contexts/currentUser'
import { request } from '@helpers/graphql'
import Button from '@components/Button'
import ButtonLink from '@components/ButtonLink'
import Card from '@components/Card'
import { useFlashMessage } from '@components/FlashMessage'

import Spinner from '@components/Spinner'
import Notification from '@components/Notification'

import SignUpForm from './SignUpForm'

const CLASSROOM_BY_SHORT_CODE_QUERY = gql`
  query classroomByShortCode($shortCode: String!) {
    classroomByShortCode(shortCode: $shortCode) {
      name
      educatorProfileName
    }
  }
`

const JOIN_CLASSROOM_MUTATION = gql`
  mutation joinClassroom($input: JoinClassroomInput!) {
    joinClassroom(input: $input) {
      success
      errors {
        message
      }
    }
  }
`

const StudentJoinClassroom = () => {
  const { setFlashMessage } = useFlashMessage()
  const { shortCode } = useParams()
  const navigate = useNavigate()
  const { user: { signedIn, role } } = useCurrentUser()

  const { data: { classroomByShortCode: classroom } = {}, isLoading } = useQuery({
    queryKey: ['classroomByShortCode', shortCode],
    queryFn: async () => await request(CLASSROOM_BY_SHORT_CODE_QUERY, { shortCode }),
    disabled: !shortCode
  })

  const { mutateAsync: joinClassroom, isLoading: isJoining, isError } = useMutation({
    mutationFn: async variables => await request(JOIN_CLASSROOM_MUTATION, variables),
    onSuccess: response => {
      if (response.joinClassroom.success) {
        setFlashMessage(`You have joined ${classroom.name} 🎉`, 'success')
        navigate('/students')
      }
    }
  })

  if (signedIn && role !== 'student') return <Navigate to="/educators/projects" />
  if (isLoading) return <Spinner className="relative top-0 left-0 flex items-center justify-center w-full h-screen" />

  return (
    <Card className="flex flex-col md:w-[450px] self-center md:mt-10 mt-5 p-5">
      <img src="/brainstorm-board.svg" alt="Brainstorming on a board" className="self-center w-[200px] h-[200px]" />

      <h1 className="text-2xl font-bold mt-3">Join classroom</h1>

      <Choose>
        <When condition={!classroom}>
          <Notification theme="error" className="my-3">
            Hmmm we couldn't find that classroom 🤔
          </Notification>

          <p>Try enter the code again.</p>

          <ButtonLink label="Enter your join code" className="w-full mt-3" to="/join_short_code" />
        </When>

        <When condition={signedIn}>
          <h2 className="text-base mt-3">
            You are joining <span className="font-semibold">{classroom.name}</span> with <span className="font-semibold">{classroom.educatorProfileName}</span>.
          </h2>

          <If condition={isError}>
            <Notification theme="error" className="mt-3">
              Oops, something went wrong 🤔
            </Notification>
          </If>

          <Button
            disabled={isJoining}
            label={isJoining ? <Spinner className="[&_*]:bg-white" /> : 'Join classroom'}
            className="mt-5 w-full"
            onClick={() => joinClassroom({ input: { shortCode } })} />
        </When>

        <Otherwise>
          <h2 className="text-base mt-3">
            You are joining <span className="font-semibold">{classroom.name}</span> with <span className="font-semibold">{classroom.educatorProfileName}</span>.
          </h2>

          <SignUpForm
            classroomName={classroom.name}
            shortCode={shortCode} />
        </Otherwise>
      </Choose>
    </Card>
  )
}

export default StudentJoinClassroom
