import React from 'react'
import { twMerge } from 'tailwind-merge'
import { useToolbar } from './Container'

const ToolbarToggle = ({ children, ...props }) => {
  const { isToolbarEnabled, toggleToolbarEnabled } = useToolbar()

  return (
    <button
      type='button'
      className={twMerge(
        'flex items-center rounded-full hover:bg-gray-200 p-1 mr-3 text-lg',
        isToolbarEnabled && 'bg-gray-200'
      )}
      onClick={toggleToolbarEnabled}
      {...props}
    >
      <span className='text-xl underline size-6 leading-tight'>Aa</span>
      {children}
    </button>
  )
}

export default ToolbarToggle
