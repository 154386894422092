import { forwardRef } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { twMerge } from 'tailwind-merge'

const SearchInput = forwardRef(({ onChange, onSearch, onClear, value, className, ...props }, ref) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSearch()
    }
  }

  return (
    <div className={twMerge('w-full', className)}>
      <label htmlFor='search' className='hidden'>
        Search
      </label>

      <div className='flex rounded-md shadow-sm'>
        <div className='relative flex flex-grow items-stretch focus-within:z-10'>
          <input
            type='text'
            name='search'
            id='search'
            className='block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
            placeholder='Search 🔎'
            onChange={onChange}
            ref={ref}
            onKeyDown={handleKeyDown}
            value={value}
            {...props}
          />

          <If condition={value}>
            <XMarkIcon onClick={onClear} className='flex items-center justify-center absolute top-2 right-2 w-6 h-6 text-gray-400 hover:text-gray-500 hover:cursor-pointer' />
          </If>
        </div>

        <button
          onClick={onSearch}
          type='button'
          className='relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-200'
        >
          Search
        </button>
      </div>
    </div>
  )
})

export default SearchInput
