import CustomTooltip from './CustomTooltip'

import {
  ResponsiveContainer,
  ScatterChart as RechartsScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Scatter
} from 'recharts'

const ScatterChart = ({ data, options }) => {
  const { xAxis = {}, yAxis = {}, fill = {}, labels = {} } = options || {}

  if (!data) return null

  return (
    <div>
      <ResponsiveContainer className='w-full' height={300}>
        <RechartsScatterChart
          margin={{ top: 20, right: 20, bottom: 20 }}
          style={{ cursor: 'pointer' }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='x' type='number' unit={xAxis.unit} label={{ value: labels?.x, position: 'bottom' }} />
          <YAxis dataKey='y' type='number' unit={yAxis.unit} label={{ value: labels?.y, angle: -90, offset: 20, position: 'insideBottomLeft' }} />

          <Tooltip content={<CustomTooltip />} />

          <Scatter data={data.null} fill={fill.null} />
          <Scatter data={data.poor} fill={fill.poor} />
          <Scatter data={data.satisfactory} fill={fill.satisfactory} />
          <Scatter data={data.good} fill={fill.good} />
          <Scatter data={data.currentUser} fill={fill.currentUser} />
        </RechartsScatterChart>
      </ResponsiveContainer>
    </div>
  )
}

export default ScatterChart
