import Card from '@components/Card'
import Button from '@components/Button'

const NoResults = ({ openModal }) => (
  <Card className="flex flex-col items-center justify-center w-auto p-10 mx-5">
    <img src='/brainstorm-board.svg' alt='Brainstorming at the board' className='w-[200px] self-center mb-5' />
    <h4 className="text-2xl font-semibold">No classrooms</h4>
    <p>
      Get started by creating your first classroom and adding students.
    </p>
    <Button label="New classroom" onClick={openModal} className="mt-5" />
  </Card>
)

export default NoResults
