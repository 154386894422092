import Card from '@components/Card'

const NoResults = ({ clearFilters }) => (
  <Card className='w-auto flex flex-col items-center justify-center p-10 mt-5'>
    <img src='/searching.svg' alt='Student reading' className='w-[100px] self-center mb-5' />
    <h4 className='text-2xl font-semibold'>No results</h4>
    <p>
      <button className='inline text-blue-500' onClick={clearFilters}>
        Clear your filters
      </button>

      &nbsp;to view all organizations.
    </p>
  </Card>
)

export default NoResults
