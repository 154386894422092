import { twMerge } from 'tailwind-merge'

import { forwardRef } from 'react'
import Label from './Label'

const TextInput = forwardRef(({ label, onChange, required, type = 'text', className, inputClassName, ...props }, ref) => (
  <Label className={className} title={label} required={required}>
    <input
      className={twMerge(
        'block font-normal w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300',
        'placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-base sm:leading-6',
        inputClassName
      )}
      type={type}
      onChange={onChange}
      ref={ref}
      {...props}
    />
  </Label>
))

export default TextInput
