import Card from '@components/Card'

const InsightCard = ({ data }) => {
  return (
    <div>
      <Choose>
        <When condition={data.length === 4}>
          <dl className='grid grid-cols-1 gap-5 sm:grid-cols-4'>
            <For each='item' of={data}>
              <div key={item.name} className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
                <dt className='truncate text-sm font-medium text-gray-500'>{item.name}</dt>
                <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>{item.value}</dd>
              </div>
            </For>
          </dl>
        </When>

        <Otherwise>
          <dl className='grid grid-cols-1 gap-5 sm:grid-cols-3'>
            <For each='item' of={data}>
              <div key={item.name} className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
                <dt className='truncate text-sm font-medium text-gray-500'>{item.name}</dt>
                <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>{item.value}</dd>
              </div>
            </For>
          </dl>
        </Otherwise>
      </Choose>
    </div>
  )
}

export default InsightCard
