import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm } from 'react-hook-form'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import TextInput from '@components/TextInput'
import Button from '@components/Button'

import LinkButton from '@components/LinkButton'
import SingleFileUpload from '@components/SingleFileInput'
import Spinner from '@components/Spinner'

const UPDATE_IMAGE_BLOCK_MUTATION = gql`
  mutation updateImageBlock($input: UpdateImageBlockInput!) {
    updateImageBlock(input: $input) {
      image {
        caption
        file {
          url
        }
      }
      errors {
        message
      }
    }
  }
`

const CREATE_IMAGE_BLOCK_FILE_MUTATION = gql`
  mutation createImageBlockFile($input: CreateImageBlockFileInput!) {
    createImageBlockFile(input: $input) {
      image {
        caption
        file {
          url
        }
      }
      errors {
        message
      }
    }
  }
`

const DELETE_IMAGE_BLOCK_FILE_MUTATION = gql`
  mutation deleteImageBlockFile($input: DeleteImageBlockFileInput!) {
    deleteImageBlockFile(input: $input) {
      image {
        caption
        file {
          url
        }
      }
      errors {
        message
      }
    }
  }
`

const ImageForm = ({ id, file, caption, refetch }) => {
  const [showFileSizeError, setShowFileSizeError] = useState(false)

  const { register, handleSubmit, formState: { errors } } = useForm({ defaultValues: { caption } })

  const { mutate: updateImageBlock, isLoading } = useMutation({
    mutationFn: async variables => request(UPDATE_IMAGE_BLOCK_MUTATION, { input: { id, ...variables } }),
    onSuccess: () => refetch()
  })

  const { mutate: createImage, isLoading: isCreating } = useMutation({
    mutationFn: async variables => request(CREATE_IMAGE_BLOCK_FILE_MUTATION, { input: { id, ...variables } }),
    onSuccess: refetch
  })

  const { mutate: deleteImage, isLoading: isDeleting } = useMutation({
    mutationFn: async () => request(DELETE_IMAGE_BLOCK_FILE_MUTATION, { input: { id } }),
    onSuccess: refetch
  })

  const onSubmit = (data) => {
    updateImageBlock({ caption: data.caption })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-5 py-5">
      <Choose>
        <When condition={file?.url}>
          <img src={file.url} className="w-full rounded-lg sm:w-1/2" />

          <LinkButton type="button" className="flex items-center text-red-500 hover:text-red-600 mt-2 mb-3" onClick={deleteImage}>
            <XMarkIcon className="h-5 w-5 mr-2" />
            Remove image
          </LinkButton>
        </When>

        <When condition={isCreating || isDeleting}>
          <Spinner className="my-5" />
        </When>

        <Otherwise>
          <SingleFileUpload
            className="mt-3"
            tabIndex={0}
            theme="secondary"
            helpText="Supported file formats: .png, .jpg and .gif. Files must be smaller than 10mb."
            accept=".png,.jpg,.jpeg"
            onChange={ev => {
              const file = ev.target.files[0]

              if (file && file.size > 10000000) { // 10mb limit
                setShowFileSizeError(true)

                return
              }

              createImage({ file })
            }}
          />

          <If condition={showFileSizeError}>
            <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">File size must be less than 10mb</p>
          </If>
        </Otherwise>
      </Choose>

      <TextInput
        className="mt-3"
        label="Caption"
        placeholder="Describe your image"
        {...register('caption')}
      />

      <Button
        className="ml-auto mt-5"
        type="submit"
        disabled={isLoading}
        label={isLoading ? 'Saving...' : 'Save'}
      />
    </form>
  )
}

export default ImageForm
