import React from 'react'
import { twMerge } from 'tailwind-merge'
import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react'

const RadioGroup = ({ children, theme, className, label, ...props }) => (
  <HeadlessRadioGroup {...props}>
    <HeadlessRadioGroup.Label className="font-semibold">
      {label}
    </HeadlessRadioGroup.Label>

    <div className={twMerge('flex flex-col gap-3 mt-1', className)}>
      {React.Children.map(children, child => {
        if (!child) return null
        return React.cloneElement(child, { theme })
      })}
    </div>
  </HeadlessRadioGroup>
)

export default RadioGroup
