const Privacy = () => (
  <div className='markdown-content m-5'>
    <h1>Mindjoy Privacy Policy</h1>

    <h2>Introduction</h2>
    <p>
      At Mindjoy, we are committed to protecting the privacy and security of our users' information, especially that of students and educators. This Privacy Policy explains how we collect, use, disclose, and protect personal information when you interact with our platform ("Mindjoy'', "Platform", "Service", "we", "us", or "our"). By using Mindjoy, you agree to the terms of this Privacy Policy.
      Please note that our Service is designed for Educators and Educational Institutions, and as such, we adhere to applicable federal student privacy laws, including the Family Educational Rights and Privacy Act (FERPA) and the Children's Online Privacy Protection Act (COPPA).
      For the purposes of this Privacy Policy, “Personal Information” means information about identified or identifiable individuals, such as their name and email address, and includes “persona information” as defined by South Africa's Protection of Personal Information Act (POPIA), “personal data” as defined by the European Union's General Data Protection Regulation (“GDPR”) and “personal information” as defined by the California Consumer Privacy Act (“CCPA”)
    </p>

    <h2>1. Information We Collect</h2>
    <p>
      In order to open and operate an account for you, provide you with our products and services, or communicate with you, we collect information in the following categories:

      <ul>
        <li>Account Information: When you create an account with Mindjoy, we collect your name, email address, contact number, password, and billing information. If you are an educator or administrator, we may also collect your role and the name of your educational institution.</li>
        <li>Student Data: We collect information about students provided by the educational institution, educators, or students themselves. This may include student names, grades, and school-related performance data. We collect only the minimum amount of information necessary to provide our Service.</li>
        <li>User-generated Content: When you interact with the Mindjoy Platform, we collect the questions you ask, the responses generated by the AI, and any feedback you provide.</li>
        <li>Usage Data: We collect information about how you interact with our Service, such as the features you use, the time you spend on the platform, and the device you use to access the Service.</li>
        <li>Technical Data: We collect technical data, such as cookies, your IP address, browser type, device information, and operating system, to improve our Service and ensure compatibility with various devices.</li>
        <li>Communication Information: If you communicate with us, we collect your name, contact information, and the contents of any messages you send.</li>
        <li>Third-party integrations: We allow you to connect third-party applications to our Service for the purposes of signing up for an account, signing in to an existing account, and importing classroom roster data. Signing up and signing in to our Service requires access to your email, first name and last name. Importing classroom roster data will require permission to access your classrooms, classroom rosters and student email addresses. Third-party applications include but are not limited to, Google Classroom and Microsoft Teams for Education. Access tokens obtained through integrations are stored in an encrypted format for enhanced security.</li>
        <li>Publicly available information: We may obtain Personal Information about you from other sources, including public records, publicly available information on internet sites, and third parties that help us update, expand, and analyze our records and inform our marketing and recruitment efforts. For instance, we may use information from LinkedIn or from a third-party data provider to update information about you in our contact database.</li>
      </ul>
    </p>

    <h2>2. Processing your personal information</h2>
    <p>
      We will only collect, use, share or otherwise process your personal information where it is necessary for us to carry out our lawful business activities and subject to the data protection laws in your country or state.
      In doing so, we rely on one or more of the following lawful grounds for processing your personal data:
    </p>

    <ul>
      <li>Contractual necessity: We may process your personal information where it is necessary in order for us to provide our products and services to you or to otherwise comply with our obligations under Mindjoy Terms of use.</li>
      <li>Consent: In some cases, we will only process your personal information where you provide us with your express consent for such processing.</li>
      <li>Legal obligation: In some cases, we may need to process your personal information in order to comply with an obligation imposed by law.</li>
    </ul>

    <h2>How We Use Your Information</h2>
    <p>
      More specifically, we may use your personal information for one or more of the following purposes:
    </p>
    <ul>
      <li>Platform Maintenance: Manage, maintain, and continuously improve our website and app, ensuring smooth operation and enhanced user experience.</li>
      <li>User Access: Manage user access to our website, app, and additional features, including the potential to restrict access when necessary.</li>
      <li>User Experience: Deliver a high-quality and customized learning experience, enhancing the overall value for users. This includes analyzing usage patterns and personalizing the user experience.</li>
      <li>Communication: Notify and communicate with users about updates, benefits, changes, technical notices, security alerts, support messages, and customer support inquiries related to our products and services.</li>
      <li>Marketing and Engagement: Market our services, including sending communications about our products, features, promotions, news, and events. Users can opt out of marketing communications as needed. Provide personalized advertising and marketing to enhance user engagement.</li>
      <li>Account Management: Assist users in managing their Mindjoy account, facilitating account settings changes, and processing payments for Mindjoy services.</li>
      <li>Protection and Security: Detect and prevent fraudulent or unauthorized use to ensure the security of our Service, as well as detect security incidents and protect against malicious or illegal activities.</li>
      <li>Feedback and Testimonials: Collect and potentially post testimonials from parents, students, and coaches to showcase the value of our services.</li>
      <li>Strategic Improvement: Use feedback and data to improve our educational, networking, marketing, social, and recruitment strategies.</li>
      <li>Social Sharing: Offer features that allow users to share content or achievements on third-party social media platforms.</li>
      <li>Legal and Compliance: Comply with legal obligations, respond to lawful requests, handle legal claims, and protect Mindjoy's rights and assets.</li>
      <li>Business Activities: In relation to business actions such as mergers, acquisitions, or sales, and ensuring the continuous provision of our services.</li>
      <li>Safety: Take necessary actions and precautions to ensure the safety of our users.</li>
    </ul>

    <h2>3. Information Sharing and Disclosure</h2>
    <p>
      We do not sell or rent your personal information to third parties. We may share your information in the following circumstances:
    </p>
    <ul>
      <li>Service Providers: We may share your information with third-party service providers that help us operate and maintain our Service, such as hosting providers and security services.These providers are required to protect your information and use it only for the purposes for which it was disclosed.</li>
      <li>AI Models and technologies: In order to provide the Service, we make use of AI models and technologies hosted by third parties. These third party systems receive chat messages in order to generate AI responses, classify information and provide moderation assessments. We do not share your personal information with third party AI providers, and do not make use of third party services that train Large Language Models (LLMs) on your data.</li>
      <li>Legal Compliance: We may disclose your information if required by law, such as in response to a subpoena, court order, or government request</li>
      <li>Business Transfers: If we are involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.</li>
    </ul>

    <h2>4. Security of your Personal Information</h2>
    <p>
      Mindjoy places great importance on ensuring the security of your personal information. We regularly review and implement appropriate and reasonable technical and organisational security measures to keep your personal information safe. Employees of Mindjoy are trained to handle personal information securely and with the utmost respect, failing which they may be subject to disciplinary action. Although we take steps to secure your personal data, we cannot guarantee that your personal data will always remain secure and, in the event of a data breach, we refer you to the section below titled "Data breaches". Importantly, you are responsible for securing the login credentials for your Mindjoy account.
    </p>

    <h2>5. International Data Transfers</h2>
    <p>
      Mindjoy is a global organization, and during the course of providing our services, your data may be transferred, stored and processed in a region outside of the jurisdiction that you reside. As such, the laws of those countries may differ from the laws applicable within your own country. By accepting this Privacy Policy, and then submitting your personal information, you expressly consent to the transfer, storing or processing of such personal data outside of your jurisdiction.
    </p>

    <h2>6. Electronic marketing</h2>
    <p>
      We may send you information about our products, services, news and promotions, or otherwise communicate with you, by email, push or in-app notifications, text message or other means for marketing purposes. When we communicate with you for marketing purposes we will do so only in accordance with applicable law, including consent or opt-out rules.
    </p>

    <p>
      You may always opt out of receiving marketing communications at a later stage by clicking on the “Unsubscribe” option included in every marketing communication sent to you and you can also manage your communications preferences via your account communications settings in our app or on our website.
    </p>

    <p>
      Please note that unsubscribing from marketing content will not stop you from receiving important communications in relation to the security or operation of your Mindjoy account.
    </p>

    <h2>7. Compliance with Student Privacy Laws</h2>

    <p>
      We adhere to applicable federal student privacy laws, including the Family Educational Rights and Privacy Act (FERPA) and the Children's Online Privacy Protection Act (COPPA). We have implemented measures to ensure compliance with these laws, as outlined in our engineering specifications. If you believe we have not complied with these laws, please contact us at <a href='mailto:support@mindjoy.com'>support@mindjoy.com</a>.
    </p>

    <h2>8. California Privacy Rights</h2>
    <p>
      If you are a California resident, you have certain rights under the California Consumer Privacy Act(CCPA), including the right to access, delete, and opt-out of the sale of your personal information.Since we do not sell your personal information, the right to opt-out of the sale does not apply. To exercise your right to access or delete your personal information, please contact us at <a href='mailto:support@mindjoy.com'>support@mindjoy.com</a>.
    </p>

    <h2>9. EU / EEA and UK Data Subjects</h2>
    <p>
      If you are a data subject in the European Union(EU), European Economic Area(EEA), or the United Kingdom(UK), you have certain rights under the General Data Protection Regulation(GDPR) or UK GDPR, including the right to access, correct, delete, restrict the processing of, or object to the processing of your personal information.To exercise these rights, please contact us at <a href='mailto:support@mindjoy.com'>support@mindjoy.com</a>.
    </p>

    <p>
      Additionally, if you are an EU / EEA or UK data subject, you have the right to lodge a complaint with your local data protection authority.
    </p>

    <h2>10. Age Restrictions</h2>
    <p>
      Our Service is intended for use by educational institutions, educators, and students. Mindjoy collects and uses student data at the direction of the school, and therefore relies upon the school to provide appropriate notice to parents of the school's usage of Mindjoy. Mindjoy does not knowingly collect any information from children under the age of 13 unless verifiable parental consent has been provided. If we become aware that we have collected personal information from a child under the age of 13 without verifiable parental consent, we will take steps to delete that information promptly. Contact <a href='mailto:support@mindjoy.com'>support@mindjoy.com</a> if you believe we have inadvertently collected this information so that we can delete it as soon as possible.
    </p>

    <h2>11. Additional matters related to your personal information</h2>
    <h3>Data Retention and Deletion</h3>
    <p>
      We retain your personal information only for as long as necessary to fulfil its intended educational purpose.You may request the deletion of your account and personal information by contacting us at support @mindjoy.com.For students, parents or guardians may request the deletion of their child's personal information by contacting the student's educational institution or us directly.
    </p>
    <h3>Access and Control</h3>
    <p>
      Parents or guardians have the right to review their child's personal information collected by our Service, correct any inaccuracies, and request the deletion of information that violates privacy laws. To exercise these rights, please contact us at <a href='mailto:support@mindjoy.com'>support@mindjoy.com</a> or the student's educational institution.
    </p>
    <h3>Your Rights</h3>
    <p>
      Most of the data Mindjoy collects, and the ways in which we use it, are necessary for us to provide and improve the services we provide to you, or to comply with our obligations.In certain situations, we give you the ability to choose how we use your data.
      Depending on the country or state in which you live, you may have certain rights relating to your personal data, including the right to:
    </p>
    <ul>
      <li>ask us for a copy of the personal data we hold about you;</li>
      <li>ask to have your personal data transferred to you or to a third party chosen by you in a structured, commonly used and machine readable format;</li>
      <li>object to the processing of your personal data, including processing for marketing purposes;</li>
      <li>opt out of the sharing of your personal data with our affiliates or third parties(either for the specific purposes outlined in this policy or for the purpose of marketing their products and services to you); or</li>
      <li>ask that we correct, delete, or restrict the processing of your personal data.</li>
    </ul>
    <p>
      Please contact us at <a href='mailto:support@mindjoy.com'>support@mindjoy.com</a> if you would like to exercise any of the above rights which you are entitled to exercise free from discrimination.These rights are limited in some situations and we may not be able to agree to your request.This could be because we have a legitimate reason for processing your personal information(for example where we have legal obligation to process your personal information, or it is in our legitimate interests to do so) or the right does not apply to the personal data we hold about you.
    </p>

    <h3>Data breaches</h3>
    <p>
      We will notify you and, where applicable, the relevant supervisory authority, of any data breach in accordance with local law.
    </p>

    <h3>Revisions to this Privacy Policy</h3>
    <p>
      We may amend this Privacy Policy from time to time.You should visit the website regularly to check when this Privacy Policy was last updated and to review the current Policy.We will do our best to notify you of any substantive amendments to the Privacy Policy and any such notice will be posted on our application or our website, or sent by email to the address associated with your Mindjoy Account.
    </p>

    <h3>Third-Party Links</h3>
    <p>
      Our Service may contain links to third-party websites, products, or services.We are not responsible for the privacy practices of these third parties, and we encourage you to review their privacy policies before providing them with your personal information.
    </p>

    <h3>Payment processors</h3>
    <p>
      In general, when you purchase classes from Mindjoy, your payment card information is provided directly to our third-party payment processor, Stripe, and Mindjoy Learning does not collect or store that information.However, we may receive limited information about your payment card from Stripe, such as the last four digits of your card, the country of issuance, and the expiration date.
    </p>

    <h3>Updates to Your Information</h3>
    <p>
      If you need to update or correct your personal information, please contact us at <a href='mailto:support@mindjoy.com'>support@mindjoy.com</a> or update it directly through your account settings.
    </p>
    <p>
      By using our Service, you acknowledge and agree to the terms of this Privacy Policy. If you do not agree with any part of this Privacy Policy, you should not use our Service.
    </p>

    <h3>Contact Us</h3>
    <p>
      If you have any questions, concerns, or requests related to this Privacy Policy, please contact our Data Protection Officer at: <a href='mailto:support@mindjoy.com'>support@mindjoy.com</a>
    </p>
  </div>
)

export default Privacy
