import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Card from '@components/Card'
import TextArea from '@components/TextArea'
import TextInput from '@components/TextInput'

const UPDATE_FEEDBACK_COMMENT_MUTATION = gql`
  mutation updateFeedbackComment($updateFeedbackCommentInput: UpdateFeedbackCommentInput!) {
    updateFeedbackComment(input: $updateFeedbackCommentInput) {
      feedbackComment {
        id
        status
        step
      }
    }
  }
`

const areasOfStrengthPlaceholder = category => {
  switch (category) {
    case 'REPORT':
      return 'e.g. consistent effort throughtout the term, strong contribution to class discussions'
    case 'SUBJECT':
      return 'e.g. graphing functons, solving quadratic equations'
    default:
      return 'e.g. creative ideas, sentence structure and grammar'
  }
}

const areasOfImprovementPlaceholder = category => {
  switch (category) {
    case 'REPORT':
      return 'e.g. working with others in groups, problem solving'
    case 'SUBJECT':
      return 'e.g. geometry, showing workings'
    default:
      return 'e.g. referencing sources, research skills'
  }
}

const ReflectStep = ({ feedbackCommentId, startPolling, category, ...props }) => {
  const queryClient = useQueryClient()
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: { ...props }
  })

  const { mutate: updateFeedbackComment } = useMutation({
    mutationFn: async variables => request(UPDATE_FEEDBACK_COMMENT_MUTATION, variables),
    onSuccess: () => startPolling()
  })

  const next = data => {
    updateFeedbackComment({
      updateFeedbackCommentInput: {
        ...data,
        generateComment: true,
        id: feedbackCommentId,
        step: "COMMENT"
      }
    })

    queryClient.setQueryData(['feedbackComment', feedbackCommentId], old => ({
      node: {
        ...old.node,
        ...data,
        status: "PROCESSING",
        step: "COMMENT"
      }
    }))
  }

  const back = () => {
    queryClient.setQueryData(['feedbackComment', feedbackCommentId], old => ({
      node: {
        ...old.node,
        step: "ORGANIZE"
      }
    }))
  }

  return (
    <Card className="p-5">
      <form onSubmit={handleSubmit(next)} className="flex flex-col space-y-4">
        <div className="flex md:flex-row md:space-x-5 flex-col">
          <div className="w-full mb-5">
            <TextInput
              id="student-name"
              label="Student name"
              required
              {...register('studentName', { required: "Student name is required" })} />
            <If condition={errors.studentName}>
              <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.studentName.message}</p>
            </If>
          </div>

          <TextInput
            id="grade"
            label="Grade"
            className="w-full"
            placeholder="Percentage, mark or letter grade"
            {...register('grade')}
          />
        </div>

        <TextArea
          id="areas-of-strength"
          label="Areas of strength"
          className="h-[100px]"
          placeholder={areasOfStrengthPlaceholder(category)}
          {...register('areasOfStrength')}
        />

        <TextArea
          id="areas-of-improvement"
          label="Areas of improvement"
          className="h-[100px]"
          placeholder={areasOfImprovementPlaceholder(category)}
          {...register('areasOfImprovement')} />

        <div className="flex flex-row justify-between">
          <Button onClick={back} label="Back" type="button" variant="outlined" />
          <Button label="Next" type="submit" />
        </div>
      </form>
    </Card>
  )
}

export default ReflectStep
