import { Link } from 'react-router-dom'

import Card from '@components/Card'
import ButtonLink from '@components/ButtonLink'

const EmptyList = () => (
  <Card className="flex flex-col items-center justify-center p-10">
    <p className="text-5xl">🧑‍🎓</p>
    <h4 className="text-2xl font-semibold">No tutors created just yet</h4>
    <p>
      Get started by&nbsp;
      <Link className="inline text-purple-500" to="/educators/tutors/new">
        creating a tutor
      </Link>, or take a look at our&nbsp;
      <Link className="inline text-purple-500" to="/educators/tutors?audience=community">
        community
      </Link> for some inspiration!
    </p>

    <ButtonLink label="New tutor" theme="secondary" to="/educators/tutors/new" className="mt-5" />
  </Card>
)

export default EmptyList
