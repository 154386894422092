import Card from '@components/Card'

const ClosedSessionNoInsights = () => (
  <Card className='w-auto flex flex-col items-center justify-center p-10'>
    <img src='/swingset.svg' alt='Student reading' className='w-[120px] self-center mb-5' />
    <h4 className='text-2xl font-semibold'>No insights available</h4>
    <p>There wasn't enough data to generate insights for this session.</p>
  </Card>
)

export default ClosedSessionNoInsights
