import React, { createContext, useContext, useState } from 'react'
import { twMerge } from 'tailwind-merge'

const ToolbarContext = createContext()

const Container = ({ children, rememberToolbarConfig = false, toolbarEnabled = true, className }) => {
  const [isToolbarEnabled, setIsToolbarEnabled] = useState(() => {
    if (rememberToolbarConfig) {
      const storedValue = localStorage.getItem('isToolbarEnabled') // eslint-disable-line

      return storedValue === 'true'
    }

    return toolbarEnabled
  })

  const toggleToolbarEnabled = () => {
    setIsToolbarEnabled(!isToolbarEnabled)

    if (rememberToolbarConfig) {
      localStorage.setItem('isToolbarEnabled', !isToolbarEnabled) // eslint-disable-line
    }
  }

  return (
    <ToolbarContext.Provider value={{ isToolbarEnabled, toggleToolbarEnabled }}>
      <div
        className={twMerge(
          'relative flex flex-col mb-1 overflow-y-hidden rounded-md bg-white shadow-sm',
          'ring-1 ring-gray-300 focus-within:ring-2 focus-within:ring-blue-600',
          'pb-[48px]', // Actions height is 48px, always account for that.
          isToolbarEnabled ? 'pt-[40px]' : '', // Toolbar height is 40px, account for that when enabled.
          className
        )}
      >
        {children}
      </div>
    </ToolbarContext.Provider>
  )
}

const useToolbar = () => {
  const context = useContext(ToolbarContext)

  if (!context) {
    throw new Error('useToolbar must be used within a ToolbarProvider')
  }

  return context || {}
}

export default Container

export { useToolbar }
