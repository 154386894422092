import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import {
  DocumentTextIcon,
  NumberedListIcon,
  Bars3BottomLeftIcon,
  LightBulbIcon,
  VideoCameraIcon,
  PhotoIcon
} from '@heroicons/react/24/outline'
import { twMerge } from 'tailwind-merge'

import OverflowMenu from '@components/OverflowMenu'
import { stripMarkdown } from '@helpers/format'

const formatPreviewLabel = (text, fallback) => {
  if (!text) return fallback

  return stripMarkdown(text)
}

const BlockPreview = ({ id, blockId, __typename, index, onDelete, selected, setSelectedBlockId, setBlockIdToDelete, ...props }) => {
  return (
    <Draggable draggableId={blockId} index={index}>
      {provided => (
        <div
          onClick={() => setSelectedBlockId(blockId)}
          className='flex flex-col group/block w-full'
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div
            className={twMerge(
              selected && 'bg-gray-100 font-semibold',
              'w-full flex hover:bg-gray-100 rounded-md p-3 gap-3 items-center leading-snug'
            )}
            {...provided.dragHandleProps}
            style={{ cursor: 'pointer' }}
          >
            <Choose>
              <When condition={__typename === 'Text'}>
                <DocumentTextIcon className='size-8 mr-2 bg-purple-200 rounded-md p-1 shrink-0 hover:cursor-grab' />
                <div className='flex-1 min-w-0'>
                  <p className='line-clamp-2'>{formatPreviewLabel(props.content, 'Content')}</p>
                </div>
              </When>

              <When condition={__typename === 'Reflection'}>
                <LightBulbIcon className='size-8 mr-2 bg-red-200 rounded-md p-1 shrink-0 hover:cursor-grab' />
                <div className='flex-1 min-w-0'>
                  <p className='line-clamp-2'>{formatPreviewLabel(props.question, 'Reflection')}</p>
                </div>
              </When>

              <When condition={__typename === 'Question'}>
                <NumberedListIcon className='size-8 mr-2 bg-yellow-200 rounded-md p-1 shrink-0 hover:cursor-grab' />
                <div className='flex-1 min-w-0'>
                  <p className='line-clamp-2'>{formatPreviewLabel(props.label, 'Multiple Choice')}</p>
                </div>
              </When>

              <When condition={__typename === 'FreeResponse'}>
                <Bars3BottomLeftIcon className='size-8 mr-2 bg-orange-200 rounded-md p-1 shrink-0 hover:cursor-grab' />
                <div className='flex-1 min-w-0'>
                  <p className='line-clamp-2'>{formatPreviewLabel(props.question, 'Free Response')}</p>
                </div>
              </When>

              <When condition={__typename === 'Video'}>
                <VideoCameraIcon className='size-8 mr-2 bg-purple-200 rounded-md p-1 shrink-0 hover:cursor-grab' />
                <div className='flex-1 min-w-0'>
                  <p className='line-clamp-2'>Video</p>
                </div>
              </When>

              <When condition={__typename === 'Image'}>
                <PhotoIcon className='size-8 mr-2 bg-purple-200 rounded-md p-1 shrink-0 hover:cursor-grab' />
                <div className='flex-1 min-w-0'>
                  <p className='line-clamp-2'>Image</p>
                </div>
              </When>
            </Choose>

            <OverflowMenu className='ml-auto shrink-0' orientation='vertical'>
              <OverflowMenu.Item onClick={() => setBlockIdToDelete(blockId)}>
                <span className='text-red-500'>Delete</span>
              </OverflowMenu.Item>
            </OverflowMenu>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default BlockPreview
