import { useEffect } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'

const UPDATE_EDUCATOR_PROJECT_SUBMISSION_MUTATION = gql`
  mutation updateEducatorProjectSubmission($input: UpdateEducatorProjectSubmissionInput!) {
    updateEducatorProjectSubmission(input: $input) {
      educatorProjectSubmission {
        id
        status
        educatorProject {
          id
          name
          readme
          series {
            id
            name
          }
          blocks {
            blockId
            position
            ... on Text {
              __typename
              id
              content
            }
            ... on Reflection {
              __typename
              id
              question
            }
            ... on Question {
              __typename
              id
              label
              options {
                id
                label
              }
            }
            ... on FreeResponse {
              __typename
              id
              question
            }
            ... on Video {
              __typename
              id
              url
            }
            ... on Image {
              __typename
              id
              caption
              file {
                filename
                url
              }
            }
          }
          playground
          submission
          createdAt
          updatedAt
        }
        blockSubmissions {
          id
          status
          blockId
          content {
            ... on ReflectionSubmission {
              __typename
              answer
            }
            ... on QuestionSubmission {
              __typename
              answer {
                id
              }
            }
          }
        }
      }
      errors {
        message
      }
    }
  }
`

const EDUCATOR_PROJECT_SUBMISSION_QUERY = gql`
  query educatorProjectSubmission($id: ID!) {
    node(id: $id) {
      ... on EducatorProjectSubmission {
        id
        status
        educatorProject {
          id
          name
          readme
          series {
            id
            name
          }
          blocks {
            blockId
            position
            ...on Text {
              __typename
              id
              content
            }
            ...on Reflection {
              __typename
              id
              question
            }
            ... on Question {
              __typename
              id
              label
              options {
                id
                label
              }
            }
            ... on FreeResponse {
              __typename
              id
              question
            }
            ... on Video {
              __typename
              id
              url
            }
            ... on Image {
              __typename
              id
              caption
              file {
                filename
                url
              }
            }
          }
          playground
          submission
          createdAt
          updatedAt
        }
        blockSubmissions {
          id
          status
          blockId
          content {
            ... on ReflectionSubmission {
              __typename
              answer
            }
            ... on FreeResponseSubmission {
              __typename
              answer
              attachments {
                id
                filename
                url
              }
            }
            ... on QuestionSubmission {
              __typename
              answer {
                id
              }
            }
          }
        }
      }
    }
  }
`

const useEducatorProjectSubmission = ({ educatorProjectId }) => {
  const {
    isLoading: isUpdating,
    mutateAsync,
    data: {
      updateEducatorProjectSubmission: { educatorProjectSubmission: { id: submissionId } = {} } = {}
    } = {}
  } = useMutation({
    mutationFn: async (variables = {}) => request(UPDATE_EDUCATOR_PROJECT_SUBMISSION_MUTATION, { input: { educatorProjectId, ...variables } })
  })

  const {
    isLoading,
    refetch,
    data: { node: { educatorProject: project, ...submission } = {} } = {}
  } = useQuery({
    queryKey: ['educatorProjectSubmission', submissionId],
    queryFn: async () => request(EDUCATOR_PROJECT_SUBMISSION_QUERY, { id: submissionId }),
    enabled: !!submissionId
  })

  useEffect(() => {
    mutateAsync()
  }, [educatorProjectId])

  return { submission, project, isLoading, refetch, updateSubmission: mutateAsync, isUpdating }
}

export {
  useEducatorProjectSubmission
}
