import PageHeading from '@components/PageHeading'

import MyLibrary from './MyLibrary'

const Projects = () => (
  <>
    <PageHeading title='Courses'>
      <div className='inline ml-3 text-xs bg-transparent text-blue-600 border-blue-600 border rounded-full px-2 py-1 font-normal mr-auto'>
        Experimental
      </div>
    </PageHeading>

    <MyLibrary />
  </>
)

export default Projects
