import ConversationComponent from './Conversation'
import EmptyState from './EmptyState'
import LoadingState from './LoadingState'
import MessageLoadingState from './MessageLoadingState'
import ToolLoadingState from './ToolLoadingState'
import Message from './Message'
import LoadMore from './LoadMore'
import SuggestionList from './SuggestionList'
import Suggestion from './Suggestion'

const Conversation = ConversationComponent

Conversation.EmptyState = EmptyState
Conversation.LoadingState = LoadingState
Conversation.ToolLoadingState = ToolLoadingState
Conversation.MessageLoadingState = MessageLoadingState
Conversation.Message = Message
Conversation.LoadMore = LoadMore
Conversation.SuggestionList = SuggestionList
Conversation.Suggestion = Suggestion

export default Conversation
