import { useNavigate } from 'react-router-dom'

import Button from '@components/Button'
import Card from '@components/Card'
import { formatDate } from '@helpers/format'

import { useSubscription } from '../../hooks/subscription'

const customerPortalUrl = import.meta.env.VITE_STRIPE_CUSTOMER_PORTAL_URL

const UpgradeButton = () => {
  const navigate = useNavigate()

  return (
    <Button
      onClick={() => navigate('/educators/upgrade')}
      label={
        <div className='flex justify-center items-center'>
          Upgrade
          <img className='ml-3 w-5 h-5' src='/crown.svg' alt='crown' />
        </div>
      }
      theme='secondary'
      className='mt-3'
    />
  )
}

const Billing = () => {
  const {
    hasActiveSubscription,
    isWithinTrialPeriod,
    daysLeftInTrial,
    subscription,
    organizationSubscription,
    totalStudentsInClassroomsCount
  } = useSubscription()

  return (
    <Card className='p-5'>
      <h2 className='text-2xl font-bold font-heading'>Billing</h2>

      <Choose>
        <When condition={isWithinTrialPeriod}>
          <p className='mt-3'>
            You are currently on a <strong>free trial</strong>.
            You have <strong>{daysLeftInTrial} {daysLeftInTrial === 1 ? 'day' : 'days'}</strong> remaining.
          </p>

          <UpgradeButton />
        </When>

        <When condition={!hasActiveSubscription}>
          <p className='mt-3'>
            You are not currently subscribed to Mindjoy.
          </p>

          <UpgradeButton />
        </When>

        <When condition={!!subscription}>
          <p className='mt-3'>
            You are currently subscribed to the <strong>{subscription.plan.toLowerCase()} plan</strong>.
            Your subscription will automatically renew on {formatDate(subscription.expiresAt)}.
          </p>

          <p>
            <strong>{totalStudentsInClassroomsCount} / 90 students</strong> are currently enrolled in your classrooms.
          </p>

          <Button onClick={() => window.open(customerPortalUrl, '_blank')} label='Manage subscription' className='mt-5' />
        </When>

        <When condition={!!organizationSubscription}>
          <p className='mt-3'>
            You are currently subscribed to the <strong>pro plan</strong> through your organization.
            Your organization's subscription renews on {formatDate(organizationSubscription.expiresAt)}.
          </p>
        </When>
      </Choose>

      <p className='mt-3'>
        If you have any questions, please get in touch with us at <a className='text-blue-500' href='mailto:support@mindjoy.com'>support@mindjoy.com</a>.
      </p>

      <p>Mindjoy partners with Stripe to manage billing. <a className='text-blue-500' href='https://support.stripe.com/questions/end-customer-faq'>Learn more.</a></p>
    </Card>
  )
}

export default Billing
