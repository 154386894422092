import Button from '@components/Button'
import Spinner from '@components/Spinner'

const LoadMore = ({ isFetchingNextPage, hasNextPage, fetchNextPage }) => {
  if (!hasNextPage) return null

  return (
    <Choose>
      <When condition={isFetchingNextPage}>
        <Spinner className="self-center mb-3" />
      </When>

      <When condition={hasNextPage}>
        <Button
          className="mb-3 w-fit self-center rounded-full px-3 py-2 bg-gray-100 hover:bg-gray-200"
          theme="light"
          variant="outlined"
          onClick={fetchNextPage}
          label="Load more"
        />
      </When>
    </Choose>
  )
}

export default LoadMore
