import { PencilSquareIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline'
import { truncate } from 'lodash'

import { stripMarkdown } from '@helpers/format'
import Image from '@components/Image'

const formatPrompt = prompt => truncate(stripMarkdown(prompt), { length: 100 })

const SelectedTutor = ({ name, imageUrl, description, summary, openModal }) => (
  <div className='flex gap-3'>
    <Choose>
      <When condition={imageUrl}>
        <Image
          src={imageUrl}
          alt={name}
          placeholder={<div className='w-[100px] h-[100px] bg-gray-200 rounded-md shrink-0' />}
          className='w-[100px] object-cover rounded-md'
        />
      </When>

      <Otherwise>
        <div className='flex items-center justify-center w-[100px] h-[100px] rounded-md bg-blue-100 shrink-0'>
          <ChatBubbleLeftRightIcon className='size-8 text-blue-400' />
        </div>
      </Otherwise>
    </Choose>

    <div className='w-full'>
      <div className='w-full flex justify-between items-center leading-none'>
        <h3 className='text-lg font-bold leading-none'>{name}</h3>

        <button
          onClick={openModal}
          className='rounded-full hover:bg-gray-200 p-1 self-start'
        >
          <PencilSquareIcon className='size-6' />
        </button>
      </div>

      <p className='leading-tight'>
        <Choose>
          <When condition={summary}>
            {summary}
          </When>

          <Otherwise>
            {formatPrompt(description)}
          </Otherwise>
        </Choose>
      </p>
    </div>
  </div>
)

export default SelectedTutor
