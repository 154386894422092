import { NavLink } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

const linkTheme = theme => {
  switch (theme) {
    case 'secondary':
      return 'font-semibold border-b-purple-500'
    default:
      return 'font-semibold border-b-blue-500'
  }
}

const Link = ({ to, state, end = false, label, icon, theme = 'primary' }) => (
  <NavLink
    to={to}
    state={state}
    end={end}
    className={({ isActive }) => twMerge(
      'w-full sm:w-fit sm:ml-5 flex flex-row justify-center items-center px-1 h-full border-b-4 outline-none',
      isActive ? linkTheme(theme) : 'border-b-white font-normal'
    )}
  >
    {icon}
    <span className={twMerge(
      icon ? 'sm:block hidden' : '',
      'ml-1'
    )}
    >{label}
    </span>
  </NavLink>
)

export default Link
