import { useState } from 'react'
import { truncate } from 'lodash'

const ShowMore = ({ content, maxCharCount = 200 }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleIsExpanded = () => setIsExpanded(!isExpanded)
  const truncatedChildren = truncate(content, { length: maxCharCount })

  if (content.length <= maxCharCount) {
    return <>{content}</>
  }

  return (
    <>
      {isExpanded ? content : truncatedChildren}

      <If condition={!isExpanded}>
        <button className="text-blue-500 cursor-pointer inline pl-1 bg-white" onClick={toggleIsExpanded}>
          more
        </button>
      </If>
    </>
  )
}

export default ShowMore
