import Card from '@components/Card'

const CustomTooltip = ({ active, payload, _label }) => {
  if (active && payload && payload.length) {
    return (
      <Card className='p-3'>
        {Object.entries(payload[0].payload.tooltipData || [])
          .filter(([_, value]) => value)
          .map(([key, value]) => (
            <div key={key} className='flex flex-col'>
              <span className='text-sm font-medium text-gray-500'>{key}</span>
              <span className='text-lg font-semibold text-gray-900'>{value}</span>
            </div>
          ))}
      </Card>
    )
  }

  return null
}

export default CustomTooltip
