import { useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams, Link } from 'react-router-dom'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'
import Spinner from '@components/Spinner'
import Notification from '@components/Notification'
import BreadCrumbs from '@components/Breadcrumbs'
import PageHeading from '@components/PageHeading'

import OrganizeStep from './OrganizeStep'
import ReflectStep from './ReflectStep'
import CommentStep from './CommentStep'
import StepBanner from './StepBanner'
import Loading from './Loading'

const FEEDBACK_COMMENT_QUERY = gql`
  query feedbackComment($id: ID!) {
    node(id: $id) {
      ... on FeedbackComment {
        id
        status
        step
        category
        length
        overallContext
        studentName
        grade
        areasOfStrength
        areasOfImprovement
        comment
        educatorProfile {
          id
          fullName
        }
        organization {
          id
          name
        }
      }
    }
  }
`

const commentCategoryMap = {
  REPORT: {
    label: 'Report comment'
  },
  SUBJECT: {
    label: 'Subject comment'
  },
  TASK: {
    label: 'Task-specific comment'
  },
}

const FeedbackCommentDetails = () => {
  const { id } = useParams()
  const queryClient = useQueryClient()
  const [refetchInverval, setRefetchInterval] = useState(null)
  const startPolling = () => setRefetchInterval(2000)
  const stopPolling = () => setRefetchInterval(null)

  const { isLoading, data: { node: feedbackComment } = {} } = useQuery({
    queryKey: ['feedbackComment', id],
    queryFn: async () => request(FEEDBACK_COMMENT_QUERY, { id }),
    refetchInterval: refetchInverval,
    onSettled: data => {
      if (refetchInverval && (data.node.status === 'COMPLETED' || data.node.status === 'FAILED')) {
        stopPolling()
      }
    }
  })

  const navigateToStep = step => {
    queryClient.setQueryData(['feedbackComment', id], old => ({
      node: {
        ...old.node,
        step: step
      }
    }))
  }

  if (isLoading) return <Spinner className="relative top-0 left-0 flex items-center justify-center w-full h-screen" />

  return (
    <>
      <BreadCrumbs>
        <BreadCrumbs.Link label="Comments" to="/educators/tools/comments" />
        <BreadCrumbs.Text label={
          (feedbackComment.studentName && feedbackComment.category) ?
            commentCategoryMap[feedbackComment.category].label + ' for ' + feedbackComment.studentName :
            'Untitled comment'
        } />
      </BreadCrumbs>

      <PageHeading title="Comment" />

      <div className="m-5">
        <StepBanner
          navigateToStep={navigateToStep}
          currentStep={feedbackComment.step}
        />

        <If condition={feedbackComment.status === 'FAILED'}>
          <Notification theme="error">
            <p>Oops! Something went wrong 😔 Please try again.</p>
          </Notification>
        </If>

        <Choose>
          <When condition={feedbackComment.status === 'PROCESSING'}>
            <Loading step={feedbackComment.step} />
          </When>

          <When condition={feedbackComment.step === 'ORGANIZE'}>
            <OrganizeStep
              feedbackCommentId={id}
              category={feedbackComment.category}
              length={feedbackComment.length}
              overallContext={feedbackComment.overallContext}
            />
          </When>

          <When condition={feedbackComment.step === 'REFLECT'}>
            <ReflectStep
              feedbackCommentId={id}
              startPolling={startPolling}
              category={feedbackComment.category}
              studentName={feedbackComment.studentName}
              grade={feedbackComment.grade}
              areasOfStrength={feedbackComment.areasOfStrength}
              areasOfImprovement={feedbackComment.areasOfImprovement}
            />
          </When>

          <When condition={feedbackComment.step === 'COMMENT'}>
            <CommentStep
              feedbackCommentId={id}
              startPolling={startPolling}
              comment={feedbackComment.comment}
            />
          </When>
        </Choose>
      </div>
    </>
  )
}

export default FeedbackCommentDetails
