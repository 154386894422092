import { Listbox as HeadlessListbox } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'

const Option = ({ value, label }) => (
  <HeadlessListbox.Option
    className='flex relative cursor-pointer select-none py-2 pl-3 pr-4 ui-active:bg-blue-100 ui-active:text-blue-900 text-gray-900'
    value={value}
  >
    {({ selected }) => (
      <>
        <If condition={selected}>
          <span className='absolute hidden ui-selected:flex inset-y-0 right-0 items-center pr-3 ui-active:bg-blue-100'>
            <CheckIcon className='h-5 w-5' aria-hidden='true' />
          </span>
        </If>

        <span className='block truncate font-normal'>
          {label}
        </span>
      </>
    )}

  </HeadlessListbox.Option>
)

export default Option
