import { Outlet } from 'react-router-dom'
import { gql } from 'graphql-request'
import { useQuery } from '@tanstack/react-query'

import { request } from '@helpers/graphql'

const STUDENT_PROFILE_QUERY = gql`
  query profile {
    me {
      profile {
        ... on StudentProfile {
         archived
        }
      }
    }
  }
`

const Archived = () => {
  const { data: { me: { profile: { archived } = {} } = {} } = {} } = useQuery({
    queryKey: ['studentProfile'],
    queryFn: async () => request(STUDENT_PROFILE_QUERY)
  })

  return (
    <>
      <Choose>
        <When condition={!archived}>
          <Outlet />
        </When>

        <Otherwise>
          <div className='flex flex-col items-center justify-center h-full'>
            <h1 className='text-3xl font-bold font-heading'>Your account has been archived 🗂</h1>

            <p className='text-lg mt-5'>
              Please ask your teacher to activate your account if this is a mistake.
            </p>
          </div>
        </Otherwise>
      </Choose>
    </>
  )
}

export default Archived
