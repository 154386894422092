import countries from './countries.json'
import PhoneNumberComponent from './PhoneNumber'
import PhoneNumberInput from './PhoneNumberInput'
import CountryCodeSelect from './CountryCodeSelect'

let PhoneNumber = PhoneNumberComponent
PhoneNumber.PhoneNumberInput = PhoneNumberInput
PhoneNumber.CountryCodeSelect = CountryCodeSelect

export default PhoneNumber

export { countries }
