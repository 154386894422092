import { useState } from 'react'
import { Link, useSearchParams, useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { truncate } from 'lodash'

import { request } from '@helpers/graphql'
import { formatTimestamp } from '@helpers/format'
import PageHeading from '@components/PageHeading'
import Table from '@components/Table'
import Pagination from '@components/Pagination'
import SearchInput from '@components/SearchInput'
import Spinner from '@components/Spinner'

import NoResults from './NoResults'
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'

const TUTORS_QUERY = gql`
  query adminTutors($page: Int!, $search: String) {
    adminTutors(page: $page, perPage: 10, search: $search) {
      nodes {
        id
        name
        createdAt
        summary
        approvedForCommunity
        image {
          url
        }
        userName
      }
      pagesCount
    }
  }
`

const TutorList = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const page = Number(searchParams.get('page')) || 1
  const search = searchParams.get('search') || ''
  const [searchTerm, setSearchTerm] = useState(search)

  const { data: { adminTutors: { nodes: tutors = [], pagesCount } = {} } = {}, isLoading } = useQuery({
    queryKey: ['adminTutors', page, search],
    queryFn: async () => await request(TUTORS_QUERY, { page, search })
  })

  const updateParams = newParams => {
    const params = {
      page,
      search,
      ...newParams
    }

    if (params.page === 1) delete params.page
    if (params.search === '') delete params.search

    setSearchParams(params)
  }

  const clearFilters = () => {
    setSearchTerm('')
    setSearchParams({})
  }

  const clearSearch = () => {
    setSearchTerm('')
    updateParams({ search: '', page: 1 })
  }

  return (
    <>
      <PageHeading title='Tutors' />

      <div className='m-5'>
        <SearchInput
          className='mb-5'
          value={searchTerm}
          onChange={event => setSearchTerm(event.target.value)}
          onSearch={() => updateParams({ search: searchTerm, page: 1 })}
          onClear={clearSearch}
        />

        <Choose>
          <When condition={isLoading}>
            <Spinner className='flex justify-center mt-64' />
          </When>

          <When condition={tutors.length < 1}>
            <NoResults clearFilters={clearFilters} />
          </When>

          <Otherwise>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header width='5%' />
                  <Table.Header width='20%'>Name</Table.Header>
                  <Table.Header width='10%'>Educator</Table.Header>
                  <Table.Header width='35%'>Summary</Table.Header>
                  <Table.Header width='20%'>Approved for community</Table.Header>
                  <Table.Header width='10%'>Created at</Table.Header>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <For each='tutor' of={tutors}>
                  <Table.Row key={tutor.id}>
                    <Table.Cell>
                      <Link
                        className='block'
                        to={`${tutor.id}`}
                        state={{ previousListParams: location.search }}
                      >
                        <Choose>
                          <When condition={tutor.image?.url}>
                            <img src={tutor.image?.url} alt={tutor.name} className='w-10 h-10 rounded-md object-cover' />
                          </When>

                          <Otherwise>
                            <div className='flex justify-center items-center w-10 h-10 rounded-md bg-blue-100'>
                              <ChatBubbleLeftEllipsisIcon className='w-6 h-6 text-blue-500 m-auto' />
                            </div>
                          </Otherwise>
                        </Choose>
                      </Link>
                    </Table.Cell>

                    <Table.Cell>
                      <Link
                        className='block'
                        to={`${tutor.id}`}
                        state={{ previousListParams: location.search }}
                      >
                        {tutor.name}
                      </Link>
                    </Table.Cell>

                    <Table.Cell>
                      <Link
                        className='block'
                        to={`${tutor.id}`}
                        state={{ previousListParams: location.search }}
                      >
                        {tutor.userName}
                      </Link>
                    </Table.Cell>

                    <Table.Cell>
                      <Link
                        className='flex'
                        to={`${tutor.id}`}
                        state={{ previousListParams: location.search }}
                      >
                        {truncate(tutor.summary, { length: 70 })}
                      </Link>
                    </Table.Cell>

                    <Table.Cell>
                      <Link
                        className='flex justify-center'
                        to={`${tutor.id}`}
                        state={{ previousListParams: location.search }}
                      >
                        <Choose>
                          <When condition={tutor.approvedForCommunity}>
                            <CheckCircleIcon className='w-6 h-6 text-green-700' />
                          </When>

                          <Otherwise>
                            <XCircleIcon className='w-6 h-6 text-red-700' />
                          </Otherwise>
                        </Choose>
                      </Link>
                    </Table.Cell>

                    <Table.Cell>
                      <Link
                        className='block'
                        to={`${tutor.id}`}
                        state={{ previousListParams: location.search }}
                      >
                        {formatTimestamp(tutor.createdAt)}
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                </For>
              </Table.Body>
            </Table>

            <Pagination page={page} pagesCount={pagesCount} setPage={page => updateParams({ page })} />
          </Otherwise>
        </Choose>
      </div>
    </>
  )
}

export default TutorList
