import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import Label from './Label'

const TextArea = forwardRef(({ label, onChange, required, type = "text", className, ...props }, ref) => (
  <Label title={label} required={required}>
    <textarea
      className={twMerge('block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-base sm:leading-6 font-normal min-h-[50px]', className)}
      type={type}
      onChange={onChange}
      ref={ref}
      {...props}
    />
  </Label>
))

export default TextArea
