import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { CheckIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Card from '@components/Card'
import Checkbox from '@components/Checkbox'

const UPDATE_EDUCATOR_PROFILE_MUTATION = gql`
  mutation updateEducatorProfile($updateEducatorProfileInput: UpdateEducatorProfileInput!) {
    updateEducatorProfile(input: $updateEducatorProfileInput) {
      educatorProfile {
        subscribedToEmailMarketing
      }
      errors {
        message
      }
    }
  }
`

const EmailPreferences = ({ subscribedToEmailMarketing, refetch }) => {
  const { mutate: updateEducatorProfile, isLoading: isUpdating, isSuccess } = useMutation({
    mutationFn: async variables => request(UPDATE_EDUCATOR_PROFILE_MUTATION, variables),
    onSuccess: () => refetch()
  })

  const { register, handleSubmit } = useForm({ mode: 'onTouched' })

  return (
    <Card className='p-5'>
      <h2 className='text-2xl font-bold font-heading'>Email preferences</h2>

      <form className='flex flex-col space-y-4 mt-5' onSubmit={handleSubmit(data => updateEducatorProfile({ updateEducatorProfileInput: data }))}>
        <Checkbox
          id='subscribed-to-email-marketing'
          label='Let me know about product updates and events'
          defaultChecked={subscribedToEmailMarketing}
          {...register('subscribedToEmailMarketing')}
        />

        <div className='flex flex-row justify-end items-center'>
          <If condition={isSuccess}>
            <CheckIcon className='h-6 w-6 text-green-700 mr-3' />
          </If>

          <Button
            className='w-fit'
            type='submit'
            disabled={!!isUpdating}
            label={isUpdating ? 'Saving...' : 'Save'}
          />
        </div>
      </form>
    </Card>
  )
}

export default EmailPreferences
