import BreadCrumbs from '@components/Breadcrumbs'
import Card from '@components/Card'

const NotFound = () => (
  <div className='h-screen w-full flex flex-col'>
    <div className='w-full bg-white flex flex-col justify-between'>
      <BreadCrumbs>
        <BreadCrumbs.Link label='Creators' to='/educators/creators' />
        <div className='w-20 h-4 bg-gray-200' />
      </BreadCrumbs>
    </div>

    <Card className='w-auto flex flex-col items-center justify-center p-10 m-5'>
      <img src='/reading-exclamation.svg' alt='Student reading' className='w-[120px] self-center mb-5' />
      <h4 className='text-2xl font-semibold'>Creator not found</h4>
      <p>
        We couldn't find the creator you're looking for.
      </p>
    </Card>
  </div>
)

export default NotFound
