import { Tab as HeadlessTab } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'

const tabTheme = theme => {
  switch (theme) {
    case 'secondary':
      return 'border-b-purple-500'
    case 'error':
      return 'border-b-red-500'
    case 'success':
      return 'border-b-green-500'
    default:
      return 'border-b-blue-500'
  }
}

const Tab = ({ children, className, theme, ...props }) => (
  <HeadlessTab className={twMerge("w-full sm:w-fit sm:ml-5 flex flex-row justify-center items-center px-1 h-full ui-not-selected:border-b-white ui-not-selected:font-normal font-semibold border-b-4 outline-none", tabTheme(theme), className)}>
    {children}
  </HeadlessTab>
)

export default Tab
