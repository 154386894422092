import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import Button from '@components/Button'
import Card from '@components/Card'

const StudentJoinShortCode = () => {
  const navigate = useNavigate()
  const { register, setValue, handleSubmit } = useForm()
  const inputRefs = useRef([])

  const onInputChange = (e, index) => {
    const nextInput = inputRefs.current[index + 1]

    if (e.target.value && nextInput) {
      nextInput.focus()
    }
  }

  const onSubmit = (data) => {
    const shortCode = data.shortCode.join('')

    navigate(`/join_classroom/${shortCode}`)
  }

  return (
    <Card className="flex flex-col md:w-[450px] self-center md:mt-10 mt-5 p-5">
      <img src="/brainstorm-board.svg" alt="Brainstorming on a board" className="self-center w-[200px] h-[200px]" />

      <h1 className="text-2xl font-bold mt-3">Enter your join code</h1>

      <form
        onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-3 mt-3">
          <For each="input" of={[1, 2, 3, 4, 5, 6]} index="index">
            <With field={register(`shortCode.${index}`)}>
              <input
                {...field}
                ref={el => {
                  field.ref(el)
                  inputRefs.current[index] = el
                }}
                className="w-full h-[50px] text-4xl block font-normal rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6"
                key={index}
                type="text"
                maxLength="1"
                autoCapitalize="none"
                autoCorrect="off"
                onPaste={e => {
                  e.preventDefault()

                  const paste = e.clipboardData.getData('text')
                  setValue('shortCode', paste)
                }}
                onChange={(e) => onInputChange(e, index)}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace' && e.target.value === '' && index > 0) {
                    inputRefs.current[index - 1].focus()
                  }
                }}
              />
            </With>
          </For>
        </div>

        <Button
          className="w-full mt-5"
          type="submit"
          label="Next" />
      </form>
    </Card>
  )
}

export default StudentJoinShortCode
