import CustomTooltip from './CustomTooltip'

import {
  ResponsiveContainer,
  Tooltip,
  Cell,
  PieChart as RechartsPieChart,
  Pie,
  Legend
} from 'recharts'

const PieChart = ({ data, options }) => {
  const { colors = [] } = options || {}
  const sanitizedData = data.filter(item => item.value > 0)

  if (!sanitizedData) return null

  const renderColorfulLegendText = (_value, entry) => {
    const { color, payload: { name, percent } = {}} = entry;
    const percentValue = (percent * 100).toFixed(0)

    return <span style={{ color }}>{`${name} ${percentValue}%`}</span>
  };

  return (
    <div className='w-full h-[400px]'>
      <ResponsiveContainer>
        <RechartsPieChart>
          <Pie
            data={sanitizedData}
            cx='50%'
            cy='32%'
            labelLine={false}
            outerRadius={110}
            dataKey='value'
          >
            {sanitizedData?.map((_, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>

          <Tooltip content={<CustomTooltip />} />

          <Legend
            layout="vertical"
            align="middle"
            verticalAlign="bottom"
            formatter={renderColorfulLegendText}
          />
        </RechartsPieChart>
      </ResponsiveContainer>
    </div>
  )
}

export default PieChart
