import { twMerge } from 'tailwind-merge'

const Row = ({ children, className, ...props }) => (
  <tr
    className={className}
    {...props}>
    {children}
  </tr>
)

export default Row
