import { Tooltip as ReactToolTip } from 'react-tooltip'

const Tooltip = ({ anchorSelect, place, children, ...props }) => (
  <ReactToolTip
    className='z-20'
    anchorSelect={anchorSelect}
    place={place}
    {...props}
  >
    {children}
  </ReactToolTip>
)

export default Tooltip
