import { BubbleMenu } from '@tiptap/react'
import { twMerge } from 'tailwind-merge'
import { TrashIcon } from '@heroicons/react/24/outline'

import Tooltip from '@components/Tooltip'

import addColumnIcon from './add-column.svg'
import removeColumnIcon from './remove-column.svg'
import addRowIcon from './add-row.svg'
import removeRowIcon from './remove-row.svg'

const Button = ({ id, label, icon, disabled, className, onClick }) => (
  <button
    id={id}
    type='button'
    onClick={onClick}
    disabled={disabled}
    className={twMerge(
      'flex items-center gap-1 px-3 py-1 rounded-md hover:bg-gray-200 disabled:bg-gray-100 disabled:text-gray-400 text-sm',
      className
    )}
    aria-label={label}
  >
    {icon}
  </button>
)

const Divider = () => <div className='border-l border-gray-200 my-1' />

const TableBubbleMenu = ({ editor }) => {
  if (!editor) {
    return null
  }

  const addRowAfter = () => {
    editor.chain().focus().addRowAfter().run()
  }

  const deleteRow = () => {
    editor.chain().focus().deleteRow().run()
  }

  const addColumnAfter = () => {
    editor.chain().focus().addColumnAfter().run()
  }

  const deleteColumn = () => {
    editor.chain().focus().deleteColumn().run()
  }

  const deleteTable = () => {
    editor.chain().focus().deleteTable().run()
  }

  return (
    <div>
      <BubbleMenu
        editor={editor}
        tippyOptions={{ duration: 300, offset: [0, 20] }}
        shouldShow={({ editor }) => editor.isActive('table')}
      >
        <div className='flex bg-white border border-gray-200 rounded-lg shadow-lg p-1 gap-1'>
          <Button
            id='add-row'
            onClick={addRowAfter}
            className='p-1 hover:bg-gray-100 rounded'
            icon={<img src={addRowIcon} className='size-6' />}
            label='Add row'
          />
          <Tooltip anchorSelect='#add-row'>Add row</Tooltip>

          <Button
            id='delete-row'
            onClick={deleteRow}
            className='p-1 hover:bg-gray-100 rounded'
            icon={<img src={removeRowIcon} className='size-6' />}
            label='Delete row'
          />
          <Tooltip anchorSelect='#delete-row'>Remove row</Tooltip>

          <Divider />

          <Button
            id='add-column'
            onClick={addColumnAfter}
            className='p-1 hover:bg-gray-100 rounded'
            icon={<img src={addColumnIcon} className='size-6' />}
            label='Add column'
          />
          <Tooltip anchorSelect='#add-column'>Add column</Tooltip>

          <Button
            id='delete-column'
            onClick={deleteColumn}
            className='p-1 hover:bg-gray-100 rounded'
            icon={<img src={removeColumnIcon} className='size-6' />}
            label='Delete column'
          />
          <Tooltip anchorSelect='#delete-column'>Remove column</Tooltip>

          <Divider />

          <Button
            id='delete-table'
            onClick={deleteTable}
            className='p-1 hover:bg-gray-100 rounded'
            icon={<TrashIcon className='h-5 w-5 text-red-600' />}
            label='Delete table'
          />
          <Tooltip anchorSelect='#delete-table'>Delete table</Tooltip>
        </div>
      </BubbleMenu>
    </div>
  )
}

export default TableBubbleMenu
