import React, { useState, useRef, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'
import { useFormContext, useFieldArray, useWatch, Controller } from 'react-hook-form'
import { MicrophoneIcon } from '@heroicons/react/24/outline'
import { ArrowRightCircleIcon } from '@heroicons/react/24/solid'
import { MathfieldElement } from 'mathlive'

import CircleSpinner from '@components/CircleSpinner'
import TextEditor from '@components/TextEditor'

import AudioRecorder from './AudioRecorder'

MathfieldElement.soundsDirectory = null

const MessageForm = ({
  noticeMessage,
  visionEnabled,
  transcribeAudio,
  isTranscribing,
  isUploading,
  sendMessage,
  uploadAttachments
}) => {
  const {
    handleSubmit,
    reset,
    setValue,
    control,
    setError,
    formState: { errors },
    clearErrors
  } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attachments'
  })
  const attachments = useWatch({
    control,
    name: 'attachments'
  })
  const messageText = useWatch({
    control,
    name: 'text'
  })

  const [isRecording, setIsRecording] = useState(false)
  const [secondsRecorded, setSecondsRecorded] = useState(0)
  const [fileUploadError, setFileUploadError] = useState(null)

  const textFieldRef = useRef(null)
  const timerRef = useRef(null)
  const counterRef = useRef(null)
  const mediaRecorder = useRef(null)

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
      })
      mediaRecorder.current = new MediaRecorder(stream, { type: 'audio/webm' })
      mediaRecorder.current.ondataavailable = handleDataAvailable
      mediaRecorder.current.start()
      setIsRecording(true)
      timerRef.current = setTimeout(stopRecording, 30000)
      counterRef.current = setInterval(
        () => setSecondsRecorded((elapsed) => elapsed + 1),
        1000
      )
    } catch (err) {
      // Ignore if user denies permission
      if (err.name !== 'NotAllowedError') {
        throw err
      }
    }
  }

  const cancelRecording = () => {
    clearTimeout(timerRef.current)
    clearInterval(counterRef.current)
    setSecondsRecorded(0)
    mediaRecorder.current.ondataavailable = null
    mediaRecorder.current.stop()
    mediaRecorder.current.stream.getTracks().forEach((track) => track.stop())
    setIsRecording(false)
    mediaRecorder.current = null
  }

  const stopRecording = () => {
    clearTimeout(timerRef.current)
    clearInterval(counterRef.current)
    setSecondsRecorded(0)
    mediaRecorder.current.stop()
    mediaRecorder.current.stream.getTracks().forEach((track) => track.stop())
    setIsRecording(false)
    mediaRecorder.current = null
  }

  const handleDataAvailable = async (event) => {
    if (event.data.size > 0) {
      const file = new File([event.data], 'blob.webm', { type: 'audio/webm' })
      transcribeAudio(file)
    }
  }

  const handleFilesAttached = async event => {
    clearErrors()
    setFileUploadError(null)

    const files = Array.from(event.target.files)

    // Check that files are indeed images.
    // File pickers can allow selecting non-image files on some devices.
    if (files.some(file => !file.type.startsWith('image/'))) {
      setFileUploadError('Files must be images.')

      return
    }

    // File size limit
    if (files.some(file => file.size > 10000000)) { // 10mb limit
      setFileUploadError('Files must be less than 10MB in size.')

      return
    }

    // File count limit
    if (files.length > 3) {
      setFileUploadError('You can only upload up to 3 files at a time.')

      return
    }

    if (textFieldRef.current) textFieldRef.current.focus()

    const { attachments = [], errors = [] } = await uploadAttachments(files)

    if (errors.length > 0) {
      setFileUploadError(errors[0].message)
    } else {
      append(attachments)
    }
  }

  const submitForm = ({ text = '', attachments = [] }) => {
    if (text.trim() === '' && attachments.length < 1) {
      setError('text', { required: true, message: 'Message is required' })

      return
    }

    sendMessage({ text, attachmentIds: attachments.map(attachment => attachment.id) })
    reset()
    setValue('text', '')
  }

  const handleKeyDown = event => {
    // Submit on Enter, but and not Shift + Enter
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSubmit(submitForm)()
    }
  }

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current)
      clearInterval(counterRef.current)
    }
  }, [])

  return (
    <>
      <form
        onSubmit={handleSubmit(submitForm)}
        onKeyDown={handleKeyDown}
      >
        <TextEditor.Container
          rememberToolbarConfig
          className={twMerge('md:mx-5 mx-3 drop-shadow-[0_0_10px_rgba(0,0,0,0.15)]', messageText?.length > 1000 && 'h-[40vh]')}
        >
          <If condition={errors.text}>
            <p className='text-red-500 leading-tight shrink-0 p-3 text-sm'>
              {errors.text.message}
            </p>
          </If>

          <If condition={!!fileUploadError}>
            <p className='text-red-500 leading-tight shrink-0 p-3 text-sm'>
              {fileUploadError}
            </p>
          </If>

          <If condition={fields.length > 0}>
            <TextEditor.AttachmentList>
              <For each='attachment' of={fields}>
                <TextEditor.Attachment
                  key={attachment.id}
                  id={attachment.id}
                  filename={attachment.filename}
                  url={attachment.url}
                  remove={() => remove(attachment.id)}
                />
              </For>
            </TextEditor.AttachmentList>
          </If>

          <Choose>
            <When condition={isRecording}>
              <AudioRecorder
                cancelRecording={cancelRecording}
                stopRecording={stopRecording}
                secondsRecorded={secondsRecorded}
              />
            </When>

            <Otherwise>
              <Controller
                name='text'
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextEditor
                    newlineHintEnabled
                    onError={message => setError('text', { message })}
                    id='text'
                    ref={e => {
                      ref(e)
                      textFieldRef.current = e
                    }}
                    {...field}
                  />
                )}
              />

              <TextEditor.Actions>
                <If condition={visionEnabled}>
                  <Choose>
                    <When condition={isUploading}>
                      <CircleSpinner className='p-1 mr-2' />
                    </When>

                    <Otherwise>
                      <TextEditor.FileInput onChange={handleFilesAttached} />
                    </Otherwise>
                  </Choose>
                </If>

                <TextEditor.ToolbarToggle />

                <Choose>
                  <When condition={!!messageText || attachments.length > 0}>
                    <button
                      className='ml-auto motion-safe:animate-[appear_0.3s_linear]'
                      type='submit'
                    >
                      <ArrowRightCircleIcon className='size-8 text-blue-600 hover:text-blue-500' />
                    </button>
                  </When>

                  <When condition={isTranscribing}>
                    <CircleSpinner className='ml-auto p-1' />
                  </When>

                  <Otherwise>
                    <button
                      id='start-recording'
                      onClick={startRecording}
                      className={twMerge('ml-auto flex items-center rounded-full hover:bg-gray-200 p-1', isRecording && 'bg-gray-200')}
                      type='button'
                    >
                      <MicrophoneIcon
                        data-tutorial='start-recording-step'
                        className='h-6 w-6 group-disabled:text-gray-400'
                      />
                    </button>
                  </Otherwise>
                </Choose>
              </TextEditor.Actions>
            </Otherwise>
          </Choose>
        </TextEditor.Container>
      </form>

      <p
        title={noticeMessage}
        className='w-full flex justify-center shrink-0 text-xs pl-3 mb-1 leading-tight truncate ml-auto text-gray-500'
      >
        {noticeMessage}
      </p>

      <div id='math-keyboard-spacer' />
    </>
  )
}

export default MessageForm
