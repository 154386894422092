import { useState } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'
import { CheckIcon, LinkIcon } from '@heroicons/react/24/solid'
import Button from '@components/Button'
import CircleSpinner from '@components/CircleSpinner'
import Table from '@components/Table'
import Spinner from '@components/Spinner'

import { useCurrentUser } from '@contexts/currentUser'
import InviteEducatorsModal from './InviteEducatorsModal'
import EducatorListActions from './EducatorListActions'

const EDUCATORS_QUERY = gql`
  query organization {
    organization {
      educatorProfiles {
        id
        fullName
        email
        authorizationRole
      }
    }
  }
`

const CREATE_OR_GET_INVITATION_URL_MUTATION = gql`
  mutation createOrGetInvitationUrl {
    createOrGetInvitationUrl(input: {}) {
      invitationUrl
      success
      errors
    }
  }
`

const EducatorList = () => {
  const { user: { email: currentUserEmail, authorization_role: currentUserAuthRole } = {} } = useCurrentUser()

  const [showInviteModal, setShowInviteModal] = useState(false)
  const [isUrlCopied, setIsUrlCopied] = useState(false)

  const { isLoading, data: { organization = {} } = {}, refetch } = useQuery({
    queryKey: ['educators'],
    queryFn: async () => await request(EDUCATORS_QUERY)
  })

  const { mutateAsync: createOrGetInvitationUrl, isLoading: isGeneratingInvitationLink } = useMutation({
    mutationFn: async () => request(CREATE_OR_GET_INVITATION_URL_MUTATION)
  })

  const copyInviteUrl = async () => {
    const { createOrGetInvitationUrl: { invitationUrl, success } } = await createOrGetInvitationUrl()

    if (success) {
      navigator.clipboard.writeText(invitationUrl)
      setIsUrlCopied(true)
      setTimeout(() => setIsUrlCopied(false), 1000)
    }
  }

  if (isLoading) {
    return <Spinner className='relative top-0 left-0 flex items-center justify-center w-full h-screen' />
  }

  return (
    <>
      <div className='flex flex-col w-full pb-10'>
        <div className='flex gap-3 ml-auto mb-5 mr-5 md:mr-0'>
          <Button
            variant='outlined'
            theme='light'
            type='button'
            onClick={() => copyInviteUrl()}
            label={
              <span className='flex gap-2 items-center'>
                Copy invite link

                <Choose>
                  <When condition={isGeneratingInvitationLink}>
                    <CircleSpinner className='size-5' />
                  </When>

                  <When condition={isUrlCopied}>
                    <CheckIcon className='size-5' />
                  </When>

                  <Otherwise>
                    <LinkIcon className='size-5' />
                  </Otherwise>
                </Choose>
              </span>
            }
          />

          <Button
            label='Invite educators'
            onClick={() => setShowInviteModal(true)}
          />
        </div>

        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header>Name</Table.Header>
              <Table.Header>Email</Table.Header>
              <Table.Header>Role</Table.Header>
              <Table.Header />
            </Table.Row>
          </Table.Head>

          <Table.Body>
            <For each='educator' of={organization.educatorProfiles}>
              <Table.Row key={educator.id}>
                <Table.Cell>{educator.fullName}</Table.Cell>
                <Table.Cell>{educator.email}</Table.Cell>
                <Table.Cell>{educator.authorizationRole.toLowerCase()}</Table.Cell>
                <Table.Cell className='flex items-center justify-end'>
                  <If condition={currentUserAuthRole === 'admin' && currentUserEmail !== educator.email}>
                    <EducatorListActions educator={educator} refetch={refetch} />
                  </If>
                </Table.Cell>
              </Table.Row>
            </For>
          </Table.Body>
        </Table>
      </div>

      <InviteEducatorsModal
        isOpen={showInviteModal}
        closeModal={() => setShowInviteModal(false)}
      />
    </>
  )
}

export default EducatorList
