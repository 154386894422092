import { useEffect, useState, useCallback } from 'react'
import { PlayIcon, PauseIcon } from '@heroicons/react/24/outline'

import CircleSpinner from '@components/CircleSpinner'
import Tooltip from '@components/Tooltip'

const AudioControls = ({
  messageId,
  audioPlayerRef,
  currentAudioMessageId,
  textToSpeechMutation
}) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasAudioLoaded, setHasAudioLoaded] = useState(false)

  const generateAudio = useCallback(() => {
    setIsLoading(true)
    textToSpeechMutation({ input: { messageId } })
    setHasAudioLoaded(false)
  }, [messageId, textToSpeechMutation])

  const playAudio = () => {
    const audio = audioPlayerRef.current
    if (audio && audio.src !== '') {
      audio.play().catch(error => console.error('Error playing audio:', error))
    }
  }

  const pauseAudio = () => {
    const audio = audioPlayerRef.current
    if (audio) {
      audio.pause()
    }
  }

  const togglePlayPause = useCallback(() => {
    if (currentAudioMessageId === messageId) {
      isPlaying ? pauseAudio() : playAudio()
      setIsPlaying(!isPlaying)
    } else {
      generateAudio()
    }
  }, [isPlaying, hasAudioLoaded, generateAudio, currentAudioMessageId])

  useEffect(() => {
    const audio = audioPlayerRef.current
    if (audio) {
      const handleCanPlay = () => {
        setHasAudioLoaded(true)
        setIsLoading(false)
        playAudio()
        setIsPlaying(true)
      }

      const handleEnded = () => setIsPlaying(false)

      audio.addEventListener('canplay', handleCanPlay)
      audio.addEventListener('ended', handleEnded)

      return () => {
        audio.removeEventListener('canplay', handleCanPlay)
        audio.removeEventListener('ended', handleEnded)
      }
    }
  }, [audioPlayerRef, currentAudioMessageId])

  return (
    <Choose>
      <When condition={isLoading}>
        <button
          className='mb-1 w-fit rounded-md p-1 cursor-not-allowed'
          disabled
        >
          <CircleSpinner className='size-5' />
        </button>
      </When>

      <When condition={isPlaying && currentAudioMessageId === messageId}>
        <button
          aria-label='Pause audio'
          className='mb-1 w-fit rounded-md p-1 cursor-pointer hover:bg-gray-100'
          onClick={togglePlayPause}
        >
          <PauseIcon className='size-5' />
        </button>
      </When>

      <Otherwise>
        <button
          id={`play-audio-${messageId}`}
          aria-label='Play audio'
          className='relative mb-1 w-fit group rounded-md p-1 cursor-pointer hover:bg-gray-100 disabled:cursor-not-allowed'
          onClick={togglePlayPause}
        >
          <PlayIcon
            data-tutorial='chat-text-to-speech-step'
            className='size-5 group-disabled:text-gray-400'
          />
        </button>
        <Tooltip anchorSelect={`#play-audio-${messageId}`} delayShow={500}>AI-generated voice 🤖</Tooltip>
      </Otherwise>
    </Choose>
  )
}

export default AudioControls
