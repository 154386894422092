import { twMerge } from 'tailwind-merge'

const LinkButton = ({ children, className, ...props }) => (
  <button
    className={twMerge("inline-flex text-blue-500 hover:text-blue-600 disabled:cursor-not-allowed disabled:text-gray-400 disabled:hover:text-gray-400", className)}
    {...props}>
    {children}
  </button>
)

export default LinkButton
