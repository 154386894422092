import { useState } from 'react'
import { gql } from 'graphql-request'
import { useQuery, useMutation } from '@tanstack/react-query'
import {
  ChatBubbleLeftEllipsisIcon,
  XMarkIcon,
  LightBulbIcon,
  PencilSquareIcon
} from '@heroicons/react/24/outline'
import { Routes, Route, useParams, useNavigate, Link } from 'react-router-dom'

import { useCurrentUser } from '@contexts/currentUser'
import { request } from '@helpers/graphql'
import Spinner from '@components/Spinner'
import Breadcrumbs from '@components/Breadcrumbs'
import CopyToClipboardButton from '@components/CopyToClipboardButton'
import Button from '@components/Button'
import TabNav from '@components/TabNav'

import Insights from './Insights'
import StudentActivity from './StudentActivity'

const ORGANIZATION_QUERY = gql`
  query organization {
    organization {
      privateStudentChatsEnabled
    }
  }
`

const ASSIGNMENT_QUERY = gql`
  query assignment($id: ID!, $page: Int!, $privateChatsEnabled: Boolean!) {
    node(id: $id) {
      ... on TutorAssignment {
        id
        classroom {
          id
          name
        }
        closed
        createdAt
        closedAt
        summaryStatistics {
          positiveSentimentCount
          neutralSentimentCount
          negativeSentimentCount
          curiousLearningStatusCount
          neutralLearningStatusCount
          confusedLearningStatusCount
          totalChatCount
          totalEngagementCount
        }
        chats(page: $page, perPage: 50) @skip(if: $privateChatsEnabled) {
          pagesCount
          nodesCount
          nodes {
            id
            createdAt
            profile {
              fullName
            }
            learningStatus
            sentiment
            flaggedByModeration
            messages {
              nodesCount
            }
          }
        }
        tutor {
          id
          name
        }
      }
    }
  }
`

const UPDATE_ASSIGNMENT_MUTATION = gql`
  mutation updateTutorAssignment($input: UpdateTutorAssignmentInput!) {
    updateTutorAssignment(input: $input) {
      assignment {
        id
      }
    }
  }
`

const SessionDetails = () => {
  const { classroomId, tutorId, id: assignmentId } = useParams()
  const { user: { organization: { features } } } = useCurrentUser()
  const navigate = useNavigate()
  const [page, setPage] = useState(1)

  const insightsEnabled = features.includes('tutor_insights')

  const { data: { organization = {} } = {}, isFetched } = useQuery({
    queryKey: ['organization'],
    queryFn: async () => request(ORGANIZATION_QUERY)
  })

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['assignment', assignmentId, page],
    queryFn: async () => request(ASSIGNMENT_QUERY, { id: assignmentId, page, privateChatsEnabled: organization.privateStudentChatsEnabled }),
    refetchInterval: 120000, // Refetch every 2 minutes
    enabled: isFetched
  })

  const { mutate: update, isLoading: isUpdating } = useMutation({
    mutationFn: async data => request(UPDATE_ASSIGNMENT_MUTATION, { input: { assignmentId, ...data } }),
    onSuccess: () => {
      refetch()
      navigate('insights')
    }
  })

  const endSession = () => {
    update({ closed: true })
  }

  const assignment = data?.node || {}
  const chats = assignment?.chats?.nodes || []
  const classroom = assignment?.classroom || {}
  const tutor = assignment?.tutor || {}
  const summaryStatistics = assignment?.summaryStatistics || {}

  if (isLoading || !isFetched) {
    return <Spinner className='flex items-center justify-center w-full mt-32' />
  }

  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.Link label='Classrooms' to='/educators/classrooms' />
        <Breadcrumbs.Link label={classroom.name} to={`/educators/classrooms/${classroomId}`} />
        <Breadcrumbs.Text label={tutor.name} />

        <Link to={`/educators/tutors/${tutorId}/settings`} className='flex items-center rounded-full hover:bg-gray-200 p-1 ml-1'>
          <PencilSquareIcon className='size-5' />
        </Link>
      </Breadcrumbs>

      <TabNav>
        <TabNav.Link
          to=''
          end
          label='Student activity'
          icon={<ChatBubbleLeftEllipsisIcon className='h-6 w-6 mr-1' />}
        />

        <If condition={insightsEnabled}>
          <TabNav.Link
            to='insights'
            label={
              <>
                Insights

                <div className='inline ml-3 text-xs bg-transparent text-blue-600 border-blue-600 border rounded-full px-2 py-1 font-normal'>
                  Experimental
                </div>
              </>
            }
            icon={<LightBulbIcon className='h-6 w-6 mr-1' />}
          />
        </If>

        <div className='flex gap-3 ml-auto mr-5'>
          <If condition={!assignment.closed}>

            <CopyToClipboardButton
              className='py-1'
              value={`${window.location.origin}/students/tutors/assignments/${assignmentId}`}
              label='Copy student link'
            />


            <Button
              className='py-1 '
              disabled={isUpdating}
              theme='error'
              variant='outlined'
              label={isUpdating ? 'Updating...' : <span className='flex items-center gap-2'>End session<XMarkIcon className='size-6' /></span>}
              onClick={endSession}
            />
          </If>
        </div>
      </TabNav>

      <div className='w-full pt-5 px-3'>
        <Routes>
          <Route
            index
            element={
              <StudentActivity
                classroomName={classroom.name}
                assignmentCreatedAt={assignment.createdAt}
                assignmentClosed={assignment.closed}
                chats={chats}
                chatsPagesCount={chats?.pagesCount || 0}
                privateStudentChatsEnabled={organization.privateStudentChatsEnabled}
                summaryStatistics={summaryStatistics}
              />
            }
          />

          <If condition={insightsEnabled}>
            <Route
              path='insights'
              element={
                <Insights
                  assignmentId={assignmentId}
                  assignmentClosed={assignment.closed}
                  assignmentClosedAt={assignment.closedAt}
                  classroomId={classroom.id}
                />
              }
            />
          </If>
        </Routes>
      </div>
    </>
  )
}

export default SessionDetails
