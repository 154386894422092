import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'

import { formatTimestamp } from '@helpers/format'
import Spinner from '@components/Spinner'
import Image from '@components/Image'

import DeleteChatModal from './DeleteChatModal'

const ChatList = ({ pages, selectedChatId, setSelectedChatId, createChat, isCreating, hasNextPage, fetchNextPage, imageUrl, refetch }) => {
  const [chatIdToDelete, setChatIdToDelete] = useState(null)

  return (
    <div className='flex flex-col md:w-1/5 sm:w-1/3 w-full h-full overflow-y-auto p-5 shrink-0 border-r'>
      <If condition={imageUrl}>
        <Image
          className='object-cover w-full h-[200px] rounded-lg mb-3'
          src={imageUrl}
          placeholder={<div className='rounded-lg h-[200px] w-full bg-gray-200 animate-pulse border shadow mb-3' />}
        />
      </If>

      <button
        onClick={createChat}
        className='text-purple-500 flex mb-3'
      >
        <Choose>
          <When condition={isCreating}>
            <Spinner className='self-center' />
          </When>

          <Otherwise>
            <PlusIcon className='h-6 w-6 mr-2' />
            New chat
          </Otherwise>
        </Choose>
      </button>

      <For each='page' of={pages}>
        <For each='chat' of={page.chats.nodes}>
          <div className={twMerge('flex items-center justify-between bg-white shadow rounded-lg w-full mb-3 border-2 border-gray-200', chat.id === selectedChatId && 'border-purple-500')}>
            <button className='p-3 h-full' onClick={() => setSelectedChatId(chat.id)}>
              {formatTimestamp(chat.createdAt)}
            </button>

            <If condition={chat.id === selectedChatId}>
              <button className='p-1 mr-2 rounded hover:bg-gray-100'>
                <TrashIcon
                  data-tutorial='delete-chat-step'
                  onClick={() => setChatIdToDelete(chat.id)}
                  className='h-5 w-5 text-gray-500'
                />
              </button>
            </If>
          </div>
        </For>
      </For>

      <If condition={hasNextPage}>
        <button
          onClick={fetchNextPage}
          className='text-purple-500 self-center'
        >
          Load more
        </button>
      </If>

      <DeleteChatModal
        chatId={chatIdToDelete}
        isOpen={!!chatIdToDelete}
        selectedChatId={selectedChatId}
        setSelectedChatId={setSelectedChatId}
        closeModal={() => setChatIdToDelete(null)}
        refetch={refetch}
      />
    </div>
  )
}

export default ChatList
