import { twMerge } from 'tailwind-merge'

const Label = ({ title, children, className, required = false, ...props }) => (
  <label {...props} className={twMerge('font-semibold', className)}>
    <span>{title}{required && <span className="text-grey-300">&nbsp;*</span>}</span>
    {children}
  </label>
)

export default Label
