import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm } from 'react-hook-form'
import { CheckIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Checkbox from '@components/Checkbox'

const UPDATE_TUTOR_MUTATION = gql`
  mutation updateTutor($input: UpdateInput!) {
    updateTutor(input: $input) {
      tutor {
        id
        name
        createdAt
        description
        summary
        socratic
        suggestionsEnabled
        stemModeEnabled
        knowledgeBaseEnabled
        visibility
        approvedForCommunity
        image {
          url
        }
        userName
        organizationName
      }
    }
  }
`

const Settings = ({ tutorId, approvedForCommunity, refetch }) => {
  const { register, handleSubmit } = useForm({ mode: 'onTouched', defaultValues: { approvedForCommunity } })

  const { mutate: updateTutor, isLoading: isUpdating, isSuccess } = useMutation({
    mutationFn: async data => request(UPDATE_TUTOR_MUTATION, { input: { ...data, id: tutorId } }),
    onSuccess: () => refetch()
  })

  const onSubmit = data => updateTutor(data)

  return (

    <div className='w-auto px-5 py-3'>
      <form
        className='flex flex-col'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='w-full mb-3'>
          <Checkbox
            label='Approved for community'
            defaultChecked={approvedForCommunity}
            {...register('approvedForCommunity')}
          />
        </div>

        <div className='ml-auto'>
          <If condition={isSuccess}>
            <CheckIcon className='mr-2 inline-block h-5 w-5' />
          </If>

          <Button
            className='mb-5 w-fit'
            theme='primary'
            disabled={isUpdating}
            type='submit'
            label={isUpdating ? 'Saving...' : 'Save'}
          />
        </div>
      </form>
    </div>
  )
}

export default Settings
