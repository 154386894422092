import Card from '@components/Card'

const ActiveSessionNoInsights = () => (
  <Card className='w-auto flex flex-col items-center justify-center p-10'>
    <img src='/swingset.svg' alt='Student reading' className='w-[120px] self-center mb-5' />
    <h4 className='text-2xl font-semibold'>No insights available just yet</h4>
    <p>End your session to see your insights here.</p>
  </Card>
)

export default ActiveSessionNoInsights
