import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'
import { useMutation } from '@tanstack/react-query'
import { ArrowPathIcon, SparklesIcon } from '@heroicons/react/24/outline'

import Button from '@components/Button'
import CircleSpinner from '@components/CircleSpinner'
import Image from '@components/Image'
import Modal from '@components/Modal'
import SecureMarkdown from '@components/SecureMarkdown'
import TextEditor from '@components/TextEditor'

const PREVIEW_FREE_RESPONSE_AUTOMARKING_MUTATION = gql`
  mutation PreviewFreeResponseAutoMarking($input: PreviewFreeResponseAutoMarkingInput!) {
    previewFreeResponseAutoMarking(input: $input) {
      marks
      feedback
      reasoning

      errors {
        message
      }
    }
  }
`

const UPLOAD_ATTACHMENTS_MUTATION = gql`
  mutation uploadEducatorProjectAttachments($input: UploadEducatorProjectAttachmentsInput!) {
    uploadEducatorProjectAttachments(input: $input) {
      attachments {
        id
        url
        filename
      }
      errors {
        message
      }
    }
  }
`

const PreviewFreeResponseQuestionModal = ({ blockId, getValues, closeModal, isOpen }) => {
  const { question, markAllocation, markScheme } = getValues()

  const {
    mutateAsync: previewFreeResponseAutoMarking,
    isLoading: isMarking,
    isSuccess: isMarkingComplete,
    data: { previewFreeResponseAutoMarking: { marks, feedback, reasoning } = {} } = {},
    reset: resetMarking
  } = useMutation({
    mutationFn: async variables => request(PREVIEW_FREE_RESPONSE_AUTOMARKING_MUTATION, { input: variables })
  })

  const { mutateAsync: uploadAttachments, isLoading: isUploading } = useMutation({
    mutationFn: async attachments => request(UPLOAD_ATTACHMENTS_MUTATION, { input: { attachments } })
  })

  const { handleSubmit, formState: { errors }, control, reset: resetForm, setError, clearErrors, watch } = useForm({
    mode: 'onTouched'
  })

  const { fields, append, remove: removeAttachments } = useFieldArray({
    control,
    name: 'attachments'
  })

  const submittedAnswer = watch('answer')
  const attachments = watch('attachments')

  const submit = ({ answer, attachments = [] }) => {
    const attachmentIds = attachments.map(attachment => attachment.id)
    previewFreeResponseAutoMarking({id: blockId, answer, attachmentIds, question, markAllocation, markScheme})
  }

  const handleFormReset = () => {
    resetMarking()
    resetForm()
    removeAttachments()
  }

  const handleFilesAttached = async event => {
    clearErrors()

    const files = Array.from(event.target.files)

    // Check that files are indeed images.
    // File pickers can allow selecting non-image files on some devices.
    if (files.some(file => !file.type.startsWith('image/'))) {
      setError('attachments', { message: 'Files must be images.' })

      return
    }

    // File size limit
    if (files.some(file => file.size > 10000000)) { // 10mb limit
      setError('attachments', { message: 'Files must be less than 10MB in size.' })

      return
    }

    // File count limit
    if (files.length > 3) {
      setError('attachments', { message: 'You can only upload up to 3 files at a time.' })

      return
    }

    const { uploadEducatorProjectAttachments: { attachments = [], errors = [] } } = await uploadAttachments(files)

    if (errors.length > 0) {
      setError('attachments', { message: errors[0].message })
    } else {
      append(attachments)
    }
  }

  const handleCloseModal = () => {
    handleFormReset()
    closeModal()
  }

  return (
    <Modal
      size='md'
      isOpen={isOpen}
      onClose={handleCloseModal}
    >
      <form
        onSubmit={handleSubmit(submit)}
        className='w-full flex flex-col px-3 py-5 gap-5'
      >
        <SecureMarkdown content={question} />

        <Choose>
          <When condition={isMarkingComplete}>
            <div className='bg-gray-200 p-3 rounded-lg'>
              <SecureMarkdown content={submittedAnswer} />

              <If condition={attachments.length > 0}>
                <For each="attachment" of={attachments}>
                  <Image
                    className='object-contain h-[200px] rounded-md'
                    src={attachment.url}
                    placeholder={<div className='rounded-md h-[200px] w-1/3 bg-gray-200 animate-pulse mt-3' />}
                  />
                </For>
              </If>
            </div>

            <div>
              <h3 className='text-lg font-semibold'>Mark</h3>
              <div className='w-fit rounded-lg leading-tight'>
                <span className='text-lg font-semibold'>{marks} / {markAllocation}</span>
              </div>
            </div>

            <div className='flex flex-col bg-purple-100 p-3 rounded-lg leading-tight'>
              <div className='flex items-center mb-1'>
                <SparklesIcon className='size-5 block mr-2 shrink-0' />
                <h3 className='text-lg font-semibold'>AI reasoning</h3>
              </div>

              <SecureMarkdown content={reasoning} />
            </div>

            <div>
              <h3 className='text-lg font-semibold'>Student feedback</h3>
              <div className='flex flex-col rounded-lg leading-tight'>
                <SecureMarkdown content={feedback} />
              </div>
            </div>

            <Button
              className='ml-auto'
              type='button'
              variant='outlined'
              theme='light'
              onClick={() => handleFormReset()}
              label={<span className='flex gap-2'><ArrowPathIcon className='h-5 w-5' /> Reset</span>}
            />
          </When>

          <Otherwise>
            <TextEditor.Container>
              <If condition={fields.length > 0}>
                <TextEditor.AttachmentList>
                  <For each='attachment' of={fields}>
                    <TextEditor.Attachment
                      key={attachment.id}
                      id={attachment.id}
                      filename={attachment.filename}
                      url={attachment.url}
                      remove={() => removeAttachments(attachment.id)}
                    />
                  </For>
                </TextEditor.AttachmentList>
              </If>

              <Controller
                name='answer'
                rules={{
                  validate: value => (value || fields.length > 0) ? null : 'Answer or attachment is required'
                }}
                control={control}
                render={({ field }) => (
                  <TextEditor
                    className='min-h-[200px]'
                    {...field}
                  />
                )}
              />

              <TextEditor.Actions>
                <Choose>
                  <When condition={isUploading}>
                    <CircleSpinner className='p-1 mr-2' />
                  </When>

                  <Otherwise>
                    <TextEditor.FileInput
                      onChange={handleFilesAttached}
                    />
                  </Otherwise>
                </Choose>

                <TextEditor.ToolbarToggle />
              </TextEditor.Actions>
            </TextEditor.Container>

            <If condition={errors.answer}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.answer.message}</p>
            </If>

            <If condition={errors.attachments}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.attachments.message}</p>
            </If>

            <Choose>
              <When condition={isMarking}>
                <span className='flex ml-auto items-center gap-2 leading-none'><CircleSpinner className='h-5 w-5' /> Marking</span>
              </When>

              <Otherwise>
                <Button
                  className='ml-auto'
                  type='submit'
                  disabled={isMarking}
                  label={'Submit'}
                />
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </form>
    </Modal>
  )
}

export default PreviewFreeResponseQuestionModal
