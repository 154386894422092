import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { Navigate, useSearchParams, Link } from 'react-router-dom'
import { setUser as setSentryUser } from '@sentry/react'
import { ArrowLongRightIcon } from '@heroicons/react/20/solid'
import ReCAPTCHA from 'react-google-recaptcha'

import { useCurrentUser } from '@contexts/currentUser'
import { useAnalytics } from '@contexts/analytics'
import { client } from '@helpers/api'
import Button from '@components/Button'
import Card from '@components/Card'
import TextInput from '@components/TextInput'

const EducatorAcceptInvitation = () => {
  const [searchParams] = useSearchParams()
  const { identify } = useAnalytics()
  const token = searchParams.get('invitation_token')

  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onTouched' })
  const { user: { signedIn }, setUser } = useCurrentUser()

  const { mutateAsync: signUp, isError, isLoading } = useMutation({
    mutationFn: async variables => await client.post('/educator/profiles', variables),
    onSuccess: response => {
      setUser({ loading: false, signedIn: true, ...response.data })
      setSentryUser({ id: response.data.id, email: response.data.email, role: response.data.role })
      identify(response.data.id)
    }
  })

  const submit = data => {
    const captcha = captchaRef.current.getValue()

    if (!captcha) {
      setCaptchaError('Please verify that you are not a robot.')
      return
    }

    signUp({ ...data, token, captcha })
  }

  const captchaRef = useRef(null)
  const [captchaError, setCaptchaError] = useState('')

  if (signedIn) {
    return <Navigate to='/educators' />
  }

  const cookies = document.cookie.split('; ')
  const csrfToken = cookies.find(cookie => cookie.startsWith('CSRF-TOKEN=')).split('=')[1]

  return (
    <Card className='w-[400px] self-center p-5 mt-10'>
      <h1 className='text-2xl font-bold'>Educator sign up</h1>

      <form method='post' action={`${import.meta.env.VITE_API_URL}/users/auth/google_app?intent=join_organization&token=${token}`}>
        <input type='hidden' name='authenticity_token' value={csrfToken} />

        <button
          type='submit'
          className='w-full my-3 flex items-center rounded-md px-3.5 py-2.5 text-base shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-gray-600'
        >
          <img
            className='w-5 mr-5'
            src='google-logo.svg'
          />

          <span>Sign up with Google</span>

          <ArrowLongRightIcon className='ml-auto w-6' />
        </button>
      </form>

      <form method='post' action={`${import.meta.env.VITE_API_URL}/users/auth/microsoft_app?intent=join_organization&token=${token}`}>
        <input type='hidden' name='authenticity_token' value={csrfToken} />

        <button
          type='submit'
          className='w-full mt-5 flex items-center rounded-md px-3.5 py-2.5 text-base shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-gray-600'
        >
          <img
            className='w-5 mr-5'
            src='microsoft-logo.svg'
          />

          <span>Sign up with Microsoft</span>

          <ArrowLongRightIcon className='ml-auto w-6' />
        </button>
      </form>

      <div className='flex items-center'>
        <div className='border-b-2 w-full' />
        <span className='p-3'>Or</span>
        <div className='border-b-2 w-full' />
      </div>

      <form className='flex flex-col space-y-4 mt-3' onSubmit={handleSubmit(submit)}>
        <If condition={isError || searchParams.get('error')}>
          <div className='text-sm mt-3 text-red-500 font-semibold' role='alert'>
            <p>Oops! Your invitation doesn't seem to be working.</p>
            <p>You may have used it already, or it expired.</p>
          </div>
        </If>

        <div>
          <TextInput
            id='email'
            label='Email'
            placeholder='name@school-email.com'
            required
            {...register('email', {
              required: 'Email is required',
              pattern: { value: /(.+)@(.+){2,}\.(.+){2,}/i, message: 'Email must be valid' },
              validate: value => !['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 'live.com', 'icloud.com'].some(domain => value.includes(domain)) || 'Please use your school email address'
            })}
          />
          <If condition={errors.email}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.email.message}</p>
          </If>
        </div>

        <div>
          <TextInput
            id='password'
            label='Password'
            type='password'
            placeholder='At least 8 characters'
            required
            {...register('password', { required: 'Password is required', minLength: { value: 8, message: 'Password must be at least 8 characters' } })}
          />
          <If condition={errors.password}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.password.message}</p>
          </If>
        </div>

        <div>
          <ReCAPTCHA
            sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
            ref={captchaRef}
          />
          <If condition={captchaError}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{captchaError}</p>
          </If>
        </div>

        <Button type='submit' label={isLoading ? 'Signing up...' : 'Sign up'} disabled={isLoading} />

        <p className='leading-tight pt-5'>
          By continuing, you agree to Mindjoy's <Link className='text-blue-500' to='/terms'>Terms of Service</Link> and <Link className='text-blue-500' to='/privacy'>Privacy Policy</Link>.
        </p>
      </form>
    </Card>
  )
}

export default EducatorAcceptInvitation
