import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { DocumentDuplicateIcon, CheckIcon } from '@heroicons/react/24/outline'

import { formatShortTimestamp } from '@helpers/format'
import SecureMarkdown from '@components/SecureMarkdown'
import Image from '@components/Image'

import AudioControls from './AudioControls'

const Message = ({
  id,
  status,
  from,
  text,
  sourceName,
  sourceLink,
  createdAt,
  audioPlayerRef,
  currentAudioMessageId,
  textToSpeechMutation,
  trackAudioPlayed,
  attachments = []
}) => {
  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text)
  }

  return (
    <div className={twMerge('flex flex-col max-w-[75%] mb-3', from === 'USER' ? 'self-end' : 'self-start')}>
      <Choose>
        <When condition={from === 'ASSISTANT'}>
          <div className='flex'>
            <button
              aria-label='Copy text to clipboard'
              className='mb-1 w-fit rounded-md p-1 cursor-pointer hover:bg-gray-100'
              onClick={() => {
                copyToClipboard()
                setIsCopied(true)
                setTimeout(() => setIsCopied(false), 1000)
              }}
            >
              {isCopied ? <CheckIcon className='h-5 w-5' /> : <DocumentDuplicateIcon data-tutorial='copy-message-step' className='h-5 w-5' />}
            </button>

            <AudioControls
              messageId={id}
              audioPlayerRef={audioPlayerRef}
              currentAudioMessageId={currentAudioMessageId}
              textToSpeechMutation={textToSpeechMutation}
            />
          </div>

          <div className='w-full flex flex-row self-end'>
            <div className='w-full border shadow rounded-lg p-2 overflow-x-auto bg-blue-100 border-blue-900'>
              <SecureMarkdown content={text} />
            </div>
          </div>

          <If condition={sourceName && sourceLink}>
            <div className='flex justify-between'>
              <a href={sourceLink} className='mt-1 text-blue-500 text-sm'>
                Computed by {sourceName}
              </a>
            </div>
          </If>

          <small className='self-end mt-1 ml-auto'>{formatShortTimestamp(createdAt)}</small>
        </When>

        <Otherwise>
          <If condition={attachments.length > 0}>
            <div className='flex gap-3 mb-3'>
              <For each='attachment' of={attachments}>
                <Image
                  className='ml-auto object-cover w-auto max-h-[200px] rounded-lg'
                  placeholder={<div className='w-[300px] h-[200px] bg-gray-200 animate-pulse rounded-lg' />}
                  key={attachment.filename}
                  src={attachment.url}
                  alt={attachment.filename}
                />
              </For>
            </div>
          </If>

          <If condition={text}>
            <div className='w-auto ml-auto flex flex-row self-end'>
              <div className={twMerge(
                'w-auto border shadow rounded-lg p-2 overflow-x-auto',
                status === 'MODERATION' ? 'bg-orange-100 border-gray-500' : 'bg-white border-gray-500'
              )}
              >
                <SecureMarkdown content={text} />
              </div>
            </div>
          </If>

          <If condition={status === 'MODERATION'}>
            <small className='self-end mt-1'>Sorry, I can't respond to this message</small>
          </If>

          <small className='self-end mt-1 ml-auto'>{formatShortTimestamp(createdAt)}</small>
        </Otherwise>
      </Choose>
    </div>
  )
}

export default Message
