import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { buttonTheme } from './Button'

const ButtonLink = ({
  label,
  onClick,
  className,
  variant = 'default',
  theme = 'primary',
  to
}) => (
  <Link
    to={to}
    onClick={onClick}
    className={
      twMerge(
        "rounded-md bg-blue-600 px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600",
        "text-center disabled:cursor-not-allowed",
        buttonTheme(variant, theme),
        className
      )}>
    {label}
  </Link>
)

export default ButtonLink
