import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeRaw from 'rehype-raw'
import rehypeKatex from 'rehype-katex'
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize'
import { truncate } from 'lodash'
import 'katex/dist/katex.min.css'

import { CodeBlock } from '@components/Markdown'

const SecureMarkdown = ({ content, maxCharCount, className }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleIsExpanded = () => setIsExpanded(!isExpanded)

  // Replace \(...\) with $...$ and \[...\] with $$...$$
  const formattedContent = content
    ? content
      .replace(/\\\((.*?)\\\)/gs, (_, expr) => `$${expr}$`)
      .replace(/\\\[(.*?)\\\]/gs, (_, expr) => `$$${expr}$$`)
    : ''

  const truncatedContent = truncate(formattedContent, { length: maxCharCount })

  return (
    <div className={twMerge('relative', className)}>
      <ReactMarkdown
        linkTarget='_blank'
        className='markdown-content inline [&>*:last-child]:inline'
        remarkPlugins={[remarkGfm, remarkMath]}
        rehypePlugins={[
          rehypeRaw,
          [rehypeSanitize, {
            ...defaultSchema,
            attributes: {
              ...defaultSchema.attributes,
              // The `language-*` regex is allowed by default.
              span: [['className', 'math-inline', 'math-display']],
              div: [['className', 'math-inline', 'math-display']],
              code: [['className', 'math-inline', 'math-display']]
            }
          }],
          rehypeKatex
        ]}
        components={{ code: CodeBlock }}
      >
        <Choose>
          <When condition={maxCharCount && !isExpanded}>
            {truncatedContent}
          </When>

          <Otherwise>
            {formattedContent}
          </Otherwise>
        </Choose>
      </ReactMarkdown>

      <If condition={maxCharCount && content.length > maxCharCount && !isExpanded}>
        <button className='text-blue-500 cursor-pointer inline pl-1 bg-white' onClick={toggleIsExpanded}>
          more
        </button>
      </If>
    </div>
  )
}

export default SecureMarkdown
