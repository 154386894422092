import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Card from '@components/Card'
import TextInput from '@components/TextInput'
import TextArea from '@components/TextArea'
import Select from '@components/Select'

const UPDATE_LESSON_PLAN_MUTATION = gql`
  mutation updateLessonPlan($updateLessonPlanInput: UpdateLessonPlanInput!) {
    updateLessonPlan(input: $updateLessonPlanInput) {
      lessonPlan {
        status
        step
      }
    }
  }
`

const OrganizeStep = ({ lessonPlanId, startPolling, ...props }) => {
  const queryClient = useQueryClient()
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: props
  })

  const { mutate: updateLessonPlan } = useMutation({
    mutationFn: async variables => request(UPDATE_LESSON_PLAN_MUTATION, variables),
    onSuccess: () => startPolling()
  })

  const next = data => {
    updateLessonPlan({
      updateLessonPlanInput: {
        ...data,
        id: lessonPlanId,
        step: "PRIORITIZE",
        grade: parseInt(data.grade),
        duration: parseInt(data.duration)
      }
    })

    queryClient.setQueryData(['lessonPlan', lessonPlanId], old => ({
      node: {
        ...old.node,
        status: "PROCESSING",
        step: "PRIORITIZE"
      }
    }))
  }

  return (
    <Card>
      <form onSubmit={handleSubmit(next)} className="flex flex-col m-5">
        <div className="flex md:flex-row flex-col md:space-x-5">
          <div className="w-full mb-5">
            <Select
              id="grade"
              label="Grade"
              className="w-full"
              required
              {...register('grade', { required: 'Grade is required' })}
            >
              <Select.Option value="">Select a grade</Select.Option>

              <For each="grade" of={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}>
                <Select.Option key={grade} value={grade}>Grade {grade}</Select.Option>
              </For>
            </Select>
            <If condition={errors.grade}>
              <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.grade.message}</p>
            </If>
          </div>

          <div className="w-full mb-5">
            <Select
              id="duration"
              label="Duration"
              className="w-full"
              required
              {...register('duration', { required: 'Duration is required' })}
            >
              <Select.Option value="">Select a duration</Select.Option>
              <Select.Option value="30">30 minutes</Select.Option>
              <Select.Option value="45">45 minutes</Select.Option>
              <Select.Option value="60">1 hour</Select.Option>
              <Select.Option value="90">1 hour 30 minutes</Select.Option>
              <Select.Option value="120">2 hours</Select.Option>
            </Select>
            <If condition={errors.duration}>
              <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.duration.message}</p>
            </If>
          </div>

          <div className="w-full mb-5">
            <Select
              id="lesson-type"
              label="Lesson type"
              className="w-full"
              required
              {...register('methodology', { required: 'Lesson type is required' })}
            >
              <Select.Option value="">Select a lesson type</Select.Option>
              <Select.Option value="PROJECT_BASED">Project-based lesson</Select.Option>
              <Select.Option value="TRADITIONAL">Traditional lesson</Select.Option>
            </Select>
            <If condition={errors.methodology}>
              <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.methodology.message}</p>
            </If>
          </div>
        </div>

        <div className="flex md:flex-row flex-col md:space-x-5">
          <div className="w-full mb-5">
            <TextInput
              id="subject"
              label="Subject"
              placeholder={`e.g. "science", or try two subjects, "science and biology"`}
              required
              {...register('subject', { required: "Subject is required" })} />
            <If condition={errors.subject}>
              <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.subject.message}</p>
            </If>
          </div>

          <div className="w-full mb-5">
            <TextInput
              id="topic"
              label="Topic"
              placeholder="e.g. Chickens in space"
              required
              {...register('topic', { required: "Topic is required" })} />
            <If condition={errors.topic}>
              <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.topic.message}</p>
            </If>
          </div>
        </div>

        <div className="mb-5">
          <TextArea
            id="objective"
            label="Objectives"
            placeholder="e.g. Explain the principles of flight and how it affects chickens. Create a visual representation of chickens in space. Plot a chicken in space flight trajectory."
            required
            {...register('objective', { required: "Objectives are required" })} />
          <If condition={errors.objective}>
            <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.objective.message}</p>
          </If>
        </div>

        <Button label="Next" type="submit" className="w-fit ml-auto" />
      </form>
    </Card>
  )
}

export default OrganizeStep
