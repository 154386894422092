import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, Link } from 'react-router-dom'
import { gql } from 'graphql-request'
import { truncate } from 'lodash'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Pagination from '@components/Pagination'
import PageHeading from '@components/PageHeading'
import Notification from '@components/Notification'

const FEEDBACK_COMMENTS_QUERY = gql`
  query feedbackComments($page: Int) {
    feedbackComments(page: $page, perPage: 10) {
      nodes {
        id
        category
        studentName
        overallContext
        step
      }
      nodesCount
      pagesCount
    }
  }
`

const commentCategoryMap = {
  REPORT: {
    label: 'Report comment'
  },
  SUBJECT: {
    label: 'Subject comment'
  },
  TASK: {
    label: 'Task-specific comment'
  },
}

const FeedbackCommentList = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState(1)

  const { isLoading, data: { feedbackComments: { nodes: feedbackComments = [], nodesCount, pagesCount } = {} } = {} } = useQuery({
    queryKey: ['feedbackComments', page],
    queryFn: async () => request(FEEDBACK_COMMENTS_QUERY, { page }),
    keepPreviousData: true
  })

  return (
    <>
      <PageHeading title="Comments" />

      <Notification className="w-100 m-5 font-normal flex items-center" theme="primary">
        <InformationCircleIcon className="w-5 h-5 mr-2 text-blue-800" />
        <strong>
          The comments tool will be retired in the near future.
        </strong>

        &nbsp;We recommend using AI tutors to create comments instead,&nbsp;<a className="font-semibold text-blue-500 underline" href="mailto:support@mindjoy.com">let us know</a>&nbsp;if you need help with the switch.
      </Notification>

      <div className="mt-3 md:mx-5 flex flex-col">
        <Choose>
          <When condition={isLoading}>
            <Spinner className="relative top-0 left-0 flex items-center justify-center w-full h-screen" />
          </When>

          <Otherwise>
            <div className="overflow-x-scroll w-full p-0.5">
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.Header width="20%">Comment type</Table.Header>
                    <Table.Header width="20%">Student Name</Table.Header>
                    <Table.Header width="55%">Overall context</Table.Header>
                    <Table.Header width="5%"></Table.Header>
                  </Table.Row>
                </Table.Head>

                <Table.Body>
                  <If condition={feedbackComments.length < 1}>
                    <Table.Row>
                      <Table.Cell colSpan="3">You don't have any comments yet, go ahead and create one 😀</Table.Cell>
                    </Table.Row>
                  </If>

                  <For each="feedbackComment" of={feedbackComments}>
                    <Table.Row key={feedbackComment.id}>
                      <Table.Cell>
                        <Link className="block" to={feedbackComment.id}>
                          <Choose>
                            <When condition={feedbackComment.category}>
                              {commentCategoryMap[feedbackComment.category].label}
                            </When>
                            <Otherwise>
                              Untitled comment
                            </Otherwise>
                          </Choose>
                        </Link>
                      </Table.Cell>
                      <Table.Cell><Link className="block" to={feedbackComment.id}>{feedbackComment.studentName}</Link></Table.Cell>
                      <Table.Cell>
                        <Link to={feedbackComment.id}>{truncate(feedbackComment.overallContext, { length: 80 })}</Link>
                      </Table.Cell>
                      <Table.Cell><Link className="block text-blue-500" to={feedbackComment.id}>edit</Link></Table.Cell>
                    </Table.Row>
                  </For>
                </Table.Body>
              </Table>
            </div>

            <Pagination page={page} pagesCount={pagesCount} setPage={setPage} />
          </Otherwise>
        </Choose>
      </div>
    </>
  )
}

export default FeedbackCommentList
