import { ArrowRightCircleIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/24/solid'

const OnboardingChecklistItem = ({ completed, label }) => (
  <li className='flex items-center whitespace-nowrap'>
    <Choose>
      <When condition={completed}>
        <CheckCircleIcon className='flex-shrink-0 flex-grow-0 w-5 h-5 mr-1 fill-green-600' />
      </When>

      <Otherwise>
        <ArrowRightCircleIcon className='flex-shrink-0 flex-grow-0 w-5 h-5 mr-1' />
      </Otherwise>
    </Choose>

    <span className='leading-none'>{label}</span>
  </li>
)

export default OnboardingChecklistItem
