import { twMerge } from 'tailwind-merge'
import { CheckIcon } from '@heroicons/react/24/outline'

import Badge from '@components/Badge'

const stepMap = {
  ORGANIZE: {
    number: 1,
    label: 'Organize'
  },
  REFLECT: {
    number: 2,
    label: 'Reflect'
  },
  COMMENT: {
    number: 3,
    label: 'Comment'
  }
}

const StepBanner = ({ currentStep, navigateToStep }) => (
  <div className="w-full flex flex-row mb-3 items-center justify-between">
    <For each="step" index="index" of={Object.keys(stepMap)}>
      <button
        onClick={() => navigateToStep(step)}
        disabled={index + 1 >= stepMap[currentStep].number}
        className="flex flex-col items-center">
        <Badge className="w-fit" theme={step === currentStep ? 'primary' : 'light'}>
          <div className="flex items-center justify-center w-6 h-6 text-center text-xl font-semibold">
            {index + 1 < stepMap[currentStep].number ? <CheckIcon className="h-6 w-6" /> : stepMap[step].number}
          </div>
        </Badge>

        <span className={twMerge("text-sm mt-1", step === currentStep ? 'font-semibold' : '')}>{stepMap[step].label}</span>
      </button>

      <If condition={index < Object.keys(stepMap).length - 1}>
        <div className="w-full h-2 bg-gray-200 rounded-lg mx-5 mb-5" />
      </If>
    </For>
  </div>
)

export default StepBanner
