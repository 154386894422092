import Card from '@components/Card'

const NoResults = ({ classroomSelected }) => (
  <Card className='w-auto flex flex-col items-center justify-center p-10'>
    <img src='/swingset.svg' alt='Student reading' className='w-[120px] self-center mb-5' />
    <h4 className='text-2xl font-semibold'>No submissions just yet</h4>
    <If condition={!classroomSelected}>
      <p>
        Select a classroom to view student submissions.
      </p>
    </If>
  </Card>
)

export default NoResults
