import { twMerge } from 'tailwind-merge'

const Tag = ({ color, name, onClick, selected }) => (
  <button
    onClick={onClick}
    style={{ backgroundColor: color }}
    className={twMerge(
      'shrink-0 inline-flex leading-none items-center px-3 py-2 rounded-full border-transparent hover:border-black hover:cursor-pointer border-2',
      selected && 'border-black',
    )}>
    {name}
  </button>
)

export default Tag
