import { ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline'

import Page from './Page'

const Pagination = ({ page, pagesCount, setPage }) => {
  if (pagesCount < 2) {
    return null
  }

  return (
    <div className="flex justify-center items-center space-x-3 my-5">
      <button
        onClick={() => setPage(page - 1)}
        disabled={page === 1}
        className={page === 1 ? 'opacity-40 cursor-not-allowed' : ''}
      >
        <ChevronLeftIcon className="h-5 w-5" />
      </button>

      <If condition={page - 1 > 1}>
        <Page page={page} pageNumber={1} setPage={setPage} />
      </If>

      <If condition={page - 2 > 1}>
        <EllipsisHorizontalIcon className="h-5 w-5 mx-3" />
      </If>

      <If condition={page - 1 > 0}>
        <Page page={page} pageNumber={page - 1} setPage={setPage} />
      </If>

      <Page page={page} pageNumber={page} setPage={setPage} />

      <If condition={page + 1 <= pagesCount - 1}>
        <Page page={page} pageNumber={page + 1} setPage={setPage} />
      </If>


      <If condition={page + 3 <= pagesCount}>
        <EllipsisHorizontalIcon className="h-5 w-5 mx-3" />
      </If>

      <If condition={page + 1 <= pagesCount}>
        <Page page={page} pageNumber={pagesCount} setPage={setPage} />
      </If>

      <button
        onClick={() => setPage(page + 1)}
        disabled={page === pagesCount}
        className={page === pagesCount ? 'opacity-40 cursor-not-allowed' : ''}
      >
        <ChevronRightIcon className="h-5 w-5" />
      </button>
    </div >
  )
}

export default Pagination
