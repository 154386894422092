import { ArrowRightOnRectangleIcon, UserPlusIcon } from '@heroicons/react/24/outline'

import Navbar from './Navbar'

const settings = [
  { name: 'Student join code', href: '/join_short_code', icon: UserPlusIcon },
  { name: 'Sign in', href: '/sign_in', icon: ArrowRightOnRectangleIcon }
]

const PublicNav = ({ children }) => (
  <Navbar
    settings={settings}>
    {children}
  </Navbar>
)

export default PublicNav
