import React from 'react'
import { Tab } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'

const Group = ({ children, className, theme = 'primary', ...props }) => (
  <Tab.Group as="div" {...props} className={twMerge(className)}>
    {React.Children.map(children, child => React.cloneElement(child, { theme }))}
  </Tab.Group>
)

export default Group
