import { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { PlusIcon, ArrowLeftIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'

import { request } from '@helpers/graphql'

import { useBreakpoint } from '@contexts/breakpoints'

import FreeResponseQuestionForm from './FreeResponseQuestionForm'
import MultipleChoiceQuestionForm from './MultipleChoiceQuestionForm'
import ReflectionQuestionForm from './ReflectionQuestionForm'
import TextForm from './TextForm'
import VideoForm from './VideoForm'
import ImageForm from './ImageForm'
import DeleteBlockModal from './DeleteBlockModal'
import AddBlockModal from './AddBlockModal'
import TutorSelectionModal from './TutorSelectionModal'
import LessonPromptModal from './LessonPromptModal'
import BlockPreview from './BlockPreview'
import NoBlockSelected from './NoBlockSelected'
import SelectedTutor from './SelectedTutor'
import NoTutorSelectedNotification from './NoTutorSelectedNotification'

const DELETE_BLOCK_MUTATION = gql`
  mutation deleteBlock($input: DeleteBlockInput!) {
    deleteBlock(input: $input) {
      success
      errors {
        message
      }
    }
  }
`

const UPDATE_BLOCK_MUTATION = gql`
  mutation UpdateBlock($input: UpdateBlockInput!) {
    updateBlock(input: $input) {
      success
      errors {
        message
      }
    }
  }
`

const UPLOAD_PUBLIC_ASSET_MUTATION = gql`
  mutation uploadPublicAsset($input: UploadPublicAssetInput!) {
    uploadPublicAsset(input: $input) {
      assetUrl
      errors {
        message
      }
    }
  }
`

const Content = ({ id, name, blocks, tutor, refetch, prompt, socraticModeEnabled, stemModeEnabled }) => {
  const isMobile = useBreakpoint('md')

  const [selectedBlockId, setSelectedBlockId] = useState(isMobile ? null : blocks[0]?.blockId)
  const [blockIdToDelete, setBlockIdToDelete] = useState(null)
  const [blockIndexToAdd, setBlockIndexToAdd] = useState(null)
  const [isTutorSelectionModalOpen, setIsTutorSelectionModalOpen] = useState(false)
  const [isLessonPromptModalOpen, setIsLessonPromptModalOpen] = useState(false)

  const [sortedBlocks, setSortedBlocks] = useState([])
  const { mutate: deleteBlock, isLoading: isDeleting } = useMutation({
    mutationFn: async variables => request(DELETE_BLOCK_MUTATION, { input: variables }),
    onSuccess: () => {
      refetch()

      if (selectedBlockId === blockIdToDelete) setSelectedBlockId(blocks[0].blockId)

      setBlockIdToDelete(null)
    }
  })
  const { mutate: updateBlock } = useMutation({
    mutationFn: async variables => request(UPDATE_BLOCK_MUTATION, { input: variables }),
    onSuccess: refetch
  })

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const updatedBlocks = Array.from(sortedBlocks)
    const [removed] = updatedBlocks.splice(result.source.index, 1)
    updatedBlocks.splice(result.destination.index, 0, removed)

    setSortedBlocks(updatedBlocks)
    updateBlock({ blockId: removed.blockId, position: result.destination.index })
  }

  const { mutateAsync: uploadPublicAsset } = useMutation({
    mutationFn: async variables => request(UPLOAD_PUBLIC_ASSET_MUTATION, { input: { ...variables } })
  })

  const handleImageUpload = async (file) => {
    try {
      const result = await uploadPublicAsset({ educatorProjectId: id, attachment: file })
      return result.uploadPublicAsset.assetUrl
    } catch (error) {
      console.log('Error uploading image: ', error)
    }
  }

  useEffect(() => {
    setSortedBlocks(blocks)
  }, [blocks, setSortedBlocks])

  return (
    <>
      <Choose>
        <When condition={isMobile}>
          <div className='flex w-full flex-col h-full'>
            <Choose>
              <When condition={!selectedBlockId}>
                <div className='p-4 overflow-y-auto bg-white h-full'>
                  <Choose>
                    <When condition={tutor}>
                      <SelectedTutor
                        name={tutor.name}
                        imageUrl={tutor.image?.url}
                        description={tutor.description}
                        summary={tutor.summary}
                        openModal={() => setIsTutorSelectionModalOpen(true)}
                      />
                    </When>

                    <When condition={prompt}>
                      <div className='flex items-center leading-none'>
                        <button
                          onClick={() => setIsLessonPromptModalOpen(true)}
                          className='flex group items-center'
                        >
                          Edit lesson prompt
                          <span className='ml-2 group-hover:bg-gray-200 rounded-full p-1'>
                            <PencilSquareIcon className='size-5' />
                          </span>
                        </button>
                      </div>
                    </When>

                    <Otherwise>
                      <NoTutorSelectedNotification
                        openModal={() => setIsTutorSelectionModalOpen(true)}
                        lessonName={name}
                      />
                    </Otherwise>
                  </Choose>

                  <div className='h-[1px] w-full bg-gray-200 my-5' />

                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='block'>
                      {provided => (
                        <div className='flex flex-col rounded-md gap-2' ref={provided.innerRef} {...provided.droppableProps}>
                          <For each='block' of={sortedBlocks} index='index'>
                            <BlockPreview
                              setSelectedBlockId={setSelectedBlockId}
                              setBlockIdToDelete={setBlockIdToDelete}
                              selected={false}
                              key={block.blockId}
                              index={index}
                              {...block}
                            />
                          </For>

                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <button
                    aria-label={`Add block at position ${sortedBlocks.length}`}
                    onClick={() => setBlockIndexToAdd(sortedBlocks.length)}
                    className='flex w-full group/button self-center text-blue-500 mt-4'
                  >
                    <PlusIcon className='size-5 mr-2' />
                    Add block
                  </button>

                </div>
              </When>

              <Otherwise>
                <div className='flex flex-col h-[calc(100%-36px)]'>
                  <button
                    onClick={() => setSelectedBlockId(null)}
                    className='flex items-center px-5 pt-3 text-blue-500'
                  >
                    <ArrowLeftIcon className='h-5 w-5 mr-2' />
                    Back
                  </button>

                  <With block={blocks.find(block => block.blockId === selectedBlockId)}>
                    <Choose>
                      <When condition={block.__typename === 'FreeResponse'}>
                        <FreeResponseQuestionForm
                          key={block.blockId}
                          refetch={refetch}
                          uploadImage={handleImageUpload}
                          {...block}
                        />
                      </When>

                      <When condition={block.__typename === 'Question'}>
                        <MultipleChoiceQuestionForm
                          key={block.blockId}
                          refetch={refetch}
                          uploadImage={handleImageUpload}
                          {...block}
                        />
                      </When>

                      <When condition={block.__typename === 'Reflection'}>
                        <ReflectionQuestionForm
                          key={block.blockId}
                          refetch={refetch}
                          uploadImage={handleImageUpload}
                          {...block}
                        />
                      </When>

                      <When condition={block.__typename === 'Text'}>
                        <TextForm
                          key={block.blockId}
                          refetch={refetch}
                          uploadImage={handleImageUpload}
                          {...block}
                        />
                      </When>
                    </Choose>
                  </With>
                </div>
              </Otherwise>
            </Choose>
          </div>
        </When>

        <Otherwise>
          <PanelGroup className='flex grow bg-white' style={{ overflow: 'auto', height: '100%' }} direction='horizontal' autoSaveId='blockEditor'>
            <Panel
              style={{ overflow: 'auto' }}
              className='bg-white flex flex-col sm:p-5 pt-5 w-1/4 flex-shrink-0 overflow-y-auto'
              defaultSize={25}
              minSize={20}
            >
              <Choose>
                <When condition={tutor}>
                  <SelectedTutor
                    name={tutor.name}
                    imageUrl={tutor.image?.url}
                    description={tutor.description}
                    summary={tutor.summary}
                    openModal={() => setIsTutorSelectionModalOpen(true)}
                  />
                </When>

                <When condition={prompt}>
                  <div className='flex items-center leading-none'>
                    <button
                      onClick={() => setIsLessonPromptModalOpen(true)}
                      className='flex group items-center'
                    >
                      Edit lesson prompt
                      <span className='ml-2 group-hover:bg-gray-200 rounded-full p-1'>
                        <PencilSquareIcon className='size-5' />
                      </span>
                    </button>
                  </div>
                </When>

                <Otherwise>
                  <NoTutorSelectedNotification
                    openModal={() => setIsTutorSelectionModalOpen(true)}
                    lessonName={name}
                  />
                </Otherwise>
              </Choose>

              <div className='h-[1px] w-full bg-gray-200 my-5' />

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='block'>
                  {provided => (
                    <div className='flex flex-col rounded-md gap-1' ref={provided.innerRef} {...provided.droppableProps}>
                      <For each='block' of={sortedBlocks} index='index'>
                        <BlockPreview
                          setSelectedBlockId={setSelectedBlockId}
                          setBlockIdToDelete={setBlockIdToDelete}
                          selected={block.blockId === selectedBlockId}
                          key={block.blockId}
                          index={index}
                          {...block}
                        />
                      </For>

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <button
                aria-label={`Add block at position ${sortedBlocks.length}`}
                onClick={() => setBlockIndexToAdd(sortedBlocks.length)}
                className='flex w-full group/button self-center text-blue-500 mt-3'
              >
                <PlusIcon className='size-5 mr-2' />
                Add block
              </button>
            </Panel>

            <PanelResizeHandle className='flex items-center justify-center h-full'>
              <div className='bg-white px-1 h-full' />
              <div className='w-[1px] h-full bg-gray-200' />
              <div className='bg-gray-50 px-1 h-full' />
            </PanelResizeHandle>

            <Panel
              style={{ overflow: 'auto' }}
              className='flex flex-col bg-gray-50'
              defaultSize={75}
              minSize={50}
            >
              <With block={blocks.find(block => block.blockId === selectedBlockId)}>
                <Choose>
                  <When condition={!block}>
                    <NoBlockSelected addBlock={() => setBlockIndexToAdd(sortedBlocks.length)} />
                  </When>

                  <When condition={block.__typename === 'FreeResponse'}>
                    <FreeResponseQuestionForm
                      key={block.blockId}
                      refetch={refetch}
                      uploadImage={handleImageUpload}
                      {...block}
                    />
                  </When>

                  <When condition={block.__typename === 'Question'}>
                    <MultipleChoiceQuestionForm
                      key={block.blockId}
                      refetch={refetch}
                      uploadImage={handleImageUpload}
                      {...block}
                    />
                  </When>

                  <When condition={block.__typename === 'Reflection'}>
                    <ReflectionQuestionForm
                      key={block.blockId}
                      refetch={refetch}
                      uploadImage={handleImageUpload}
                      {...block}
                    />
                  </When>

                  <When condition={block.__typename === 'Text'}>
                    <TextForm
                      key={block.blockId}
                      refetch={refetch}
                      uploadImage={handleImageUpload}
                      {...block}
                    />
                  </When>

                  <When condition={block.__typename === 'Video'}>
                    <VideoForm
                      key={block.blockId}
                      refetch={refetch}
                      {...block}
                    />
                  </When>

                  <When condition={block.__typename === 'Image'}>
                    <ImageForm
                      key={block.blockId}
                      refetch={refetch}
                      {...block}
                    />
                  </When>
                </Choose>
              </With>
            </Panel>
          </PanelGroup>
        </Otherwise>
      </Choose>

      <DeleteBlockModal
        isOpen={!!blockIdToDelete}
        closeModal={() => setBlockIdToDelete(null)}
        deleteBlock={() => deleteBlock({ blockId: blockIdToDelete })}
        isDeleting={isDeleting}
      />

      <AddBlockModal
        id={id}
        index={blockIndexToAdd}
        isOpen={blockIndexToAdd !== null}
        closeModal={() => setBlockIndexToAdd(null)}
        setSelectedBlockId={setSelectedBlockId}
        refetch={refetch}
      />

      <TutorSelectionModal
        isOpen={isTutorSelectionModalOpen}
        closeModal={() => setIsTutorSelectionModalOpen(false)}
        id={id}
        tutor={tutor}
      />

      <LessonPromptModal
        id={id}
        isOpen={isLessonPromptModalOpen}
        closeModal={() => setIsLessonPromptModalOpen(false)}
        prompt={prompt}
        socraticModeEnabled={socraticModeEnabled}
        stemModeEnabled={stemModeEnabled}
      />
    </>
  )
}

export default Content
