import { gql } from 'graphql-request'
import { useQuery } from '@tanstack/react-query'
import { useParams, Routes, Route, useLocation } from 'react-router-dom'
import { Cog6ToothIcon, IdentificationIcon, UsersIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import BreadCrumbs from '@components/Breadcrumbs'
import TabNav from '@components/TabNav'

import Overview from './Overview'
import Settings from './Settings'

const TUTOR_QUERY = gql`
  query tutor($id: ID!) {
    tutor(id: $id) {
      id
      name
      createdAt
      description
      summary
      socratic
      suggestionsEnabled
      stemModeEnabled
      knowledgeBaseEnabled
      visibility
      approvedForCommunity
      image {
        url
      }
      userName
      organizationName
    }
  }
`

const TutorDetails = () => {
  const { id } = useParams()
  const location = useLocation()

  const previousListParams = location.state?.previousListParams || ''

  const { isLoading, refetch, data: { tutor = {} } = {} } = useQuery({
    queryKey: ['adminTutor', id],
    queryFn: async () => request(TUTOR_QUERY, { id })
  })

  if (isLoading) return

  return (
    <div>
      <BreadCrumbs>
        <BreadCrumbs.Link label='Tutors' to={`/admin/tutors${previousListParams}`} />
        <BreadCrumbs.Text label={tutor.name} />
      </BreadCrumbs>

      <TabNav>
        <TabNav.Link
          to=''
          end
          label='Overview'
          icon={<IdentificationIcon className='h-6 w-6 mr-1' />}
        />
        <TabNav.Link
          to='settings'
          label='Settings'
          icon={<Cog6ToothIcon className='h-6 w-6 mr-1' />}
        />
      </TabNav>

      <Routes>
        <Route
          index
          element={
            <Overview
              tutorId={id}
              name={tutor.name}
              createdAt={tutor.createdAt}
              description={tutor.description}
              summary={tutor.summary}
              socratic={tutor.socratic}
              suggestionsEnabled={tutor.suggestionsEnabled}
              stemModeEnabled={tutor.stemModeEnabled}
              knowledgeBaseEnabled={tutor.knowledgeBaseEnabled}
              visibility={tutor.visibility}
              approvedForCommunity={tutor.approvedForCommunity}
              imageUrl={tutor?.image?.url}
              userName={tutor.userName}
              organizationName={tutor.organizationName}
            />
          }
        />

        <Route
          path='settings'
          element={
            <Settings
              tutorId={id}
              approvedForCommunity={tutor.approvedForCommunity}
              refetch={refetch}
            />
          }
        />
      </Routes>
    </div>
  )
}

export default TutorDetails
