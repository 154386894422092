import { Children, cloneElement } from 'react'
import { twMerge } from 'tailwind-merge'
import { Link } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/24/outline'

const LinkCrumb = ({ label, to }) => (
  <Link className='font-semibold inline-block text-nowrap' to={to}>{label}</Link>
)

const TextCrumb = ({ label }) => (
  <p className='inline-block truncate'>{label}</p>
)

const BreadCrumbsComponent = ({ children, className }) => {
  const childrenWithSeparators = []
  const childCount = Children.count(children)

  Children.forEach(children, (child, index) => {
    childrenWithSeparators.push(cloneElement(child, { key: index }))

    // Add separator only if current child is LinkCrumb or TextCrumb
    // and if there's a next child
    if (index < childCount - 1) {
      const currentChild = child.type.name
      const nextChild = Children.toArray(children)[index + 1]?.type.name
      const isCrumb = [LinkCrumb.name, TextCrumb.name].includes(currentChild)
      const nextIsCrumb = [LinkCrumb.name, TextCrumb.name].includes(nextChild)

      if (isCrumb && nextIsCrumb) {
        childrenWithSeparators.push(
          <ChevronRightIcon key={`separator-${index}`} className='mx-1 w-4 h-4 shrink-0' />
        )
      }
    }
  })

  return (
    <div className={twMerge('max-h-[45px] flex flex-row items-center text-base py-3 px-5 leading-tight border-b-gray-200 border-b bg-white', className)}>
      {childrenWithSeparators}
    </div>
  )
}

const BreadCrumbs = BreadCrumbsComponent

BreadCrumbs.Link = LinkCrumb
BreadCrumbs.Text = TextCrumb

export default BreadCrumbs
