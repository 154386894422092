import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import {
  DocumentTextIcon,
  LightBulbIcon,
  Bars3BottomLeftIcon,
  NumberedListIcon,
  VideoCameraIcon
} from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import Modal from '@components/Modal'
import Spinner from '@components/Spinner'

const CREATE_BLOCK_MUTATION = gql`
  mutation createBlock($input: CreateBlockInput!) {
    createBlock(input: $input) {
      block {
        blockId
        position
      }
      errors {
        message
      }
    }
  }
`

const AddBlockModal = ({ id, index, closeModal, isOpen, refetch, setSelectedBlockId }) => {
  const { mutate: createBlock, isLoading: isCreating } = useMutation({
    mutationFn: async variables => request(CREATE_BLOCK_MUTATION, { input: { educatorProjectId: id, position: index, ...variables } }),
    onSuccess: async data => {
      await refetch()

      setSelectedBlockId(data.createBlock.block.blockId)
      closeModal()
    }
  })

  return (
    <Modal
      size='md'
      isOpen={isOpen}
      onClose={closeModal}
    >
      <div className='flex flex-col px-3 py-5 h-full'>
        <Choose>
          <When condition={isCreating}>
            <Spinner className='my-5 self-center' />
          </When>

          <Otherwise>
            <p className='text-lg font-semibold'>Content</p>

            <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-3 gap-3 mb-5'>
              <button
                type='button'
                onClick={() => createBlock({ blockType: 'TEXT' })}
                className='flex flex-row items-center mb-2 hover:bg-gray-100 hover:font-semibold p-2 rounded-md'
              >
                <DocumentTextIcon className='size-8 mr-2 bg-purple-200 rounded-md p-1 shrink-0' />
                Content
              </button>

              <button
                type='button'
                onClick={() => createBlock({ blockType: 'VIDEO' })}
                className='flex flex-row items-center mb-2 hover:bg-gray-100 hover:font-semibold p-2 rounded-md'
              >
                <VideoCameraIcon className='size-8 mr-2 bg-purple-200 rounded-md p-1 shrink-0' />
                Video
              </button>
            </div>

            <p className='text-lg font-semibold'>Questions</p>

            <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-3 gap-3'>
              <button
                type='button'
                onClick={() => createBlock({ blockType: 'FREE_RESPONSE' })}
                className='flex flex-row items-center gap-2 mb-2  hover:bg-gray-100 hover:font-semibold p-2 rounded-md'
              >
                <Bars3BottomLeftIcon className='size-8 mr-2 bg-orange-200 rounded-md p-1 shrink-0' />
                Free response
              </button>

              <button
                type='button'
                onClick={() => createBlock({ blockType: 'QUESTION' })}
                className='flex flex-row items-center gap-2 mb-2  hover:bg-gray-100 hover:font-semibold p-2 rounded-md'
              >
                <NumberedListIcon className='size-8 mr-2 bg-yellow-200 rounded-md p-1 shrink-0' />
                Multiple choice
              </button>

              <button
                type='button'
                onClick={() => createBlock({ blockType: 'REFLECTION' })}
                className='flex flex-row items-center gap-2 mb-2  hover:bg-gray-100 hover:font-semibold p-2 rounded-md'
              >
                <LightBulbIcon className='size-8 mr-2 bg-red-200 rounded-md p-1 shrink-0' />
                Reflection
              </button>
            </div>
          </Otherwise>
        </Choose>
      </div>
    </Modal>
  )
}

export default AddBlockModal
