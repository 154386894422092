import Card from '@components/Card'

const CalculatingInsights = () => (
  <Card className='w-auto flex flex-col items-center justify-center p-10'>
    <img src='/reading.svg' alt='Student searching' className='w-[120px] self-center mb-5' />
    <h4 className='text-2xl font-semibold'>Calculating insights</h4>
    <p>This could take a few minutes...</p>
  </Card>
)

export default CalculatingInsights
