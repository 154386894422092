import { useQuery } from '@tanstack/react-query'
import { Link, useSearchParams, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { gql } from 'graphql-request'
import {
  EllipsisHorizontalIcon,
  CheckIcon
} from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import Card from '@components/Card'
import Select from '@components/Select'
import Table from '@components/Table'
import Spinner from '@components/Spinner'

import NoResults from './NoResults'
import LessonSubmissionList from './LessonSubmissionList'

const SERIES_SUBMISSION_QUERY = gql`
  query seriesSubmissions($classroomId: ID!, $seriesId: ID!) {
    seriesSubmissions(classroomId: $classroomId, seriesId: $seriesId) {
      studentProfile {
        id
        fullName
      }
      submissions {
        id
        status
        score
        educatorProject {
          id
        }
      }
      score
    }
  }
`

const SubmissionList = ({ id, classroomId, educatorProjects, totalPossibleScore }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedLessonId = searchParams.get('lessonId')

  const setSelectedLessonId = lessonId => {
    setSearchParams({ lessonId })
  }

  const { data: { seriesSubmissions = [] } = {}, isInitialLoading: isLoading } = useQuery(
    ['classroomCourseSubmissions', id, classroomId],
    async () => request(SERIES_SUBMISSION_QUERY, { classroomId, seriesId: id })
  )

  return (
    <div className='mx-5 my-3 h-full'>
      <Card className='flex flex-row items-center p-3 mb-5 gap-5'>
        <Select
          label='Lesson'
          value={selectedLessonId}
          onChange={e => setSelectedLessonId(e.target.value)}
        >
          <Select.Option key='' value=''>Select a lesson</Select.Option>
          <For each='lesson' of={educatorProjects}>
            <Select.Option key={lesson.id} value={lesson.id}>{lesson.name}</Select.Option>
          </For>
        </Select>
        {/* TODO Ethan: Add return all submissions logic */}
        {/* <If condition={!selectedLessonId}>
          <Button
            className='ml-auto py-1 self-end disabled:cursor-not-allowed disabled:opacity-40'
            variant='outlined'
            theme='secondary'
            label={
              <span className='flex items-center'>
                <ArrowUturnLeftIcon className='w-5 h-5 mr-2' />
                Return all submissions
              </span>
            }
          />
        </If> */}
      </Card>

      <Choose>
        <When condition={isLoading}>
          <Spinner className='flex items-center justify-center w-full mt-24' />
        </When>

        <When condition={seriesSubmissions.length < 1}>
          <NoResults />
        </When>

        <When condition={selectedLessonId}>
          <LessonSubmissionList
            key={`${classroomId}-${selectedLessonId}`}
            classroomId={classroomId}
            educatorProjectId={selectedLessonId}
          />
        </When>

        <Otherwise>
          {/**
           * Complex height calculation for table scrolling:
           * On desktop: height = full height - breadcrumbs - tabs - toolbar - padding
           * On mobile: height = full height - navbar - breadcrumbs - tabs - toolbar - padding
           **/}
          <div className='lg:max-h-[calc(100vh-84px-50px-45px-2.75rem)] max-h-[calc(100vh-57px-84px-50px-45px-2.75rem)] flex overflow-auto whitespace-nowrap w-full rounded-lg border border-gray-200 bg-white'>
            <Table className='table-auto w-full'>
              <Table.Head>
                <Table.Row>
                  <Table.Header className='text-base align-middle w-48 sticky left-0 top-0 z-10 bg-gray-50'>
                    Students
                    <div className='absolute top-0 right-0 bottom-0 w-px bg-gray-200 z-20' />
                  </Table.Header>
                  <Table.Header className='text-base align-middle w-32 sticky top-0 bg-gray-50'>Total</Table.Header>
                  <For each='project' of={educatorProjects}>
                    <Table.Header key={project.id} className='text-base align-middle text-center sticky top-0 bg-gray-50 w-32 z-10'>
                      {project.name}
                    </Table.Header>
                  </For>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <For each='seriesSubmission' of={seriesSubmissions}>
                  <Table.Row key={seriesSubmission.studentProfile.id}>
                    <Table.Cell className='sticky left-0 bg-white w-48'>
                      <div className='relative flex items-center'>
                        {seriesSubmission.studentProfile.fullName}
                      </div>
                      <div className='absolute top-0 right-0 bottom-0 w-px bg-gray-200 z-20' />
                    </Table.Cell>
                    <Table.Cell className='w-32'>
                      <div className='bg-gray-100 rounded-md px-2 py-1 w-fit border border-gray-300'>
                        {seriesSubmission.score} / {totalPossibleScore}
                      </div>
                    </Table.Cell>

                    <For each='project' of={educatorProjects}>
                      <With submission={seriesSubmission.submissions.find(s => s.educatorProject.id === project.id)}>
                        <Choose>
                          <When condition={submission?.status === 'COMPLETED' || submission?.status === 'RETURNED'}>
                            <Table.Cell className='w-32'>
                              <div className='w-full flex justify-center items-center'>
                                <button onClick={() => setSelectedLessonId(project.id)}>
                                  <div className='w-full flex justify-center'>
                                    <Choose>
                                      <When condition={project.totalPossibleScore > 0}>
                                        <With percentage={(submission.score / project.totalPossibleScore) * 100}>
                                          <div className={twMerge(
                                            'relative flex items-center justify-center rounded-md px-2 py-1',
                                            percentage === 100
                                              ? 'bg-green-100 border border-green-600'
                                              : percentage >= 50
                                                ? 'bg-yellow-100 border border-yellow-600'
                                                : 'bg-red-100 border border-red-600'
                                          )}
                                          >
                                            {submission.score}
                                            <span className='mx-1'>/</span>
                                            {project.totalPossibleScore}
                                          </div>
                                        </With>
                                      </When>
                                      <Otherwise>
                                        <div className='bg-gray-100 rounded-md px-2 py-1 w-fit border border-gray-300'>
                                          <CheckIcon className='w-5 h-5 mx-auto' />
                                        </div>
                                      </Otherwise>
                                    </Choose>
                                  </div>
                                </button>
                              </div>
                            </Table.Cell>
                          </When>

                          <Otherwise>
                            <Table.Cell />
                          </Otherwise>
                        </Choose>
                      </With>
                    </For>
                  </Table.Row>
                </For>
              </Table.Body>
            </Table>
          </div>
        </Otherwise>
      </Choose>
    </div>
  )
}

export default SubmissionList
