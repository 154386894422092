import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Modal from '@components/Modal'

const BATCH_ARCHIVE_STUDENTS_MUTATION = gql`
  mutation batchArchiveStudentProfiles($input: BatchArchiveStudentProfilesInput!) {
    batchArchiveStudentProfiles(input: $input) {
      success
      errors {
        message
      }
    }
  }
`

const ArchiveStudentsModal = ({
  closeModal,
  isOpen,
  classroomId
}) => {
  const { mutate: archiveStudents, isLoading: isArchiving } = useMutation({
    mutationFn: async () => request(BATCH_ARCHIVE_STUDENTS_MUTATION, { input: { classroomId } }),
    onSuccess: () => closeModal()
  })

  return (
    <Modal
      size='sm'
      isOpen={isOpen}
      onClose={closeModal}
    >
      <h3 className='font-heading text-2xl font-bold mb-3'>Archive students</h3>
      <p className='mb-3'>
        Are you sure you'd like to archive all the students in this classroom?
      </p>
      <p className='mb-3'>
        Students will no longer be able to use Mindjoy.
        This can be changed later.
      </p>

      <div className='flex justify-end'>
        <Button
          onClick={archiveStudents}
          disabled={isArchiving}
          label={isArchiving ? 'Archiving...' : 'Archive students'}
          className='w-fit mt-3 self-end'
        />
      </div>
    </Modal>
  )
}

export default ArchiveStudentsModal
