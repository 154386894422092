import axios from 'axios'

const baseURL = import.meta.env.VITE_API_URL

axios.defaults.xsrfCookieName = 'CSRF-TOKEN'
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'

export const client = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true,
  withXSRFToken: true,
  crossDomain: true
})
