import { twMerge } from 'tailwind-merge'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { useBreakpoint } from '@contexts/breakpoints'
import Image from '@components/Image'

const Attachment = ({ id, url, filename, remove }) => {
  const isMobile = useBreakpoint('md')

  return (
    <div tabIndex='0' className='group relative rounded-lg'>
      <Image
        placeholder={<div className='w-[150px] h-[100px] bg-gray-200 animate-pulse rounded-lg' />}
        key={id}
        className='object-cover w-[150px] h-[100px] rounded-lg'
        src={url}
        alt={filename}
      />

      <button
        type='button'
        aria-label='Remove attachment'
        onClick={remove}
        className={twMerge(
          isMobile ? 'block' : 'hidden group-hover:block group-focus:block', // Always remove show on touch-screen devices
          'absolute top-[-10px] right-[-10px] p-1 bg-red-500 rounded-full'
        )}
      >
        <XMarkIcon className='h-4 w-4 text-white' />
      </button>
    </div>
  )
}

export default Attachment
