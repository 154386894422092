import { Extension } from '@tiptap/core'

// https://github.com/ueberdosis/tiptap/issues/2755

const KeyboardHandler = Extension.create({
  name: 'keyboardHandler',

  addKeyboardShortcuts: () => {
    return {
      'Shift-Enter': ({ editor }) => {
        editor.commands.first(({ commands }) => [
          () => commands.newlineInCode(),
          () => commands.splitListItem('listItem'),
          () => commands.createParagraphNear(),
          () => commands.liftEmptyBlock(),
          () => commands.splitBlock()
        ])
      }
    }
  }
})

export default KeyboardHandler
