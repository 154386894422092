import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import { useFlashMessage } from '@components/FlashMessage'
import Button from '@components/Button'
import Modal from '@components/Modal'
import Notification from '@components/Notification'

const DELETE_PROJECT_MUTATION = gql`
  mutation deleteEducatorProject($input: DeleteEducatorProjectInput!) {
    deleteEducatorProject(input: $input) {
      success
      errors {
        message
      }
    }
  }
`

const DeleteProjectModal = ({
  seriesId,
  closeModal,
  isOpen,
  projectId,
  name,
  assignedToClassrooms,
  refetch
}) => {
  const { setFlashMessage } = useFlashMessage()

  const { mutate: deleteProject, isLoading: isDeleting } = useMutation({
    mutationFn: async () => request(DELETE_PROJECT_MUTATION, { input: { id: projectId } }),
    onSuccess: () => {
      setFlashMessage('Project deleted', 'success')
      closeModal()
      refetch()
    }
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
    >
      <h3 className='font-heading text-2xl font-bold mb-3'>Delete lesson</h3>
      <p className='mb-3'>You're about to delete <strong>{name}</strong>.</p>

      <If condition={assignedToClassrooms}>
        <Notification className='font-normal' theme='warning'>
          <ExclamationTriangleIcon className='h-5 w-5 mr-2' />
          This will remove the lesson from classrooms it's assigned to
        </Notification>
      </If>

      <div className='flex justify-end'>
        <Button
          onClick={deleteProject}
          disabled={isDeleting}
          theme='error'
          label={isDeleting ? 'Deleting...' : 'Delete'}
          className='w-fit mt-3 self-end'
        />
      </div>
    </Modal>
  )
}

export default DeleteProjectModal
