import { useState } from 'react'
import { useParams, Routes, Route } from 'react-router-dom'
import { gql } from 'graphql-request'
import { useQuery } from '@tanstack/react-query'
import { UsersIcon, Cog6ToothIcon, BookOpenIcon, ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import BreadCrumbs from '@components/Breadcrumbs'
import Spinner from '@components/Spinner'
import TabNav from '@components/TabNav'

import { useSubscription } from '../../hooks/subscription'
import ProFeatureTooltip from '../../components/ProFeatureTooltip'
import AddStudentsModal from '../../components/AddStudentsModal'
import TutorList from './TutorList'
import CoursesList from './CoursesList'
import StudentProfilesList from './StudentProfilesList'
import Settings from './Settings'

const CLASSROOM_QUERY = gql`
  query classroom($id: ID!) {
    classroom(id: $id) {
      id
      name
      shortCode
      educatorProfile {
        id
        fullName
      }
    }
  }
`

const ClassroomDetails = () => {
  const { id } = useParams()
  const { hasProFeatures } = useSubscription()
  const [showInviteModal, setShowInviteModal] = useState(false)
  const {
    isLoading,
    data: { classroom = {} } = {},
    refetch
  } = useQuery({
    queryKey: ['classroom', id],
    queryFn: async () => await request(CLASSROOM_QUERY, { id })
  })

  return (
    <>
      <BreadCrumbs>
        <BreadCrumbs.Link label='Classrooms' to='/educators/classrooms' />
        <BreadCrumbs.Text label={classroom.name} />
      </BreadCrumbs>

      <Choose>
        <When condition={isLoading}>
          <Spinner className='relative left-0 top-0 flex h-screen w-full items-center justify-center' />
        </When>

        <Otherwise>
          <TabNav>
            <TabNav.Link
              to=''
              end
              icon={<ChatBubbleLeftEllipsisIcon className='size-6 mr-1' />}
              label='Tutors'
            />
            <TabNav.Link
              to='courses'
              end
              icon={<BookOpenIcon className='size-6 mr-1' />}
              label='Courses'
            />
            <TabNav.Link
              to='students'
              label='Students'
              icon={<UsersIcon className='size-6 mr-1' />}
            />
            <TabNav.Link
              to='settings'
              label='Settings'
              icon={<Cog6ToothIcon className='size-6 mr-1' />}
            />

            <div className='ml-auto mr-5'>
              <Button
                id='add-students-button'
                className='my-1.5 py-2'
                disabled={!hasProFeatures}
                onClick={() => setShowInviteModal(true)}
                label='Add students'
              />

              <If condition={!hasProFeatures}>
                <ProFeatureTooltip anchorSelect='#add-students-button' />
              </If>
            </div>
          </TabNav>

          <Routes>
            <Route
              index
              element={<TutorList id={id} />}
            />

            <Route
              path='courses'
              element={<CoursesList id={id} />}
            />

            <Route
              path='students'
              element={<StudentProfilesList id={id} />}
            />

            <Route
              path='settings'
              element={<Settings classroomId={classroom.id} name={classroom.name} refetch={refetch} />}
            />
          </Routes>

          <AddStudentsModal
            classroomId={id}
            shortCode={classroom.shortCode}
            classroomName={classroom.name}
            closeModal={() => setShowInviteModal(false)}
            isOpen={showInviteModal}
          />
        </Otherwise>
      </Choose>
    </>
  )
}

export default ClassroomDetails
