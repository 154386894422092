import { Tab } from '@headlessui/react'
import { ShieldCheckIcon, UserCircleIcon } from '@heroicons/react/24/outline'

import PageHeading from '@components/PageHeading'

import Profile from './Profile'
import SecuritySettings from './SecuritySettings'

const Settings = () => (
  <>
    <PageHeading title='Settings' />

    <div className='h-full'>
      <Tab.Group as='div' className='flex flex-col md:flex-row md:h-full'>
        <Tab.List className='flex flex-col text-base p-5 space-y-1 border-gray-200 border-r h-full min-w-[250px]'>
          <Tab className='ui-selected:bg-gray-200 ui-selected:font-semibold hover:text-blue-600 hover:bg-gray-200 group flex gap-x-3 rounded-md p-2 leading-6 outline-none'>
            <UserCircleIcon className='h-5 w-5 inline-block mr-2' />
            Profile
          </Tab>

          <Tab className='ui-selected:bg-gray-200 ui-selected:font-semibold hover:text-blue-600 hover:bg-gray-200 group flex gap-x-3 rounded-md p-2 leading-6 outline-none'>
            <ShieldCheckIcon className='h-5 w-5 inline-block mr-2' />
            Security settings
          </Tab>
        </Tab.List>

        <Tab.Panels className='flex-grow md:p-5'>
          <Tab.Panel>
            <Profile />
          </Tab.Panel>

          <Tab.Panel>
            <SecuritySettings />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  </>
)

export default Settings
