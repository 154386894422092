import { useState } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { tomorrowNightBlue } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { DocumentDuplicateIcon, CheckIcon } from '@heroicons/react/24/outline'

const CodeBlock = ({ children, inline }) => {
  const [codeCopied, setCodeCopied] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(children)
  }

  return (
    <Choose>
      <When condition={inline}>
        <code className="bg-gray-300 p-1">{children}</code>
      </When>

      <Otherwise>
        <div className="[&_pre]:rounded-md relative overflow-auto mb-3">
          <Choose>
            <When condition={codeCopied}>
              <CheckIcon className="h-5 w-5 absolute top-0 right-0 m-2 text-white" />
            </When>

            <Otherwise>
              <button
                className="absolute top-0 right-0 m-2 text-white cursor-pointer"
                onClick={() => {
                  copyToClipboard()
                  setCodeCopied(true)
                  setTimeout(() => setCodeCopied(false), 1000)
                }}>
                <DocumentDuplicateIcon className="h-5 w-5" />
              </button>
            </Otherwise>
          </Choose>

          <SyntaxHighlighter
            language="python"
            style={tomorrowNightBlue}>
            {children}
          </SyntaxHighlighter>
        </div>
      </Otherwise>
    </Choose>
  )
}

export default CodeBlock
