import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { gql } from 'graphql-request'
import { capitalize, round, orderBy } from 'lodash'

import { useCurrentUser } from '@contexts/currentUser'
import { request } from '@helpers/graphql'
import { formatShortDuration, formatDuration } from '@helpers/format'
import BreadCrumbs from '@components/Breadcrumbs'
import Table from '@components/Table'
import Card from '@components/Card'
import { BarChart, PieChart, ScatterChart } from '@components/Charts'

import InsightCard from './InsightCard'

const EDUCATOR_PROJECT_QUERY = gql`
  query educatorProject($id: ID!) {
    node(id: $id) {
      ... on EducatorProject {
        id
        name
        mySubmission {
          id
          status
        }
        series {
          id
          name
          assignment {
            classroom {
              id
            }
          }
        }
      }
    }
  }
`

const CLASSROOM_INSIGHTS_QUERY = gql`
  query anonymousClassroomInsights($classroomId: ID!, $insightableId: ID!) {
    anonymousClassroomInsights(classroomId: $classroomId, insightableId: $insightableId) {
      __typename
      ... on Insight {
        totalTimeSeconds
        activeTimeSeconds
        questionsAsked
        questionsAnswered
        answerQuality
        group
        misconceptionsConcepts
        misconceptionsDetails
        misconceptionsSyllabusConnection
      }
      ... on AnonymousInsight {
        totalTimeSeconds
        activeTimeSeconds
        questionsAsked
        questionsAnswered
        answerQuality
        group
      }
    }
  }
`

const STUDENT_INSIGHTS_QUERY = gql`
  query studentInsights($insightableId: ID!) {
    studentInsights(insightableId: $insightableId) {
      totalTimeSeconds
      activeTimeSeconds
      questionsAsked
      questionsAnswered
      answerQuality
      group
      questionCountDefinition
      questionCountConceptual
      questionCountProcedural
      questionCountVerification
      questionCountOther
      questionExampleDefinition
      questionExampleConceptual
      questionExampleProcedural
      questionExampleVerification
      questionExampleOther
      questionAdvice
      aiAdvice
      answerCountIncorrect
      answerCountPartiallyCorrect
      answerCountCorrect
      answerCountSingleWord
      answerCountOther
      answerExampleIncorrect
      answerExamplePartiallyCorrect
      answerExampleCorrect
      answerExampleSingleWord
      answerExampleOther
      answerAdvice
      recommendationsFocusedStudyAreas
      recommendationsEngagement
      recommendationsCriticalThinking
      recommendationsBehaviorIndicators
      recommendationsImprovements
      skillsDemonstrated
      areasForImprovement
      misconceptionsDetails
      misconceptionsConcepts
      misconceptionsEvidence
      misconceptionsSyllabusConnection
    }
  }
`

const LessonInsights = () => {
  const { id } = useParams()

  const { isLoading, data: { node: { series: { assignment: { classroom } = {}, ...series } = {}, ...educatorProject } = {} } = {} } = useQuery({
    queryKey: ['educatorProject', id],
    queryFn: async () => request(EDUCATOR_PROJECT_QUERY, { id })
  })

  const { data: { anonymousClassroomInsights = [] } = {} } = useQuery(
    ['anonymousClassroomInsights', classroom?.id, id],
    async () => request(CLASSROOM_INSIGHTS_QUERY, { classroomId: classroom?.id, insightableId: id }),
    { enabled: !!classroom?.id && !!id }
  )

  const { insightIsLoading, data: { studentInsights = {} } = {} } = useQuery({
    queryKey: ['studentInsights', id],
    queryFn: async () => request(STUDENT_INSIGHTS_QUERY, { insightableId: id })
  })

  const questionData = studentInsights && [
    { type: 'Definition Question', count: studentInsights.questionCountDefinition, example: studentInsights.questionExampleDefinition },
    { type: 'Conceptual Question', count: studentInsights.questionCountConceptual, example: studentInsights.questionExampleConceptual },
    { type: 'Procedural Question', count: studentInsights.questionCountProcedural, example: studentInsights.questionExampleProcedural },
    { type: 'Verification Question', count: studentInsights.questionCountVerification, example: studentInsights.questionExampleVerification },
    { type: 'Other', count: studentInsights.questionCountOther, example: studentInsights.questionExampleOther }
  ]

  const totalQuestions = studentInsights && questionData.reduce((sum, question) => sum + question.count, 0)

  const questionDataWithPercentages = studentInsights && questionData.map(question => ({
    ...question,
    percentage: question.count && ((question.count / totalQuestions) * 100).toFixed(2),
    example: question.example
  }))

  const misconceptionData = studentInsights && studentInsights.misconceptionsDetails &&
    studentInsights.misconceptionsDetails.map((misconception, index) => ({
      concept: studentInsights.misconceptionsConcepts[index],
      detail: misconception,
      evidence: studentInsights.misconceptionsEvidence[index]
    }))

  const { user: { first_name: currentUserFirstName, last_name: currentUserLastName } } = useCurrentUser()

  const scatterDataFunction = (acc, student) => {
    let key
    const value = {
      x: round(student.activeTimeSeconds / 60, 2),
      y: student.questionsAsked,
      tooltipData: {
        Questions: student.questionsAsked,
        Quality: capitalize(student.answerQuality),
        Time: formatDuration(student.activeTimeSeconds)
      }
    }

    // The backend should only return an Insight (rather than an AnonymousInsight) if the student is the current user.
    if (student.__typename == 'Insight') {
      key = 'currentUser'
      value.tooltipData.Name = `${currentUserFirstName} ${currentUserLastName}`
    } else {
      key = student.answerQuality
    }

    if (!acc[key]) acc[key] = []

    acc[key].push(value)
    return acc
  }
  const scatterData = anonymousClassroomInsights.reduce(scatterDataFunction, {})

  const calculateAverage = (data, key) => {
    const total = data.reduce((acc, student) => acc + (student[key] || 0), 0)
    return total / data.length
  }

  const avgActiveTime = calculateAverage(anonymousClassroomInsights, 'activeTimeSeconds')

  const insightsData = {
    title: 'Insights',
    data: [
      { name: 'Your active time', value: formatShortDuration(studentInsights?.activeTimeSeconds) },
      { name: 'Class average', value: formatShortDuration(avgActiveTime) },
      { name: 'Questions Asked', value: studentInsights?.questionsAsked },
      { name: 'Questions Answered', value: studentInsights?.questionsAnswered }
    ]
  }

  const engagementData = {
    title: 'Engagement',
    comments: studentInsights?.recommendationsEngagement,
    options: {
      labels: {
        y: 'Active Time (mins)'
      }
    },
    data: orderBy(
      anonymousClassroomInsights.map((student) => {
        const currentStudent = student.__typename == 'Insight'
        return {
          value: round(student.activeTimeSeconds / 60, 2),
          color: currentStudent ? '#8b2be2' : '#9ca3af', // Purple for current user, gray for others
          tooltipData: {
            Name: currentStudent
              ? `${currentUserFirstName} ${currentUserLastName}`
              : null,
            Time: formatDuration(student.activeTimeSeconds)
          }
        }
      }),
      ['value'],
      ['desc']
    )
  }

  const gray = '#9ca3af'
  const purple = '#8b2be2'

  const timeLearningVsEngagementData = {
    title: 'Time Learning vs. Engagement',
    options: {
      xAxis: {
        unit: 'min'
      },
      fill: {
        null: gray,
        poor: gray,
        satisfactory: gray,
        good: gray,
        currentUser: purple
      }
    },
    data: scatterData
  }

  const questionsAskedData = {
    title: 'Questions Asked',
    options: {
      colors: [purple, '#3366cc', '#4a89dc', '#a9e4ef', '#dbe9ee']
    },
    data: (() => {
      // Ensure questionDataWithPercentages exists and has data
      if (!questionDataWithPercentages || questionDataWithPercentages.length === 0) {
        return []
      }

      return questionDataWithPercentages.map(question => ({
        name: question.type,
        value: parseFloat(question.percentage), // Ensure percentage is a number
        tooltipData: {
          Type: question.type,
          Percentage: `${round(question.percentage)}%`,
          Example: question.example
        }
      }))
    })()
  }

  if (isLoading) return

  return (
    <>
      <BreadCrumbs>
        <BreadCrumbs.Link label='My courses' to='/students/courses' />
        <BreadCrumbs.Link label={series.name} to={`/students/courses/${series.id}`} />
        <BreadCrumbs.Link label={educatorProject.name} to={`/students/courses/${series.id}/lessons/${educatorProject.id}`} />
        <BreadCrumbs.Text label='Insights' />
      </BreadCrumbs>

      <Choose>
        <When condition={!studentInsights}>
          <div className='mt-3 mx-5'>
            No insights available yet!
          </div>
        </When>

        <Otherwise>
          <div className='my-6 mx-8 space-y-10'>
            <div className='grid space-y-3'>
              <h2 className='font-heading text-2xl font-bold'>{insightsData.title}</h2>

              <InsightCard data={insightsData.data} />
            </div>

            <div className='grid space-y-3'>
              <h2 className='font-heading text-2xl font-bold'>{engagementData.title}</h2>

              <Card className='grid space-y-3 items-center p-6'>
                <BarChart
                  data={engagementData.data}
                  options={engagementData.options}
                />

                <If condition={engagementData.comments}>
                  <span>{engagementData.comments}</span>
                </If>
              </Card>
            </div>

            <If condition={studentInsights.recommendationsCriticalThinking}>
              <div className='grid space-y-3'>
                <h2 className='font-heading text-2xl font-bold'>Critical Thinking</h2>

                <Card className='p-6'>
                  {studentInsights.recommendationsCriticalThinking}
                </Card>
              </div>
            </If>

            <If condition={studentInsights.recommendationsBehaviorIndicators}>
              <div className='grid space-y-3'>
                <h2 className='font-heading text-2xl font-bold'>Insight into how you learn</h2>

                <Card className='p-6'>
                  {studentInsights.recommendationsBehaviorIndicators}
                </Card>
              </div>
            </If>

            <If condition={misconceptionData}>
              <div className='grid space-y-3'>
                <h2 className='font-heading text-2xl font-bold'>Misconceptions</h2>

                <Table>
                  <Table.Head>
                    <Table.Row>
                      <Table.Header width='33%'>Concept</Table.Header>
                      <Table.Header width='33%'>Detail</Table.Header>
                      <Table.Header width='33%'>Evidence</Table.Header>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    <For each='misconception' index='index' of={misconceptionData}>
                      <Table.Row key={index}>
                        <Table.Cell className='whitespace-normal'>{misconception.concept}</Table.Cell>
                        <Table.Cell className='whitespace-normal'>{misconception.detail}</Table.Cell>
                        <Table.Cell className='whitespace-normal'>{misconception.evidence}</Table.Cell>
                      </Table.Row>
                    </For>
                  </Table.Body>
                </Table>
              </div>
            </If>

            <If condition={timeLearningVsEngagementData}>
              <div>
                <h2 className='font-heading text-2xl font-bold mb-5'>AI Skills</h2>
                <Card className='p-6'>
                  <h3 className='font-heading text-xl font-bold mb-3'>{timeLearningVsEngagementData.title}</h3>

                  <ScatterChart
                    data={timeLearningVsEngagementData.data}
                    options={timeLearningVsEngagementData.options}
                  />
                </Card>
              </div>
            </If>

            <If condition={questionsAskedData}>
              <div>
                <div className='grid grid-cols-1 gap-5 sm:grid-cols-2'>
                  <Card className='p-6'>
                    <h3 className='font-heading text-xl font-bold mb-3'>{questionsAskedData.title}</h3>

                    <PieChart
                      data={questionsAskedData.data}
                      options={questionsAskedData.options}
                    />
                  </Card>

                  <Card className='space-y-6'>
                    <If condition={studentInsights.skillsDemonstrated}>
                      <div className='p-6'>
                        <h3 className='font-heading text-xl font-bold mb-3'>Skills Demonstrated</h3>

                        <ul className='items-center justify-center px-5 list-disc'>
                          <For each='area' index='index' of={studentInsights.skillsDemonstrated}>
                            <li key={index}>{area}</li>
                          </For>
                        </ul>
                      </div>
                    </If>

                    <If condition={studentInsights.areasForImprovement}>
                      <div className='p-6'>
                        <h3 className='font-heading text-xl font-bold mb-3'>Areas for Improvement</h3>

                        <ul className='items-center justify-center px-5 list-disc'>
                          <For each='area' index='index' of={studentInsights.areasForImprovement}>
                            <li key={index}>{area}</li>
                          </For>
                        </ul>
                      </div>
                    </If>
                  </Card>
                </div>
              </div>
            </If>

            <If condition={studentInsights.questionAdvice}>
              <div>
                <h3 className='font-heading text-xl font-bold mb-3'>How to write better questions</h3>

                <Card className='p-6'>
                  <p className='items-center justify-center'>
                    {studentInsights.questionAdvice}
                  </p>
                </Card>
              </div>
            </If>

            <If condition={studentInsights.answerAdvice}>
              <div>
                <h3 className='font-heading text-xl font-bold mb-3'>How to structure better answers</h3>

                <Card className='p-6'>
                  <p className='items-center justify-center'>
                    {studentInsights.answerAdvice}
                  </p>
                </Card>
              </div>
            </If>

            <If condition={studentInsights.aiAdvice}>
              <div>
                <h3 className='font-heading text-xl font-bold mb-3'>How to use AI more effectively</h3>

                <Card className='p-6'>
                  <p className='items-center justify-center'>
                    {studentInsights.aiAdvice}
                  </p>
                </Card>
              </div>
            </If>
          </div>
        </Otherwise>
      </Choose>
    </>
  )
}

export default LessonInsights
