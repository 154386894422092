import TableComponent from './Table'
import Header from './Header'
import Row from './Row'
import Cell from './Cell'
import Body from './Body'
import Head from './Head'

let Table = TableComponent

Table.Header = Header
Table.Row = Row
Table.Cell = Cell
Table.Body = Body
Table.Head = Head

export default Table


