import { useNavigate } from 'react-router-dom'

import Button from '@components/Button'

const UpgradeBanner = () => {
  const navigate = useNavigate()

  return (
    <div className='flex justify-between items-center bg-purple-100 rounded-lg p-3 text-purple-700 m-3'>
      <p className='leading-tight'>
        Your 2 week free trial of Mindjoy has ended. <strong>Upgrade your plan</strong> to continue using Mindjoy.
      </p>

      <Button
        className='w-fit'
        onClick={() => navigate('/educators/upgrade')}
        label={
          <div className='flex justify-center items-center'>
            Upgrade
            <img className='ml-3 w-5 h-5' src='/crown.svg' alt='crown' />
          </div>
        }
        theme='secondary'
      />
    </div>
  )
}

export default UpgradeBanner
