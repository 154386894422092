import { useNavigate } from 'react-router-dom'

import { useCurrentUser } from '@contexts/currentUser'
import { useSubscription } from '../hooks/subscription'

const Upgrade = () => {
  const navigate = useNavigate()
  const { user: { id, email } } = useCurrentUser()
  const { hasActiveSubscription } = useSubscription()

  if (hasActiveSubscription) {
    navigate('/educators/settings')
  }

  return (
    <div className='pt-10 bg-white h-screen'>
      <stripe-pricing-table
        client-reference-id={id}
        customer-email={email}
        pricing-table-id={import.meta.env.VITE_STRIPE_PRICING_TABLE_ID}
        publishable-key={import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY}
      >
      </stripe-pricing-table>

      <p className='text-center text-gray-600 pt-5'>
        If you have any questions, please get in touch with us at <a className='text-blue-500' href='mailto:support@mindjoy.com'>support@mindjoy.com</a>.
      </p>
    </div>
  )
}

export default Upgrade
