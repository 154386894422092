import { intlFormatDistance, parseISO, format, intervalToDuration } from 'date-fns'

export const commaSeparatedListWithAnd = (array) => {
  if (array.length === 0) {
    return ''
  }

  if (array.length === 1) {
    return array[0]
  }

  if (array.length === 2) {
    return array.join(' and ')
  }

  const last = array.slice(-1)
  const rest = array.slice(0, -1)
  return `${rest.join(', ')}, and ${last}`
}

export const pluralize = (string, number) => {
  return number > 1 ? `${string}s` : string
}

// Given an integer of seconds > 0, returns 'n minutes',  'n seconds', or 'n min y seconds'.
export const formatDuration = (totalSeconds) => {
  if (!totalSeconds) {
    totalSeconds = 0
  }

  const { days, hours, minutes, seconds } = intervalToDuration({
    start: 0,
    end: totalSeconds * 1000
  })

  let result = ''

  if (days > 0) {
    result += `${days} ${pluralize('day', days)}`
    if (hours > 0) {
      result += ` ${hours} ${pluralize('hour', hours)}`
    }
  } else if (hours > 0) {
    result += `${hours} ${pluralize('hour', hours)}`
    if (minutes > 0) {
      result += ` ${minutes} ${pluralize('minute', minutes)}`
    }
  } else if (minutes > 0) {
    result += `${minutes} ${pluralize('minute', minutes)}`
    if (seconds > 0) {
      result += ` ${seconds} ${pluralize('sec', seconds)}`
    }
  } else if (seconds > 0) {
    result += `${seconds} ${pluralize('second', seconds)}`
  }

  return result.trim()
}

export const formatShortDuration = (totalSeconds) => {
  if (!totalSeconds) {
    totalSeconds = 0;
  }

  const { days, hours, minutes, seconds } = intervalToDuration({
    start: 0,
    end: totalSeconds * 1000
  });

  if (days > 0) {
    return `${days} ${pluralize('day', days)}`;
  } else if (hours > 0) {
    return `${hours} ${pluralize('hour', hours)}`;
  } else if (minutes > 0) {
    return `${minutes} ${pluralize('minute', minutes)}`;
  } else {
    return `${seconds} ${pluralize('second', seconds)}`;
  }
}

export const durationInWords = date => {
  if (!date) {
    return ''
  }

  return intlFormatDistance(parseISO(date), new Date(), { addSuffix: true })
}

export const formatTimestamp = (date) => {
  if (!date) {
    return ''
  }

  return format(parseISO(date), 'HH:mm, dd MMM yyyy')
}

export const formatShortTimestamp = (date) => {
  // If today's date, show time only
  // Otherwise, month and day only

  if (!date) {
    return ''
  }

  const parsedDate = parseISO(date)
  const today = new Date()

  if (
    parsedDate.getDate() === today.getDate() &&
    parsedDate.getMonth() === today.getMonth() &&
    parsedDate.getFullYear() === today.getFullYear()
  ) {
    return format(parsedDate, 'HH:mm')
  }

  return format(parsedDate, 'HH:mm, dd/MM')
}

export const formatDate = (date) => {
  if (!date) {
    return ''
  }

  return format(parseISO(date), 'dd MMM yyyy')
}

export const stripMarkdown = text => {
  if (!text) return ''

  return text.replace(/[*_~$#|`]/g, '')
}
