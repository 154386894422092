import CustomTooltip from './CustomTooltip'

import {
  ResponsiveContainer,
  BarChart as RechartsBarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Cell
} from 'recharts'

const BarChart = ({ data, options }) => {
  const { labels = {}, angle = {} , height = 300 } = options || {}

  if (!data) return null

  return (
    <div>
      <ResponsiveContainer className='w-full' height={height}>
        <RechartsBarChart
          data={data}
          margin={{ bottom: labels && labels.x && 20 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            interval={0}
            dataKey='name'
            angle={angle && angle.x}
            textAnchor={(angle && angle.x && 'end') || 'middle'}
            label={{ value: labels && labels.x }}
          />

          <YAxis label={{ value: labels && labels.y, angle: -90, offset: 20, position: 'insideBottomLeft' }} />

          <Tooltip content={<CustomTooltip />} />

          <Bar dataKey='value'>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color || '#3b82f6'} />
            ))}
          </Bar>
        </RechartsBarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default BarChart
