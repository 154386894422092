import { twMerge } from 'tailwind-merge'

const Table = ({ children, className, ...props }) => {
  return (
    <div className='inline-block min-w-full align-middle'>
      <div className='table-container shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
        <table
          className={twMerge('min-w-full divide-y divide-gray-300', className)}
          {...props}
        >
          {children}
        </table>
      </div>
    </div>
  )
}

export default Table
