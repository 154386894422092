import React, { createContext, useState, useEffect, useContext } from 'react'

const BreakpointContext = createContext()

const screens = {
  sm: '640',
  md: '768',
  lg: '1024',
  xl: '1280',
  '2xl': '1536',
}

export const BreakpointProvider = ({ children }) => {
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth)

  // Update currentWidth state whenever the window is resized
  useEffect(() => {
    const handleResize = () => setCurrentWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <BreakpointContext.Provider value={currentWidth}>
      {children}
    </BreakpointContext.Provider>
  )
}

export const useBreakpoint = (breakpoint) => {
  const currentWidth = useContext(BreakpointContext)

  const breakpointWidth = parseInt(screens[breakpoint], 10)

  // Return whether the current width is greater than or equal to the breakpoint
  return currentWidth < breakpointWidth
}
