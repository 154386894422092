import { twMerge } from 'tailwind-merge'

const badgeTheme = theme => {
  switch (theme) {
    case 'success':
      return 'bg-green-100 text-green-900 border-green-900'
    case 'error':
      return 'bg-red-100 text-red-900 border-red-300'
    case 'secondary':
      return 'bg-purple-100 text-purple-900 border-purple-300'
    case 'light':
      return 'bg-white text-gray-900 border-gray-300'
    default:
      return 'bg-blue-100 text-blue-900 border-blue-300'
  }
}

const Badge = ({ children, theme, className }) => (
  <div className={twMerge("rounded-full p-2 h-fit border shadow", badgeTheme(theme), className)}>
    {children}
  </div>
)

export default Badge
