import Card from '@components/Card'
import { CheckCircleIcon, XCircleIcon, ClockIcon } from '@heroicons/react/24/solid'

import { formatTimestamp } from '@helpers/format'
import Tooltip from '@components/Tooltip'

const Item = ({ label, value }) => (
  <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
    <dt className='font-medium'>{label}</dt>
    <dd className='mt-1 leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>{value}</dd>
  </div>
)

const Overview = ({ name, createdAt, verificationStatus, educatorCount }) => (
  <Card className='w-auto mx-5 my-3'>
    <div className='px-4 py-6 sm:px-6'>
      <h3 className='text-2xl font-semibold leading-7 text-gray-900'>{name}</h3>
      <p className='mt-1 max-w-2xl leading-6 text-gray-500'>Organization details.</p>
    </div>

    <div className='border-t border-gray-100'>
      <dl className='divide-y divide-gray-100'>
        <Item
          label='Verification status'
          value={
            <Choose>
              <When condition={verificationStatus === 'VERIFIED'}>
                <CheckCircleIcon id='verified-icon' className='w-7 h-7 text-green-700' />
                <Tooltip anchorSelect='#verified-icon'>Verified</Tooltip>
              </When>

              <When condition={verificationStatus === 'PENDING'}>
                <ClockIcon id='pending-icon' className='w-6 h-6 text-orange-600' />
                <Tooltip anchorSelect='#pending-icon'>Pending</Tooltip>
              </When>

              <Otherwise>
                <XCircleIcon id='rejected-icon' className='w-6 h-6 text-red-700' />
                <Tooltip anchorSelect='#rejected-icon'>Rejected</Tooltip>
              </Otherwise>
            </Choose>
          }
        />
        <Item label='Signed up' value={formatTimestamp(createdAt)} />

        <Item label='Number of educators' value={educatorCount} />
      </dl>
    </div>
  </Card>
)

export default Overview
